import React, { useContext, useState } from 'react';
import { Dropdown, Menu, Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FiEdit, FiMoreVertical, FiPackage, FiTrash2 } from 'react-icons/fi';

import { Context } from '../../services/context';
import { hasAction, actions } from '../../services/security';

import ProductosForm from './ProductosForm';

const Productos = () => {
  const [state, setState] = useContext(Context);

  const AGREGAR =
    state.id === 0
      ? hasAction(actions.SALIDAS_CREAR)
      : hasAction(actions.SALIDAS_EDITAR);
  const EDITAR =
    state.id === 0
      ? hasAction(actions.SALIDAS_CREAR)
      : hasAction(actions.SALIDAS_EDITAR);
  const ELIMINAR =
    state.id === 0
      ? hasAction(actions.SALIDAS_CREAR)
      : hasAction(actions.SALIDAS_EDITAR);

  const [formVisible, setFormVisible] = useState(false);
  const [current, setCurrent] = useState();

  const menu = (item, index) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="1" onClick={() => onEdit(item, index)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="2" onClick={() => onDelete(index)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onAdd = () => {
    setCurrent({ index: undefined });
    setFormVisible(true);
  };

  const onEdit = (item, index) => {
    setCurrent({ ...item, index: index });
    setFormVisible(true);
  };

  const onDelete = (index) => {
    Modal.confirm({
      title: 'Confirmar eliminación',
      icon: <ExclamationCircleOutlined />,
      content: '¿Esta seguro que desea eliminar este item?',
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: async () => {
        let nProductos = [...state.productos];
        nProductos.splice(index, 1);
        setState({ ...state, productos: nProductos });
      },
    });
  };

  return (
    <div className="items form-subsection" style={{ boxShadow: 'none' }}>
      <div className="form-subsection-header" style={{ cursor: 'initial' }}>
        <div className="icon">
          <FiPackage />
        </div>
        <div className="content">
          <div className="title">Productos</div>
          <div className="subtitle">{state.productos.length} productos</div>
        </div>
      </div>
      <div className="form-subsection-content">
        <table className="table table-small">
          <thead>
            <tr>
              {!state.readonly && <th style={{ width: '60px' }}></th>}
              <th>Producto</th>
              <th>Marca</th>
              <th>Modelo</th>
              <th style={{ textAlign: 'center' }}>Cantidad</th>
              <th style={{ textAlign: 'center' }}>Devoluciones</th>
            </tr>
          </thead>
          <tbody>
            {state.productos.length === 0 && (
              <tr>
                <td className="table-empty" colSpan="6">
                  Sin resultados
                </td>
              </tr>
            )}

            {state.productos.map((item, index) => (
              <tr key={index}>
                {!state.readonly && (
                  <td className="table-actions">
                    <Dropdown
                      overlay={() => menu(item, index)}
                      trigger={['click']}
                      placement="bottomLeft"
                      arrow
                    >
                      <div>
                        <FiMoreVertical />
                      </div>
                    </Dropdown>
                  </td>
                )}
                <td>{item.productoTexto}</td>
                <td>{item.marca}</td>
                <td>{item.modelo}</td>
                <td style={{ textAlign: 'center' }}>{item.cantidad}</td>
                <td style={{ textAlign: 'center' }}>{item.devoluciones}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {AGREGAR && !state.readonly && (
          <div
            className="actions"
            style={{ textAlign: 'center', margin: '20px 0' }}
          >
            <Button type="primary" onClick={onAdd}>
              Agregar
            </Button>
          </div>
        )}
      </div>

      <Modal
        visible={formVisible}
        onCancel={() => setFormVisible(false)}
        footer={null}
        maskClosable={false}
        keyboard={false}
        destroyOnClose={true}
      >
        <ProductosForm item={current} onClose={() => setFormVisible(false)} />
      </Modal>
    </div>
  );
};

export default Productos;
