/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { DatePicker, Form, Input, Menu, Modal, Tag } from 'antd';
import {
  FiCheckCircle,
  FiEdit,
  FiLogOut,
  FiPrinter,
  FiTrash2,
  FiXCircle,
} from 'react-icons/fi';
import locale from 'antd/es/date-picker/locale/es_ES';
import 'moment/locale/es';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { ContextProvider } from '../../services/context';
import { downloadBase64 } from '../../services/files';
import http from '../../services/http';
import { hasAction, actions } from '../../services/security';

import Header from '../../components/Header';
import Filters from '../../components/Filters';
import FiltersButton from '../../components/FiltersButton';
import Table from '../../components/Table';
import { ProductoSalidaEstado } from '../../services/enums';
import { showSuccess } from '../../services/notification';

const Devoluciones = () => {
  const history = useHistory();

  const VER = hasAction(actions.DEVOLUCIONES_VER);
  const EDITAR = hasAction(actions.DEVOLUCIONES_EDITAR);
  const ELIMINAR = hasAction(actions.DEVOLUCIONES_ELIMINAR);
  const ACEPTAR = hasAction(actions.DEVOLUCIONES_ACEPTAR);
  const RECHAZAR = hasAction(actions.DEVOLUCIONES_RECHAZAR);

  const [state, setState] = useState({
    filters: {
      visible: false,
      salida: '',
      producto: '',
      desde: '',
      hasta: '',
    },
    columns: [
      {
        id: 1,
        title: '#',
        style: { minWidth: '100px' },
        render: (item) => 'S' + item.salida.padStart(8, '0'),
      },
      {
        id: 2,
        title: 'Fecha',
        style: { minWidth: '50px', textAlign: 'center' },
        render: (item) => item.fecha,
      },
      {
        id: 3,
        title: 'Hora',
        style: { minWidth: '50px', textAlign: 'center' },
        render: (item) => item.hora,
      },
      {
        id: 4,
        title: 'Origen',
        style: { minWidth: '400px' },
        render: (item) => item.origen,
      },
      {
        id: 5,
        title: 'Estado',
        style: { minWidth: '150px', textAlign: 'center' },
        render: (item) => {
          if (item.estado === ProductoSalidaEstado.Pendiente) {
            return <Tag color="purple">Pendiente</Tag>;
          } else if (item.estado === ProductoSalidaEstado.Aceptada) {
            return <Tag color="green">Aceptada</Tag>;
          } else if (item.estado === ProductoSalidaEstado.Rechazada) {
            return <Tag color="red">Rechazada</Tag>;
          }
        },
      },
      {
        id: 6,
        title: 'Usuario',
        style: { minWidth: '200px' },
        render: (item) => item.usuario,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const breadcrumb = [{ id: 1, title: 'Devoluciones', url: '/devoluciones' }];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Salida" name="salida">
          <Input />
        </Form.Item>
        <Form.Item label="Producto" name="producto">
          <Input />
        </Form.Item>
        <Form.Item label="Desde" name="desde">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>
        <Form.Item label="Hasta" name="hasta">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="3" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {item.imprimible && (
          <Menu.Item key="2" onClick={() => onClickPrint(item)}>
            <FiPrinter />
            Remito
          </Menu.Item>
        )}
        <Menu.Item key="5" onClick={() => onClickSalida(item)}>
          <FiLogOut />
          Solicitud de Salida
        </Menu.Item>
        <Menu.Divider />
        {ACEPTAR && item.aceptable && (
          <Menu.Item key="0" onClick={() => onClickAccept(item)}>
            <FiCheckCircle />
            Aceptar
          </Menu.Item>
        )}
        {RECHAZAR && item.aceptable && (
          <Menu.Item key="1" onClick={() => onClickReject(item)}>
            <FiXCircle />
            Rechazar
          </Menu.Item>
        )}
        <Menu.Divider />
        {ELIMINAR && item.eliminable && (
          <Menu.Item key="4" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onClickSalida = (item) => {
    history.push(`/salidas/${item.salida}`);
  };

  const onClickPrint = async (item) => {
    const response = await http.post('/devoluciones/remito', {
      id: item.id,
    });

    if (response && response.data.data) {
      const data = response.data.data;
      downloadBase64(data.filename, data.content);
    }
  };

  const onEdit = (item) => {
    history.push(`/devoluciones/${item.id}`);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: 'Confirmar eliminación',
      icon: <ExclamationCircleOutlined />,
      content: '¿Esta seguro que desea eliminar esta devolución?',
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: async () => {
        const rDelete = await http.delete(`devoluciones/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess('La devolucion fue eliminada exitosamente.');
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  const onClickAccept = (item) => {
    Modal.confirm({
      title: 'Aceptar Devolución',
      icon: <ExclamationCircleOutlined />,
      content: '¿Esta seguro que desea aceptar esta devolución?',
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: async () => {
        const rAccept = await http.post(`Devoluciones/aceptar/${item.id}`);
        if (rAccept && rAccept.data.code === 0) {
          showSuccess('La devolución fue aceptada exitosamente.');
          onClickPrint(item);
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  const onClickReject = (item) => {
    Modal.confirm({
      title: 'Rechazar Salida',
      icon: <ExclamationCircleOutlined />,
      content: '¿Esta seguro que desea rechazar esta devolución?',
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: async () => {
        const rReject = await http.post(`Devoluciones/rechazar/${item.id}`);
        if (rReject && rReject.data.code === 0) {
          showSuccess('La devolución fue rechazada exitosamente.');
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="devoluciones page">
      <ContextProvider value={[state, setState]}>
        <Header
          title="Devoluciones"
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Table url="devoluciones/list" menu={menu} />
      </ContextProvider>
    </div>
  );
};

export default Devoluciones;
