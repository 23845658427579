/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { FiMaximize } from "react-icons/fi";

import http from "../../../services/http";
import { showSuccess } from "../../../services/notification";
import { hasAction, actions } from "../../../services/security";
import { required } from "../../../services/validation";

import Header from "../../../components/Header";

const AreasForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.AREAS_VER);
  const CREAR = hasAction(actions.AREAS_CREAR);
  const EDITAR = hasAction(actions.AREAS_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });
  const [tipos, setTipos] = useState([]);
  const [usuarios, setUsuarios] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Configuración" },
    { id: 2, title: "Áreas", url: "/configuracion/areas" },
    { id: 3, title: state.title },
  ];

  useEffect(() => {
    async function getForm() {
      const rData = await http.get("areas/data");
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setTipos(data.tipos);
        setUsuarios(data.usuarios);
      }

      const rForm = await http.get(`areas/${state.id}`);
      if (rForm.data.code === 0) {
        const data = rForm.data.data;
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const rSave =
      state.id === 0
        ? await http.post("areas", values)
        : await http.put(`areas/${state.id}`, values);

    if (rSave && rSave.data.code === 0) {
      history.push("/configuracion/areas");
      showSuccess("El área fue guardada exitosamente.");
    } else {
      setState({ ...state, loading: false });
    }
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="area">
      <Header title={`${state.title} Área`} breadcrumb={breadcrumb} />

      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form card">
          <div className="form-icon">
            <FiMaximize />
          </div>

          <Row gutter={20}>
            <Col span={24}>
              <Form.Item label="Nombre" name="nombre" rules={[required]}>
                <Input maxLength={250} disabled={!GUARDAR} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Código" name="codigo" rules={[required]}>
                <Input maxLength={20} disabled={!GUARDAR} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Responsables"
                name="responsables"
                rules={[required]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  maxTagCount={3}
                  disabled={!GUARDAR}
                >
                  {usuarios.map((item) => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Supervisores" name="supervisores">
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  maxTagCount={3}
                  disabled={!GUARDAR}
                >
                  {usuarios.map((item) => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Auditores" name="auditores">
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  maxTagCount={3}
                  disabled={!GUARDAR}
                >
                  {usuarios.map((item) => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Tipos de reclamos" name="reclamosTipos">
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  maxTagCount={2}
                  disabled={!GUARDAR}
                >
                  {tipos.map((item) => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <div className="form-actions">
            <Link to="/configuracion/areas">
              <Button type="secondary">Volver</Button>
            </Link>
            {GUARDAR && (
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AreasForm;
