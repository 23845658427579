/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Form, Input, Menu, Modal, Select } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FiEdit, FiPlus, FiTrash2 } from "react-icons/fi";

import { ContextProvider } from "../../../services/context";
import http from "../../../services/http";
import { showSuccess } from "../../../services/notification";
import { hasAction, actions } from "../../../services/security";

import Header from "../../../components/Header";
import Filters from "../../../components/Filters";
import FiltersButton from "../../../components/FiltersButton";
import Table from "../../../components/Table";
import { FiPrinter } from "react-icons/fi";

const PuntosReclamo = () => {
  const history = useHistory();

  const VER = hasAction(actions.PUNTOS_RECLAMO_VER);
  const CREAR = hasAction(actions.PUNTOS_RECLAMO_CREAR);
  const EDITAR = hasAction(actions.PUNTOS_RECLAMO_EDITAR);
  const ELIMINAR = hasAction(actions.PUNTOS_RECLAMO_ELIMINAR);

  const [state, setState] = useState({
    filters: {
      visible: false,
      numero: "",
      tipo: "",
      distrito: "",
      direccion: "",
    },
    columns: [
      {
        id: 1,
        title: "Número",
        style: {},
        render: (item) => item.id,
      },
      {
        id: 2,
        title: "Descripcion",
        style: {},
        render: (item) => item.descripcion,
      },
      {
        id: 3,
        title: "Tipo",
        style: {},
        render: (item) => item.tipo,
      },
      {
        id: 4,
        title: "Distrito",
        style: {},
        render: (item) => item.distrito,
      },
      {
        id: 5,
        title: "Dirección",
        style: {},
        render: (item) => item.direccion,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const [distritos, setDistritos] = useState([]);
  const [tipos, setTipos] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Configuración" },
    { id: 2, title: "Puntos de Reclamo", url: "/configuracion/puntosreclamo" },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        {CREAR && (
          <Button type="primary" icon={<FiPlus />} onClick={onAdd}>
            Nuevo
          </Button>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Número" name="numero">
          <Input type="number" step="any" onWheel={(e) => e.target.blur()} />
        </Form.Item>
        <Form.Item label="Tipo" name="tipo">
          <Select allowClear>
            {tipos.map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Distrito" name="distrito">
          <Select allowClear>
            {distritos.map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Direccion" name="direccion">
          <Input />
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="1" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
        <Menu.Divider />
        <Menu.Item key="2" onClick={() => onPrint(item)}>
          <FiPrinter />
          Imprimir
        </Menu.Item>
      </Menu>
    );
  };

  const onAdd = () => {
    history.push("/configuracion/puntosreclamo/nuevo");
  };

  const onEdit = (item) => {
    history.push(`/configuracion/puntosreclamo/${item.id}`);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este punto de reclamo?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`reclamospuntos/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("El punto de reclamo fue eliminado exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  const onPrint = (item) => {
    window
      .open(
        `${process.env.REACT_APP_API_URL}/reclamospuntos/${item.id}/print`,
        "_blank"
      )
      .focus();
  };

  useEffect(() => {
    async function getData() {
      const rData = await http.get("reclamospuntos/data");
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setDistritos(data.distritos);
        setTipos(data.tipos);
      }
    }

    getData();
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="puntosReclamo page">
      <ContextProvider value={[state, setState]}>
        <Header
          title="Puntos de Reclamo"
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Table url="reclamospuntos/list" menu={menu} />
      </ContextProvider>
    </div>
  );
};

export default PuntosReclamo;
