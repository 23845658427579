/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { FiPackage } from "react-icons/fi";

import { Context } from "../../services/context";
import http from "../../services/http";
import { required } from "../../services/validation";

const ProductosForm = (props) => {
  const [form] = Form.useForm();
  const { item, onClose } = props;

  const [state, setState] = useContext(Context);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const [categoria, setCategoria] = useState();

  const [categorias, setCategorias] = useState([]);
  const [productos, setProductos] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await http.get(
        `expedientes/productos/data?direccion=${state.direccion}`
      );

      if (response && response.data.code === 0) {
        const data = response.data.data;
        setCategorias(data.categorias);
        setProductos(data.productos);
      }

      setLoaded(true);
    }

    getData();
  }, [state.id, state.direccion]);

  useEffect(() => {
    async function getForm() {
      if (!loaded) {
        return;
      }

      item.cantidad = parseFloat(item.cantidadSolicitada);
      form.setFieldsValue(item);

      onChangeProducto(item.producto);
    }

    getForm();
  }, [loaded]);

  const onChangeCategoria = (value) => {
    setCategoria(value);
    onChangeProducto("");
  };

  const onChangeProducto = async (value) => {
    const item = productos.find((x) => x.id.toString() === value);
    if (!item) {
      form.setFieldsValue({
        producto: "",
        descripcion: "",
      });

      return;
    }

    setCategoria(item.categoria);

    form.setFieldsValue({
      categoria: item.categoria,
      descripcion: item.descripcion,
    });
  };

  const onSave = async (values) => {
    setLoading(true);

    values.expediente = state.id;
    values.cantidad = parseFloat(values.cantidad);

    const response =
      item.id === undefined
        ? await http.post("expedientes/productos", values)
        : await http.put(`expedientes/productos/${item.id}`, values);

    if (response && response.data.code === 0) {
      form.resetFields();
      setState({ ...state, reload: !state.reload });
      setLoading(false);
      onClose();
    } else {
      setLoading(false);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onSave}>
      <div className="form">
        <div className="form-icon">
          <FiPackage />
        </div>

        <Form.Item label="Categoría" name="categoria" rules={[required]}>
          <Select
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={onChangeCategoria}
          >
            {categorias.map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Producto" name="producto" rules={[required]}>
          <Select
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={onChangeProducto}
          >
            {productos
              .filter(
                (x) =>
                  (!categoria || x.categoria === categoria) &&
                  !props.productos.find((y) => y.producto === x.id.toString())
              )
              .map((x) => (
                <Select.Option key={x.id} value={x.id.toString()}>
                  {x.codigo} - {x.nombre}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item label="Descripción" name="descripcion">
          <Input.TextArea rows={5} disabled={true} />
        </Form.Item>

        <Form.Item label="Cantidad" name="cantidad" rules={[required]}>
          <Input type="number" step="any" onWheel={(e) => e.target.blur()} />
        </Form.Item>

        <Form.Item label="Comentarios" name="comentarios" className="textarea">
          <Input.TextArea showCount maxLength={500} />
        </Form.Item>

        <div className="form-actions">
          <Button type="secondary" onClick={onClose}>
            Volver
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Guardar
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ProductosForm;
