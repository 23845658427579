/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Button, Form, Input, Select } from "antd";
import { FiLayers } from "react-icons/fi";

import http from "../../../services/http";
import { showSuccess } from "../../../services/notification";
import { hasAction, actions } from "../../../services/security";
import { minimumOptions, required } from "../../../services/validation";

import Header from "../../../components/Header";

const { TextArea } = Input;

const CategoriasForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.CATEGORIAS_VER);
  const CREAR = hasAction(actions.CATEGORIAS_CREAR);
  const EDITAR = hasAction(actions.CATEGORIAS_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loaded: false,
    loading: false,
    subcategorias: [],
  });

  const [direcciones, setDirecciones] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Configuración" },
    { id: 2, title: "Categorías", url: "/configuracion/categorias" },
    { id: 3, title: state.title },
  ];

  useEffect(() => {
    async function getData() {
      const response = await http.get("categorias/data");
      if (response.data.code === 0) {
        const data = response.data.data;
        setDirecciones(data.direcciones);
        setState({ ...state, loaded: true });
      }
    }

    getData();
  }, []);

  useEffect(() => {
    async function getForm() {
      if (!state.loaded) {
        return;
      }

      const response = await http.get(`categorias/${state.id}`);
      if (response.data.code === 0) {
        const data = response.data.data;
        form.setFieldsValue(data);
        setState({ ...state, subcategorias: data.subcategorias });
      }
    }

    getForm();
  }, [state.loaded]);

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const rSave =
      state.id === 0
        ? await http.post("categorias", values)
        : await http.put(`categorias/${state.id}`, values);

    if (rSave && rSave.data.code === 0) {
      history.push("/configuracion/categorias");
      showSuccess("La categoría fue guardada exitosamente.");
    } else {
      setState({ ...state, loading: false });
    }
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="categoria">
      <Header title={`${state.title} Categoría`} breadcrumb={breadcrumb} />

      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form card">
          <div className="form-icon">
            <FiLayers />
          </div>

          <Form.Item label="Nombre" name="nombre" rules={[required]}>
            <Input maxLength={250} disabled={!GUARDAR} />
          </Form.Item>

          <Form.Item label="Código" name="codigo">
            <Input maxLength={10} disabled={!GUARDAR} />
          </Form.Item>

          <Form.Item label="Descripción" name="descripcion">
            <TextArea showCount maxLength={500} disabled={!GUARDAR} />
          </Form.Item>

          <Form.Item label="Subcategorías" name="subcategorias">
            <Select mode="tags">
              {state.subcategorias.map((subcategoria, index) => (
                <Select.Option key={index}>{subcategoria}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Direcciones"
            name="direcciones"
            rules={[minimumOptions(0)]}
          >
            <Select
              mode="multiple"
              allowClear
              showSearch
              optionFilterProp="children"
              disabled={!GUARDAR}
            >
              {direcciones.map((x) => (
                <Select.Option key={x.value} value={x.value}>
                  {x.text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div className="form-actions">
            <Link to="/configuracion/categorias">
              <Button type="secondary">Volver</Button>
            </Link>
            {GUARDAR && (
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CategoriasForm;
