import React, { useContext } from 'react';
import { Input } from 'antd';
import { FiPackage } from 'react-icons/fi';

import { Context } from './../../services/context';

const Items = ({ list, readonly }) => {
  const [state, setState] = useContext(Context);

  const onChangeCantidad = (event, item, index) => {
    const value = event.currentTarget.value;

    if (
      value &&
      (isNaN(value) ||
        parseFloat(value) < 0 ||
        parseFloat(value) > item.disponible)
    ) {
      return;
    }

    let nItems = [...state.items];
    nItems[index].cantidad = value;
    setState({ ...state, items: nItems });
  };

  return (
    <div className="items form-subsection">
      <div className="form-subsection-header">
        <div className="icon">
          <FiPackage />
        </div>
        <div className="content">
          <div className="title">Items</div>
          <div className="subtitle">{list.length} elementos</div>
        </div>
      </div>
      <div className="form-subsection-content">
        <table className="table table-small">
          <thead>
            <tr>
              <th>Producto</th>
              <th>Origen</th>
              <th style={{ textAlign: 'center' }}>Total</th>
              <th style={{ textAlign: 'center' }}>Devueltos</th>
              <th style={{ textAlign: 'center' }}>Cantidad</th>
            </tr>
          </thead>
          <tbody>
            {list.length === 0 && (
              <tr>
                <td className="table-empty" colSpan="7">
                  Sin resultados
                </td>
              </tr>
            )}

            {list.map((item, index) => (
              <tr key={index}>
                <td>{item.producto}</td>
                <td>{item.origen}</td>
                <td style={{ textAlign: 'center' }}>{item.total}</td>
                <td style={{ textAlign: 'center' }}>{item.devoluciones}</td>
                <td style={{ textAlign: 'center' }}>
                  {!readonly && (
                    <Input
                      type="number"
                      min={0}
                      max={item.disponible}
                      value={item.cantidad}
                      style={{ textAlign: 'center' }}
                      onWheel={(e) => e.target.blur()}
                      onChange={(event) => onChangeCantidad(event, item, index)}
                    />
                  )}
                  {readonly && item.cantidad}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Items;
