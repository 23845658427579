/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DatePicker, Form } from "antd";
import Chart from "react-apexcharts";
import * as moment from "moment";

import http from "../../../services/http";

import empty from "../../../assets/images/empty.svg";

const Censo = () => {
  const [form] = Form.useForm();

  const [periodo, setPeriodo] = useState();
  const [pieChart, setPieChartChart] = useState({
    series: [44, 55, 13, 43, 22],
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  useEffect(() => {
    setPeriodo(new Date().getFullYear());
    form.setFieldsValue({ periodo: moment() });
  }, []);

  useEffect(() => {
    async function getData() {
      const rData = await http.get(
        `tableros/hacienda/censo?periodo=${periodo}`
      );
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setPieChartChart({
          ...pieChart,
          options: { ...pieChart.options, labels: data.labels },
          series: data.series,
        });
      }
    }

    getData();
  }, [periodo]);

  const onYearChange = (value) => {
    setPeriodo(value?.year().toString());
  };

  return (
    <div className="card chart">
      <div className="header">
        <div className="title">Censo</div>
        <Form form={form} className="year">
          <Form.Item name="periodo">
            <DatePicker
              picker="year"
              bordered={false}
              size="large"
              placeholder="Año"
              onChange={onYearChange}
            />
          </Form.Item>
        </Form>
      </div>

      {pieChart.options.labels.length === 0 && (
        <div className="empty">
          <img src={empty} alt="empty" />
          Sin resultados
        </div>
      )}

      {pieChart.options.labels.length > 0 && (
        <Chart
          className="canvas"
          options={pieChart.options}
          series={pieChart.series}
          type="pie"
          height={1200}
          width="70%"
        />
      )}
    </div>
  );
};

export default Censo;
