/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Button, Form, Input, Select } from 'antd';
import { FiFolder } from 'react-icons/fi';

import http from './../../../services/http';
import { showSuccess } from './../../../services/notification';
import { hasAction, actions } from './../../../services/security';
import { required } from './../../../services/validation';

import Header from './../../../components/Header';

const { Option } = Select;

const DireccionesForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.DIRECCIONES_VER);
  const CREAR = hasAction(actions.DIRECCIONES_CREAR);
  const EDITAR = hasAction(actions.DIRECCIONES_EDITAR);
  const GUARDAR = match.params.id === 'nuevo' ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === 'nuevo' ? 'Crear' : EDITAR ? 'Editar' : 'Ver',
    id: match.params.id === 'nuevo' ? 0 : parseInt(match.params.id),
    loading: false,
  });
  const [usuarios, setUsuarios] = useState([]);

  const breadcrumb = [
    { id: 1, title: 'Configuración' },
    { id: 2, title: 'Direcciones', url: '/configuracion/direcciones' },
    { id: 3, title: state.title },
  ];

  useEffect(() => {
    async function getForm() {
      const rData = await http.get('direcciones/data');
      let usuarios = [];

      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        usuarios = data.usuarios;
      }

      const rForm = await http.get(`direcciones/${state.id}`);
      if (rForm.data.code === 0) {
        const data = rForm.data.data;

        if (data.usuarios) {
          usuarios = usuarios.map((x) => {
            return { ...x, selected: data.usuarios.indexOf(x.value) > -1 };
          });
        }

        setUsuarios(usuarios);
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  const onUsuariosChange = (values) => {
    let nUsuarios = [...usuarios];
    nUsuarios = nUsuarios.map((x) => {
      return { ...x, selected: values.indexOf(x.value) > -1 };
    });
    setUsuarios(nUsuarios);

    const responsable = form.getFieldValue('responsable');
    const temp = nUsuarios.find((x) => x.value === responsable && x.selected);

    if (!temp) {
      form.setFieldsValue({ responsable: undefined });
    }
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const rSave =
      state.id === 0
        ? await http.post('direcciones', values)
        : await http.put(`direcciones/${state.id}`, values);

    if (rSave && rSave.data.code === 0) {
      history.push('/configuracion/direcciones');
      showSuccess('La dirección fue guardada exitosamente.');
    } else {
      setState({ ...state, loading: false });
    }
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="direccion">
      <Header title={`${state.title} Dirección`} breadcrumb={breadcrumb} />

      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form card">
          <div className="form-icon">
            <FiFolder />
          </div>

          <Form.Item label="Nombre" name="nombre" rules={[required]}>
            <Input maxLength={100} disabled={!GUARDAR} />
          </Form.Item>

          <Form.Item label="Usuarios" name="usuarios">
            <Select
              mode="multiple"
              allowClear
              showSearch
              optionFilterProp="children"
              maxTagCount="responsive"
              disabled={!GUARDAR}
              onChange={onUsuariosChange}
            >
              {usuarios.map((x) => (
                <Option key={x.value} value={x.value}>
                  {x.text}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Responsable" name="responsable">
            <Select allowClear disabled={!GUARDAR}>
              {usuarios
                .filter((x) => x.selected)
                .map((x) => (
                  <Option key={x.value} value={x.value}>
                    {x.text}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <div className="form-actions">
            <Link to="/configuracion/direcciones">
              <Button type="secondary">Volver</Button>
            </Link>
            {GUARDAR && (
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default DireccionesForm;
