/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Popover,
  Select,
  Tag,
} from 'antd';
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { FiEdit, FiPlus, FiPrinter, FiSearch, FiTrash2 } from 'react-icons/fi';
import { SiMicrosoftexcel } from 'react-icons/si';
import locale from 'antd/es/date-picker/locale/es_ES';
import 'moment/locale/es';

import { ContextProvider } from '../../services/context';
import { downloadBase64 } from '../../services/files';
import http from '../../services/http';
import { showSuccess } from '../../services/notification';
import { hasAction, actions } from '../../services/security';

import Header from '../../components/Header';
import Filters from '../../components/Filters';
import FiltersButton from '../../components/FiltersButton';
import Table from '../../components/Table';

const { Option } = Select;

const Expedientes = () => {
  const history = useHistory();

  const VER = hasAction(actions.EXPEDIENTES_VER);
  const CREAR = hasAction(actions.EXPEDIENTES_CREAR);
  const EDITAR = hasAction(actions.EXPEDIENTES_EDITAR);
  const ELIMINAR = hasAction(actions.EXPEDIENTES_ELIMINAR);

  const [state, setState] = useState({
    filters: {
      visible: false,
      desde: '',
      hasta: '',
      direccion: '',
      numero: '',
      producto: '',
    },
    columns: [
      {
        title: 'Número',
        style: { minWidth: '150px' },
        render: (item) => item.numero,
      },
      {
        title: 'Fecha',
        style: { width: '120px', textAlign: 'center' },
        render: (item) => item.fecha,
      },
      {
        title: 'Dirección',
        style: {},
        render: (item) => item.direccion,
      },
      {
        title: 'Productos',
        style: { minWidth: '150px' },
        render: (item) => (
          <Popover content={() => renderProductos(item)} title="Productos">
            <Tag color="purple">{item.productos.length} productos</Tag>
          </Popover>
        ),
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const [loadingExport, setLoadingExport] = useState(false);

  const [direcciones, setDirecciones] = useState([]);

  const breadcrumb = [{ id: 1, title: 'Expedientes', url: '/expedientes' }];

  const buttons = () => {
    return (
      <React.Fragment>
        <Dropdown overlay={exportMenu}>
          <Button loading={loadingExport}>
            {!loadingExport && (
              <React.Fragment>
                Exportar <DownOutlined />
              </React.Fragment>
            )}
          </Button>
        </Dropdown>
        <FiltersButton />
        {CREAR && (
          <Button type="primary" icon={<FiPlus />} onClick={onAdd}>
            Nuevo
          </Button>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Desde" name="desde">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>
        <Form.Item label="Hasta" name="hasta">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>
        <Form.Item label="Dirección" name="direccion">
          <Select allowClear showSearch optionFilterProp="children">
            {direcciones.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Número" name="numero">
          <Input />
        </Form.Item>
        <Form.Item label="Producto" name="producto">
          <Input />
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {!EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiSearch />
            Ver
          </Menu.Item>
        )}
        {EDITAR && (
          <Menu.Item key="1" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {VER && (
          <Menu.Item key="2" onClick={() => onPrint(item)}>
            <FiPrinter />
            Imprimir
          </Menu.Item>
        )}
        {VER && (
          <Menu.Item key="3" onClick={() => onExportProductosById(item)}>
            <SiMicrosoftexcel />
            Productos
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="4" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const exportMenu = () => (
    <Menu>
      <Menu.Item key="1" icon={<SiMicrosoftexcel />} onClick={onExport}>
        Excel
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<SiMicrosoftexcel />}
        onClick={onExportProductos}
      >
        Productos
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    async function getData() {
      const response = await http.get('expedientes/data');
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setDirecciones(data.direcciones);
      }
    }

    getData();
  }, []);

  const onAdd = () => {
    history.push('/expedientes/nuevo');
  };

  const onPrint = (item) => {
    var win = window.open(
      `${process.env.REACT_APP_API_URL}/expedientes/${item.id}/print`,
      '_blank'
    );
    win.focus();
  };

  const onEdit = (item) => {
    history.push(`/expedientes/${item.id}`);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: 'Confirmar eliminación',
      icon: <ExclamationCircleOutlined />,
      content: '¿Esta seguro que desea eliminar este expediente?',
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: async () => {
        const rDelete = await http.delete(`expedientes/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess('El expediente fue eliminado exitosamente.');
          setState({ ...state, reload: !state.reload, page: 1 });
        }
      },
    });
  };

  const onExport = async () => {
    setLoadingExport(true);

    const response = await http.post('/expedientes/export', state.filters);
    if (response && response.data.data) {
      const data = response.data.data;
      downloadBase64(data.filename, data.content);
    }

    setLoadingExport(false);
  };

  const onExportProductos = async () => {
    setLoadingExport(true);

    const response = await http.post(
      '/expedientes/export/productos',
      state.filters
    );
    if (response && response.data.data) {
      const data = response.data.data;
      downloadBase64(data.filename, data.content);
    }

    setLoadingExport(false);
  };

  const onExportProductosById = async (item) => {
    const response = await http.post(
      `/expedientes/export/productos/${item.id}`
    );
    if (response && response.data.data) {
      const data = response.data.data;
      downloadBase64(data.filename, data.content);
    }
  };

  const renderProductos = (item) => {
    return (
      <div>
        {item.productos.map((x, i) => (
          <div key={i} className="popover-producto">
            {x.producto}
          </div>
        ))}
      </div>
    );
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="expedientes page">
      <ContextProvider value={[state, setState]}>
        <Header title="Expedientes" breadcrumb={breadcrumb} buttons={buttons} />
        <Filters filters={filters} />
        <Table url="expedientes/list" menu={menu} />
      </ContextProvider>
    </div>
  );
};

export default Expedientes;
