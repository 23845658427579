import React from "react";
import { NavLink } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import {
  FiAlertTriangle,
  FiHome,
  FiFileText,
  FiPieChart,
  FiBarChart2,
  FiCompass,
  FiRepeat,
  FiMaximize,
  FiLogOut,
  FiArchive,
  FiShield,
  FiFolder,
  FiPackage,
  FiUser,
  FiMessageSquare,
  FiList,
  FiLayers,
  FiMap,
  FiTruck,
  FiClock,
  FiFlag,
  FiSettings,
  FiX,
  FiMapPin,
} from "react-icons/fi";
import { hasAction, actions } from "./../services/security";
import logo from "./../assets/images/logo.png";
import "./SideBar.scss";

const SideBar = ({ collapsed, onCollapse }) => {
  const getSize = () => {
    return collapsed
      ? { width: "80px", height: "calc(100% - 74px)" }
      : { width: "260px", height: "calc(100% - 74px)" };
  };

  const getOverlay = () => {
    return collapsed ? "sidebar-overlay" : "sidebar-overlay visible";
  };

  const getClasses = () => {
    return collapsed ? "sidebar collapsed" : "sidebar";
  };

  return (
    <div className={getOverlay()}>
      <div className={getClasses()}>
        <div className="sidebar-header">
          <img className="sidebar-logo" src={logo} alt="logo" />
          <p className="sidebar-title">
            <span className="small"></span>
            <span className="name">Municipalidad</span>
          </p>
          <FiX className="sidebar-collapse" onClick={() => onCollapse(true)} />
        </div>
        <Scrollbars className="sidebar-scroll" style={getSize()} autoHide>
          <ul className="sidebar-list">
            <li className="nav-item">
              <NavLink to="/" exact>
                <FiHome />
                <div>Inicio</div>
              </NavLink>
            </li>
            {hasAction(actions.EXPEDIENTES_VER) && (
              <li className="nav-item">
                <NavLink to="/expedientes">
                  <FiFileText />
                  <div>Expedientes</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.INVENTARIO_VER) && (
              <li className="nav-item">
                <NavLink to="/inventario">
                  <FiList />
                  <div>Inventario</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.SALIDAS_VER) && (
              <li className="nav-item">
                <NavLink to="/salidas">
                  <FiLogOut />
                  <div>Solicitudes de Salida</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.DEVOLUCIONES_VER) && (
              <li className="nav-item">
                <NavLink to="/devoluciones">
                  <FiRepeat />
                  <div>Devoluciones</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.PUNTO_PEDIDO_VER) && (
              <li className="nav-item">
                <NavLink to="/punto-pedido">
                  <FiAlertTriangle />
                  <div>Punto de Pedido</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.RECLAMOS_VER) && (
              <li className="nav-item">
                <NavLink to="/reclamos">
                  <FiMessageSquare />
                  <div>Reclamos</div>
                </NavLink>
              </li>
            )}

            {(hasAction(actions.TABLEROS_HACIENDA_VER) ||
              hasAction(actions.TABLEROS_RECLAMOS_VER)) && (
                <li className="nav-header">Tableros</li>
              )}

            {hasAction(actions.TABLEROS_HACIENDA_VER) && (
              <li className="nav-item">
                <NavLink to="/tableros/hacienda">
                  <FiBarChart2 />
                  <div>Hacienda</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.TABLEROS_RECLAMOS_VER) && (
              <li className="nav-item">
                <NavLink to="/tableros/reclamos">
                  <FiPieChart />
                  <div>Reclamos</div>
                </NavLink>
              </li>
            )}

            {hasAction(actions.CATASTRO_VER) && (
              <li className="nav-header">Notificaciones</li>
            )}
            {hasAction(actions.CATASTRO_VER) && (
              <li className="nav-item">
                <NavLink to="/notificaciones/catastro">
                  <FiMap />
                  <div>Catastro</div>
                </NavLink>
              </li>
            )}

            {(hasAction(actions.AREAS_VER) ||
              hasAction(actions.CATEGORIAS_VER) ||
              hasAction(actions.DEPOSITOS_VER) ||
              hasAction(actions.DIRECCIONES_VER) ||
              hasAction(actions.PARAMETROS_VER) ||
              hasAction(actions.PRODUCTOS_VER) ||
              hasAction(actions.PROVEEDORES_VER) ||
              hasAction(actions.PUNTOS_RECLAMO_VER) ||
              hasAction(actions.TIPOS_RECLAMOS_VER) ||
              hasAction(actions.UNIDADES_VER)) && (
                <li className="nav-header">Configuración</li>
              )}
            {hasAction(actions.AREAS_VER) && (
              <li className="nav-item">
                <NavLink to="/configuracion/areas">
                  <FiMaximize />
                  <div>Areas</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.CATEGORIAS_VER) && (
              <li className="nav-item">
                <NavLink to="/configuracion/categorias">
                  <FiLayers />
                  <div>Categorías</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.DEPOSITOS_VER) && (
              <li className="nav-item">
                <NavLink to="/configuracion/depositos">
                  <FiArchive />
                  <div>Depósitos</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.DIRECCIONES_VER) && (
              <li className="nav-item">
                <NavLink to="/configuracion/direcciones">
                  <FiFolder />
                  <div>Direcciones</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.PARAMETROS_VER) && (
              <li className="nav-item">
                <NavLink to="/configuracion/parametros">
                  <FiSettings />
                  <div>Parámetros</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.PRODUCTOS_VER) && (
              <li className="nav-item">
                <NavLink to="/configuracion/productos">
                  <FiPackage />
                  <div>Productos</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.PROVEEDORES_VER) && (
              <li className="nav-item">
                <NavLink to="/configuracion/proveedores">
                  <FiTruck />
                  <div>Proveedores</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.PUNTOS_RECLAMO_VER) && (
              <li className="nav-item">
                <NavLink to="/configuracion/puntosreclamo">
                  <FiMapPin />
                  <div>Puntos de Reclamo</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.TIPOS_RECLAMOS_VER) && (
              <li className="nav-item">
                <NavLink to="/configuracion/tiposreclamo">
                  <FiFlag />
                  <div>Tipos de Reclamo</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.UNIDADES_VER) && (
              <li className="nav-item">
                <NavLink to="/configuracion/unidades">
                  <FiCompass />
                  <div>Unidades de Medida</div>
                </NavLink>
              </li>
            )}

            {(hasAction(actions.PERFILES_VER) ||
              hasAction(actions.USUARIOS_VER) ||
              hasAction(actions.AUDITORIA_VER)) && (
                <li className="nav-header">Seguridad</li>
              )}
            {hasAction(actions.PERFILES_VER) && (
              <li className="nav-item">
                <NavLink to="/seguridad/perfiles">
                  <FiShield />
                  <div>Perfiles</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.USUARIOS_VER) && (
              <li className="nav-item">
                <NavLink to="/seguridad/usuarios">
                  <FiUser />
                  <div>Usuarios</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.AUDITORIA_VER) && (
              <li className="nav-item">
                <NavLink to="/seguridad/auditoria">
                  <FiClock />
                  <div>Auditoría</div>
                </NavLink>
              </li>
            )}
          </ul>
        </Scrollbars>
      </div>
    </div>
  );
};

export default SideBar;
