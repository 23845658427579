/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Checkbox, Dropdown, Pagination, Select, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FiMoreVertical } from 'react-icons/fi';

import { Context } from './../services/context';
import http from './../services/http';

import empty from './../assets/images/empty.svg';

import './Table.scss';

const Table = (props) => {
  const history = useHistory();
  const { url, menu, checkbox, onReload, onCheck } = props;

  const [state, setState] = useContext(Context);

  useEffect(() => {
    async function getList() {
      if (!state.loaded) {
        return;
      }

      setState({ ...state, loading: true });

      const response = await http.post(url, {
        page: state.page,
        size: state.size,
        ...state.filters,
      });

      if (response && response.data.code === 0) {
        let data = response.data.data;

        if (onReload) {
          data.list = onReload(data.list);
        }

        if (data.list.length === 0 && state.page > 1) {
          setState({
            ...state,
            page: 1,
          });
        } else {
          setState({
            ...state,
            list: data.list,
            total: data.total,
            loading: false,
          });
        }
      } else {
        setState({ ...state, loading: false });
      }

      localStorage.setItem(
        history.location.pathname,
        JSON.stringify({
          filters: state.filters,
          page: state.page,
          size: state.size,
        })
      );
    }

    getList();
  }, [state.page, state.size, state.loaded, state.reload]);

  const getFooter = () => {
    let start = state.page * state.size - state.size + 1;
    start = start < state.total ? start : state.total;

    let end = state.page * state.size;
    end = end < state.total ? end : state.total;

    return `Mostrando ${start} - ${end} de ${state.total} resultados`;
  };

  const onChangePage = (value) => {
    setState({ ...state, page: value });
  };

  const onChangeSize = (value) => {
    setState({ ...state, size: parseInt(value) });
  };

  const onChangeCheckAll = (checked) => {
    const ids = state.list.map((x) => x.id);
    let nSelected = [...state.selected];

    if (checked) {
      nSelected = [...nSelected, ...ids];
      nSelected = nSelected.filter(
        (item, pos) => nSelected.indexOf(item) === pos
      );
    } else {
      nSelected = nSelected.filter((x) => !ids.includes(x));
    }

    onCheck(nSelected);
  };

  const onChangeCheck = (item, checked) => {
    let nSelected = [...state.selected.filter((x) => x !== item.id)];

    if (checked) {
      nSelected.push(item.id);
    }

    onCheck(nSelected);
  };

  return (
    <div className="card-table">
      {state.loading && (
        <div className="loading">
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 50, color: '#04bebe', marginBottom: '10px' }}
                spin
              />
            }
          />
          Cargando...
        </div>
      )}

      {!state.loading && state.total === 0 && (
        <div className="empty">
          <img src={empty} alt="empty" />
          Sin resultados
        </div>
      )}

      {!state.loading && state.total > 0 && (
        <React.Fragment>
          <div className="table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  {menu && <th></th>}
                  {checkbox && (
                    <th className="col-checkbox">
                      <Checkbox
                        onChange={(e) => onChangeCheckAll(e.target.checked)}
                      />
                    </th>
                  )}
                  {state.columns.map((c, index) => (
                    <th key={index} style={c.style}>
                      {c.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {state.list.map((i, index) => (
                  <tr key={index} className={state.classes && state.classes(i)}>
                    {menu && (
                      <td className="table-actions">
                        <Dropdown
                          overlay={() => menu(i)}
                          trigger={['click']}
                          placement="bottomLeft"
                          arrow
                        >
                          <div>
                            <FiMoreVertical />
                          </div>
                        </Dropdown>
                      </td>
                    )}
                    {checkbox && (
                      <td className="col-checkbox">
                        <Checkbox
                          disabled={i.disabled}
                          checked={state.selected.indexOf(i.id) > -1}
                          onChange={(e) => onChangeCheck(i, e.target.checked)}
                        />
                      </td>
                    )}
                    {state.columns.map((c, index) => (
                      <td key={index} style={c.style}>
                        {c.render(i)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="footer">
            <div className="count">{getFooter()}</div>
            <div className="right">
              <Select
                defaultValue={state.size.toString()}
                style={{ width: 160 }}
                bordered={false}
                onChange={onChangeSize}
              >
                <Select.Option value="10">10 resultados</Select.Option>
                <Select.Option value="20">20 resultados</Select.Option>
                <Select.Option value="50">50 resultados</Select.Option>
                <Select.Option value="100">100 resultados</Select.Option>
                <Select.Option value="1000">1000 resultados</Select.Option>
              </Select>
              <Pagination
                defaultPageSize={state.size}
                current={state.page}
                total={state.total}
                showSizeChanger={false}
                onChange={onChangePage}
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Table;
