/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Menu,
  Modal,
  Tooltip,
  Select,
} from "antd";
import { FiAlertTriangle, FiEdit, FiPlus, FiTrash2 } from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import { ContextProvider } from "../../services/context";
import http from "../../services/http";
import { showSuccess } from "./../../services/notification";
import { hasAction, actions } from "../../services/security";

import Header from "../../components/Header";
import Filters from "../../components/Filters";
import FiltersButton from "../../components/FiltersButton";
import Table from "../../components/Table";
import EstadoForm from "./EstadoForm";

const { Option } = Select;

const Reclamos = () => {
  const history = useHistory();

  const VER = hasAction(actions.RECLAMOS_VER);
  const CREAR = hasAction(actions.RECLAMOS_CREAR);
  const EDITAR = hasAction(actions.RECLAMOS_EDITAR);
  const ELIMINAR = hasAction(actions.RECLAMOS_ELIMINAR);

  const [state, setState] = useState({
    filters: {
      numero: "",
      desde: "",
      hasta: "",
      documento: "",
      tipo: "",
      area: "",
      prioridad: "",
      estado: "",
      creador: "",
      responsable: "",
    },
    columns: [
      {
        title: "",
        style: { minWidth: "30px" },
        render: (item) => {
          return (
            item.alerta && (
              <Tooltip title="Días excedidos">
                <FiAlertTriangle className="icon" />
              </Tooltip>
            )
          );
        },
      },
      {
        title: "Número",
        style: { minWidth: "90px", textAlign: "center" },
        render: (item) => item.id,
      },
      {
        title: "Fecha",
        style: { minWidth: "120px", textAlign: "center" },
        render: (item) => item.fecha,
      },

      {
        title: "Tipo",
        style: {},
        render: (item) => item.tipo,
      },
      {
        title: "Area",
        style: {},
        render: (item) => item.area,
      },
      {
        title: "Distrito",
        style: {},
        render: (item) => item.distrito,
      },
      {
        title: "Dirección",
        style: {},
        render: (item) => item.direccion,
      },
      {
        title: "Creado por",
        style: { minWidth: "200px" },
        render: (item) => item.creador,
      },
      {
        title: "Responsable",
        style: { minWidth: "200px" },
        render: (item) => item.responsable,
      },
      {
        title: "Prioridad",
        style: { textAlign: "center" },
        render: (item) => item.prioridad,
      },
      {
        title: "Estado",
        style: { textAlign: "center" },
        render: (item) => item.estado,
      },
      {
        title: "Ult. Actualización",
        style: { textAlign: "center" },
        render: (item) => item.actualizacion,
      },
    ],
    classes: (item) => {
      return item.alerta ? "warning" : "";
    },
    loading: true,
    loaded: false,
    reload: true,
    selected: [],
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });
  const [exportLoading, setExportLoading] = useState(false);

  const [areas, setAreas] = useState([]);
  const [distritos, setDistritos] = useState([]);
  const [estados, setEstados] = useState([]);
  const [prioridades, setPrioridades] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [modalEstadoVisible, setModalEstadoVisible] = useState(false);

  const breadcrumb = [{ id: 1, title: "Reclamos", url: "/reclamos" }];

  const buttons = () => {
    return (
      <React.Fragment>
        {state.selected.length > 0 && (
          <Button
            icon={<FiEdit />}
            style={{ minWidth: "150px" }}
            onClick={() => setModalEstadoVisible(true)}
          >
            Cambiar Estado
          </Button>
        )}
        <Button loading={exportLoading} onClick={onClickExport}>
          {!exportLoading && <React.Fragment>Exportar</React.Fragment>}
        </Button>
        <FiltersButton />
        {CREAR && (
          <Button type="primary" icon={<FiPlus />} onClick={onAdd}>
            Nuevo
          </Button>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Número" name="numero">
          <Input />
        </Form.Item>
        <Form.Item label="Desde" name="desde">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>
        <Form.Item label="Hasta" name="hasta">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>
        <Form.Item label="Documento" name="documento">
          <Input />
        </Form.Item>
        <Form.Item label="Tipo" name="tipo">
          <Select allowClear showSearch optionFilterProp="children">
            {tipos.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Area" name="area">
          <Select allowClear showSearch optionFilterProp="children">
            {areas.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Direccion" name="direccion">
          <Input />
        </Form.Item>
        <Form.Item label="Distrito" name="distrito">
          <Select allowClear showSearch optionFilterProp="children">
            {distritos.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Creado por" name="creador">
          <Select allowClear showSearch optionFilterProp="children">
            {usuarios.map((x) => (
              <Option key={x.value} value={x.text}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Responsable" name="responsable">
          <Select allowClear showSearch optionFilterProp="children">
            {usuarios.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Prioridad" name="prioridad">
          <Select allowClear showSearch optionFilterProp="children">
            {prioridades.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Estado" name="estado">
          <Select allowClear showSearch optionFilterProp="children">
            {estados.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="1" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("reclamos/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setAreas(data.areas);
        setDistritos(data.distritos);
        setEstados(data.estados);
        setPrioridades(data.prioridades);
        setTipos(data.tipos);
        setUsuarios(data.usuarios);
      }
    }

    getData();
  }, []);

  const onAdd = () => {
    history.push("/reclamos/nuevo");
  };

  const onEdit = (item) => {
    history.push(`/reclamos/${item.id}`);
  };

  const onClickExport = async () => {
    setExportLoading(true);

    const response = await http.post(`reclamos/export`, state.filters);
    if (response && response.data.code === 0) {
      const data = response.data.data;
      const linkSource = `data:application/octet-stream;base64,${data.content}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = data.filename;
      downloadLink.click();
    }

    setExportLoading(false);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este reclamo?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`reclamos/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("El reclamo fue eliminado exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  const onCheck = (list) => {
    setState({ ...state, selected: list });
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="reclamos page">
      <ContextProvider value={[state, setState]}>
        <Header title="Reclamos" breadcrumb={breadcrumb} buttons={buttons} />
        <Filters filters={filters} />
        <Table url="reclamos/list" menu={menu} checkbox onCheck={onCheck} />
        <Modal
          visible={modalEstadoVisible}
          onCancel={() => setModalEstadoVisible(false)}
          footer={null}
          destroyOnClose={true}
        >
          <EstadoForm onClose={() => setModalEstadoVisible(false)} />
        </Modal>
      </ContextProvider>
    </div>
  );
};

export default Reclamos;
