/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Button, Col, Form, Input, Row } from 'antd';
import { FiRepeat } from 'react-icons/fi';

import useQuery from '../../hooks/useQuery';

import { ContextProvider } from '../../services/context';
import http from '../../services/http';
import { showSuccess } from '../../services/notification';
import { hasAction, actions } from '../../services/security';

import Header from '../../components/Header';
import Items from './Items';
import Archivos from './Archivos';

const DevolucionesForm = ({ match }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const query = useQuery();

  const [state, setState] = useState({
    title: match.params.id === 'nuevo' ? 'Crear' : 'Editar',
    id: match.params.id === 'nuevo' ? 0 : parseInt(match.params.id),
    loaded: true,
    loading: false,
    reload: false,
    items: [],
  });

  const VER = hasAction(actions.DEVOLUCIONES_VER);
  const CREAR = hasAction(actions.DEVOLUCIONES_CREAR);
  const EDITAR = hasAction(actions.DEVOLUCIONES_EDITAR);
  const [readonly, setReadonly] = useState(state.id === 0 ? !VER : !EDITAR);

  const salida = query.get('salida');

  const breadcrumb = [
    { id: 1, title: 'Devoluciones', url: '/devoluciones' },
    { id: 2, title: state.title },
  ];

  useEffect(() => {
    async function getForm() {
      if (!state.loaded) {
        return;
      }

      const response = await http.get(
        `devoluciones/${state.id}?salida=${salida}`
      );
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setState({ ...state, items: data.items });
        setReadonly(data.estado > 1 ? true : readonly);
        form.setFieldsValue(data);
      }
    }

    getForm();
  }, [state.id, state.loaded, state.reload]);

  const onSave = async (values) => {
    values.salida = salida;
    values.items = state.items;

    const rSave =
      state.id === 0
        ? await http.post('devoluciones', values)
        : await http.put(`devoluciones/${state.id}`, values);

    if (rSave && rSave.data.code === 0) {
      showSuccess('La devolución fue guardada exitosamente.');
      history.push('/devoluciones');
    } else {
      setState({ ...state, loading: false });
    }
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <ContextProvider value={[state, setState]}>
      <div className="devolucion">
        <Header title={`${state.title} Devolución`} breadcrumb={breadcrumb} />
        <Form form={form} layout="vertical" onFinish={onSave}>
          <div className="form card">
            <div className="form-icon">
              <FiRepeat />
            </div>

            <Row gutter={[16, 5]}>
              <Col span={12}>
                <Form.Item label="Fecha" name="fecha">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Hora" name="hora">
                  <Input disabled={true} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Comentario"
                  name="comentario"
                  className="textarea"
                >
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    disabled={readonly}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <Items list={state.items} readonly={readonly} />
          {state.id > 0 && <Archivos />}

          <div className="form-actions">
            <Link to="/devoluciones">
              <Button type="secondary">Volver</Button>
            </Link>
            {CREAR && !readonly && (
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            )}
          </div>
        </Form>
      </div>
    </ContextProvider>
  );
};

export default DevolucionesForm;
