import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Row, Col } from "antd";
import { Animated } from "react-animated-css";
import { getJwt } from "./../../services/security";
import { LoginProvider } from "./LoginContext";
import LoginForm from "./LoginForm";
import ResetForm from "./ResetForm";
import logoVertical from "./../../assets/images/logo-vertical.png";
import "./Login.scss";
import UpdateForm from "./UpdateForm";

const Login = () => {
  const [login, setLogin] = useState({
    loading: false,
    username: "",
    password: "",
    confirm: "",
    form: "login",
    data: {},
  });

  if (getJwt() != null) {
    return <Redirect to="/" />;
  }

  return (
    <div className="login">
      <div className="card">
        <Row>
          <Col className="login-sidebar" xs={0} lg={8}>
            <div className="overlay">
              <h2 className="title">Bienvenido a tú Municipalidad Digital</h2>
              <p className="copyright">
              </p>
            </div>
          </Col>
          <Col className="login-form" xs={24} lg={16}>
            <img className="logo" src={logoVertical} alt="logo" />
            <LoginProvider value={[login, setLogin]}>
              {login.form === "login" && (
                <Animated
                  animationIn="fadeIn"
                  animationOut="fadeOut"
                  isVisible={!login.reset}
                >
                  <LoginForm />
                </Animated>
              )}
              {login.form === "reset" && (
                <Animated
                  animationIn="fadeIn"
                  animationOut="fadeOut"
                  isVisible={login.reset}
                >
                  <ResetForm />
                </Animated>
              )}
              {login.form === "update" && (
                <Animated
                  animationIn="fadeIn"
                  animationOut="fadeOut"
                  isVisible={login.reset}
                >
                  <UpdateForm />
                </Animated>
              )}
            </LoginProvider>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;
