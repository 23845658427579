import React, { useRef, useState } from 'react';
import { Modal } from 'antd';

import { convertToBase64, downloadBase64 } from '../../../services/files';
import http from '../../../services/http';
import { showSuccess, showWarning } from './../../../services/notification';

import excel from '../../../assets/images/excel.png';

import './ModalImport.scss';

const ModalImport = ({ visible, onSubmit, onClose }) => {
  const fileRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const onClickDownload = async () => {
    const url = 'notificaciones/catastro/import/excel';
    const response = await http.get(url);

    if (response && response.data.code === 0) {
      const data = response.data.data;
      downloadBase64(data.filename, data.content);
    }
  };

  const onClickUpload = () => {
    if (loading) {
      return;
    }

    fileRef.current.click();
  };

  const onUpload = async (e) => {
    const files = e.target.files;
    if (files.length === 0) {
      return;
    }

    setLoading(true);
    const base64 = await convertToBase64(files[0]);
    e.target.value = '';

    const url = 'notificaciones/catastro/import/excel';
    const data = {
      file: base64.split('base64,')[1],
    };

    const response = await http.post(url, data);
    setLoading(false);

    if (response && response.data.code === 0) {
      const data = response.data.data;

      if (data.repeated > 0) {
        showWarning(
          `El archivo se importo exitosamente pero se encontraron ${data.repeated} registros ya cargados en el sistema.`
        );
      } else {
        showSuccess('El archivo se importo exitosamente.');
      }

      onClose();
    }
  };

  return (
    <Modal
      title="Importar Excel"
      className="modal-catastro-import"
      visible={visible}
      footer={null}
      onCancel={onClose}
    >
      <div className="button" onClick={onClickDownload}>
        <div className="image">
          <img className="icon" src={excel} alt="logo" />
        </div>
        <div className="data">
          <div className="title">Descargar plantilla</div>
          <div className="description">
            Este archivo indica el formato que se debe respetar al importar un
            excel
          </div>
        </div>
      </div>
      <div className="button" onClick={onClickUpload}>
        <div className="image">
          <img className="icon" src={excel} alt="logo" />
        </div>
        <div className="data">
          <div className="title">Cargar archivo</div>
          <div className="description">
            Selecciona el archivo que quieras importar. Recorda que debe
            respetar el formato de la plantilla
          </div>
        </div>
      </div>
      <input
        type="file"
        ref={fileRef}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={onUpload}
      />
    </Modal>
  );
};

export default ModalImport;
