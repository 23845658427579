/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Col, Row } from "antd";

import PagoVoluntario from "./Rentas/PagoVoluntario";
import Recaudacion from "./Rentas/Recaudacion";
import CumplimientoContribuyentes from "./Rentas/CumplimientoContribuyentes";
import PadronContribuyentes from "./Rentas/PadronContribuyentes";
import GradoCobrabilidad from "./Rentas/GradoCobrabilidad";
import CobranzasApremios from "./Rentas/CobranzasApremios";

const Rentas = () => {
  return (
    <Row gutter={[20]} justify="center">
      <Col xs={24} lg={12}>
        <PadronContribuyentes />
      </Col>
      <Col xs={24} lg={12}>
        <CumplimientoContribuyentes />
      </Col>
      <Col xs={24} lg={12}>
        <GradoCobrabilidad />
      </Col>
      <Col xs={24} lg={12}>
        <CobranzasApremios />
      </Col>
      <Col xs={24}>
        <PagoVoluntario />
      </Col>
      <Col xs={24}>
        <Recaudacion />
      </Col>
    </Row>
  );
};

export default Rentas;
