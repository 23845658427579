/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Button, Form, Input, Menu, Popover, Select, Tooltip } from 'antd';
import {
  FiAlertTriangle,
  FiPrinter,
  FiRepeat,
  FiLogOut,
  FiLogIn,
} from 'react-icons/fi';
import fileDownload from 'js-file-download';

import { ContextProvider } from './../../services/context';
import http from './../../services/http';
import { hasAction, actions } from './../../services/security';

import Header from './../../components/Header';
import Filters from './../../components/Filters';
import FiltersButton from './../../components/FiltersButton';
import Table from './../../components/Table';
import Movimientos from './Movimientos';

const { Option } = Select;

const Deposito = ({ match }) => {
  const history = useHistory();

  const VER = hasAction(actions.INVENTARIO_VER);
  const EXPORTAR = hasAction(actions.INVENTARIO_EXPORTAR);
  const ENTRADAS_CREAR = hasAction(actions.INVENTARIO_ENTRADA_CREAR);
  const SALIDAS_CREAR = hasAction(actions.INVENTARIO_SALIDA_CREAR);

  const [state, setState] = useState({
    filters: {
      visible: false,
      deposito: match.params.id,
      categoria: '',
      producto: '',
      marca: '',
      modelo: '',
      descripcion: '',
      puntoPedido: '',
    },
    columns: [
      {
        title: 'Producto',
        style: {},
        render: (item) => item.nombre,
      },
      {
        title: 'Código',
        style: {},
        render: (item) => item.codigo,
      },
      {
        title: 'Stock',
        style: {},
        render: (item) => (
          <div className="row">
            {item.stock}
            {item.stockBajo && (
              <Tooltip title="Punto de pedido alcanzado">
                <FiAlertTriangle className="icon" />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        title: 'Categoría',
        style: {},
        render: (item) => item.categoria,
      },
      {
        title: 'Marca',
        style: {},
        render: (item) => item.marca,
      },
      {
        title: 'Modelo',
        style: {},
        render: (item) => item.modelo,
      },
      {
        title: 'Descripcion',
        style: {},
        render: (item) => {
          if (item.descripcion.length > 30) {
            return (
              <Popover
                placement="top"
                content={
                  <div style={{ maxWidth: '400px' }}>{item.descripcion}</div>
                }
              >
                {item.descripcion.substring(0, 30)}...
              </Popover>
            );
          }

          return item.descripcion;
        },
      },
    ],
    classes: (item) => {
      return item.stockBajo ? 'warning' : '';
    },
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const [loadingExport, setLoadingExport] = useState(false);

  const [deposito, setDeposito] = useState({ text: '', value: '' });
  const [categorias, setCategorias] = useState([]);
  const [productos, setProductos] = useState([]);
  const [current, setCurrent] = useState();

  const breadcrumb = [
    { id: 1, title: 'Inventario', url: '/inventario' },
    {
      id: 2,
      title: deposito.text,
      url: `/inventario/depositos/${deposito.value}`,
    },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        {ENTRADAS_CREAR && (
          <Button type="primary" icon={<FiLogIn />} onClick={onAddEntrada}>
            Entrada
          </Button>
        )}
        {SALIDAS_CREAR && (
          <Button type="primary" icon={<FiLogOut />} onClick={onAddSalida}>
            Salida
          </Button>
        )}
        <FiltersButton />
        {EXPORTAR && (
          <Button
            type="primary"
            icon={<FiPrinter />}
            loading={loadingExport}
            onClick={onExport}
          >
            {!loadingExport && 'Exportar'}
          </Button>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Categoría" name="categoria">
          <Select allowClear showSearch optionFilterProp="children">
            {categorias.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Producto" name="producto">
          <Select allowClear showSearch optionFilterProp="children">
            {productos.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Marca" name="marca">
          <Input />
        </Form.Item>
        <Form.Item label="Modelo" name="modelo">
          <Input />
        </Form.Item>
        <Form.Item label="Descripción" name="descripcion">
          <Input />
        </Form.Item>
        <Form.Item label="Punto de Pedido" name="puntoPedido">
          <Select allowClear>
            <Option value="0">No</Option>
            <Option value="1">Si</Option>
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {VER && (
          <Menu.Item key="1" onClick={() => onClickMovimientos(item)}>
            <FiRepeat />
            Ver Movimientos
          </Menu.Item>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get(
        `inventario/deposito/data?id=${state.filters.deposito}`
      );
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setDeposito(data.deposito);
        setCategorias(data.categorias);
        setProductos(data.productos);
      }
    }

    getData();
  }, []);

  const onAddEntrada = () => {
    history.push(`/entradas/nuevo?deposito=${deposito.value}`);
  };

  const onAddSalida = () => {
    history.push(`/salidas/nuevo?tipo=2&origen=${deposito.value}`);
  };

  const onExport = async () => {
    setLoadingExport(true);

    const response = await http.post(
      'inventario/deposito/export',
      state.filters,
      true
    );
    fileDownload(response.data, `Deposito_${Date.now()}.xlsx`);

    setLoadingExport(false);
  };

  const onClickMovimientos = (item) => {
    setCurrent(item);
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="deposito page">
      <ContextProvider value={[state, setState]}>
        <Header
          title={deposito.text}
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Table url="inventario/deposito" menu={menu} />
        <Movimientos deposito={current} onClose={() => setCurrent()} />
      </ContextProvider>
    </div>
  );
};

export default Deposito;
