/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Col, Form, Input, Row, Select, Tag } from "antd";
import { FiLogIn } from "react-icons/fi";

import useQuery from "../../hooks/useQuery";

import { ContextProvider } from "../../services/context";
import http from "../../services/http";
import { showSuccess } from "../../services/notification";
import { required } from "../../services/validation";

import Header from "../../components/Header";

const EntradasForm = ({ match }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const query = useQuery();

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : "Editar",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loaded: false,
    loading: false,
    reload: false,
  });

  const breadcrumb = [
    { id: 1, title: "Entradas" },
    { id: 2, title: state.title },
  ];

  const deposito = query.get("deposito");

  const [expedientes, setExpedientes] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [productos, setProductos] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await http.get("entradas/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setExpedientes(data.expedientes);
        setState({ ...state, loaded: true });
      }
    }

    getData();
  }, []);

  useEffect(() => {
    async function getForm() {
      if (!state.loaded) {
        return;
      }

      const response = await http.get(
        `entradas/${state.id}?deposito=${deposito}`
      );
      if (response && response.data.code === 0) {
        const data = response.data.data;
        form.setFieldsValue(data);
      }
    }

    getForm();
  }, [state.loaded]);

  const onChangeExpediente = async (value) => {
    const response = await http.get(`entradas/data/expediente/${value}`);
    if (response && response.data.code === 0) {
      const data = response.data.data;
      setProductos(data.productos);
      setProveedores(data.proveedores);

      if (data.proveedores.length === 1) {
        form.setFieldsValue({ proveedor: data.proveedores[0].value });
      }
    }
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    values.deposito = deposito;

    const response =
      state.id === 0
        ? await http.post("entradas", values)
        : await http.put(`entradas/${state.id}`, values);

    if (response && response.data.code === 0) {
      showSuccess("La entrada fue guardada exitosamente.");
      history.goBack();
    } else {
      setState({ ...state, loading: false });
    }
  };

  return (
    <ContextProvider value={[state, setState]}>
      <div className="entrada">
        <Header title={`${state.title} Entrada`} breadcrumb={breadcrumb} />
        <Form form={form} layout="vertical" onFinish={onSave}>
          <div className="form card">
            <div className="form-icon">
              <FiLogIn />
            </div>

            <Row gutter={[16, 5]}>
              <Col span={12}>
                <Form.Item label="Fecha" name="fecha">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Hora" name="hora">
                  <Input disabled={true} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Expediente"
                  name="expediente"
                  rules={[required]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={onChangeExpediente}
                  >
                    {expedientes.map((x) => (
                      <Select.Option key={x.value} value={x.value}>
                        {x.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Proveedor"
                  name="proveedor"
                  rules={[required]}
                >
                  <Select showSearch optionFilterProp="children">
                    {proveedores.map((x) => (
                      <Select.Option key={x.value} value={x.value}>
                        {x.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Depósito" name="depositoTexto">
                  <Input disabled={true} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Producto" name="producto" rules={[required]}>
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    suffix={<Tag color="red">2/10</Tag>}
                  >
                    {productos.map((x) => (
                      <Select.Option key={x.value} value={x.value}>
                        {x.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Cantidad" name="cantidad" rules={[required]}>
                  <Input
                    type="number"
                    step="any"
                    onWheel={(e) => e.target.blur()}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Marca" name="marca">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Modelo" name="modelo">
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Nº Remito" name="remito">
                  <Input
                    type="number"
                    step="any"
                    onWheel={(e) => e.target.blur()}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Observaciones"
                  name="observaciones"
                  className="textarea"
                >
                  <Input.TextArea showCount maxLength={500} />
                </Form.Item>
              </Col>
            </Row>

            <div className="form-actions">
              <Link to="/">
                <Button type="secondary">Volver</Button>
              </Link>
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </ContextProvider>
  );
};

export default EntradasForm;
