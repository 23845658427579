/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FiFileText } from "react-icons/fi";

import { ContextProvider } from "../../services/context";
import http from "../../services/http";
import { showSuccess } from "../../services/notification";
import { hasAction, actions } from "../../services/security";
import { required } from "./../../services/validation";

import Header from "../../components/Header";
import Archivos from "./Archivos";
import Entradas from "./Entradas";
import Productos from "./Productos";

import "./ExpedientesForm.scss";

const ExpedientesForm = ({ match }) => {
  const [form] = Form.useForm();
  const history = useHistory();

  const VER = hasAction(actions.EXPEDIENTES_VER);
  const CREAR = hasAction(actions.EXPEDIENTES_CREAR);
  const EDITAR = hasAction(actions.EXPEDIENTES_EDITAR);

  const readonly = match.params.id === "nuevo" ? !CREAR : !EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loaded: false,
    loading: false,
    reload: false,
    direccion: null,
    proveedores: [],
  });

  const [direcciones, setDirecciones] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [tipos, setTipos] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Expedientes", url: "/expedientes" },
    { id: 2, title: state.title },
  ];

  useEffect(() => {
    async function getData() {
      const response = await http.get("expedientes/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setDirecciones(data.direcciones);
        setProveedores(data.proveedores);
        setTipos(data.tipos);
        setState({ ...state, loaded: true });
      }
    }

    getData();
  }, []);

  useEffect(() => {
    async function getForm() {
      if (!state.loaded) {
        return;
      }

      const response = await http.get(`expedientes/${state.id}`);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        form.setFieldsValue(data);
        setState({
          ...state,
          direccion: data.direccion,
          proveedores: data.proveedores,
        });
      }
    }

    getForm();
  }, [state.id, state.loaded, state.reload]);

  const onChangeDireccion = (value) => {
    setState({ ...state, direccion: value });
  };

  const onChangeProveedores = (value) => {
    setState({ ...state, proveedores: value });
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const response =
      state.id === 0
        ? await http.post("expedientes", values)
        : await http.put(`expedientes/${state.id}`, values);

    if (response && response.data.code === 0) {
      const data = response.data.data;

      if (state.id === 0) {
        Modal.confirm({
          title: "Continuar editando",
          icon: <ExclamationCircleOutlined />,
          content: "¿Desea seguir editando este expediente?",
          okText: "Confirmar",
          cancelText: "Cancelar",
          onOk: () => {
            setState({ ...state, id: data, title: "Editar" });
            history.push(`/expedientes/${data}`);
          },
          onCancel: () => history.push("/expedientes"),
        });
      } else {
        showSuccess("El expediente fue guardado exitosamente.");
        history.push("/expedientes");
      }
    } else {
      setState({ ...state, loading: false });
    }
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <ContextProvider value={[state, setState]}>
      <div className="expediente">
        <Header title={`${state.title} Expediente`} breadcrumb={breadcrumb} />
        <Form form={form} layout="vertical" onFinish={onSave}>
          <div className="form card">
            <div className="form-icon">
              <FiFileText />
            </div>
            <Row gutter={[16, 5]}>
              <Col span={12}>
                <Form.Item label="Número" name="numero" rules={[required]}>
                  <Input disabled={readonly} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Fecha" name="fecha">
                  <Input disabled={true} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Dirección"
                  name="direccion"
                  rules={[required]}
                >
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    disabled={readonly}
                    onChange={onChangeDireccion}
                  >
                    {direcciones.map((x) => (
                      <Select.Option
                        key={x.value}
                        value={x.value}
                        disabled={x.disabled}
                      >
                        {x.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Tipo" name="tipo" rules={[required]}>
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    disabled={readonly}
                  >
                    {tipos.map((x) => (
                      <Select.Option key={x.value} value={x.value}>
                        {x.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Plazo de Entrega" name="plazoEntrega">
                  <Input disabled={readonly} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Proveedores" name="proveedores">
                  <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    disabled={readonly}
                    onChange={onChangeProveedores}
                  >
                    {proveedores.map((x) => (
                      <Select.Option key={x.value} value={x.value}>
                        {x.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <div className="form-actions">
              <Link to="/expedientes">
                <Button type="secondary">Volver</Button>
              </Link>
              {!readonly && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={state.loading}
                >
                  Guardar
                </Button>
              )}
            </div>
          </div>
        </Form>

        {state.id > 0 && (
          <React.Fragment>
            <Productos />
            <Entradas />
            <Archivos />
          </React.Fragment>
        )}
      </div>
    </ContextProvider>
  );
};

export default ExpedientesForm;
