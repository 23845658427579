/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Chart from "react-apexcharts";

import { Context } from "../../../services/context";
import http from "../../../services/http";

import empty from "../../../assets/images/empty.svg";

const Prioridad = () => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);

  const [options, setOptions] = useState({
    chart: {
      type: "donut",
    },
    labels: [],
  });
  const [series, setSeries] = useState([]);

  useEffect(() => {
    async function getData() {
      const filters = state.getFilters();
      const response = await http.get("tableros/reclamos/prioridad" + filters);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setOptions({ ...options, labels: data.categories });
        setSeries(data.serie);
      }
    }

    getData();
  }, [state.reload]);

  return (
    <div className="chart card">
      <div className="header">
        <div className="title">Prioridad</div>
      </div>

      {series.length === 0 && (
        <div className="empty">
          <img src={empty} alt="empty" />
          Sin resultados
        </div>
      )}

      {series.length > 0 && (
        <Chart
          className="canvas"
          options={options}
          series={series}
          type="donut"
          height={300}
          width="100%"
        />
      )}
    </div>
  );
};

export default Prioridad;
