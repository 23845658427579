/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Form, Input, Menu, Select } from "antd";
import { FiSearch } from "react-icons/fi";

import { ContextProvider } from "./../../services/context";
import http from "./../../services/http";
import { hasAction, actions } from "./../../services/security";

import Header from "./../../components/Header";
import Filters from "./../../components/Filters";
import FiltersButton from "./../../components/FiltersButton";
import Table from "./../../components/Table";

const Inventario = () => {
  const history = useHistory();

  const VER = hasAction(actions.INVENTARIO_VER);
  const VER_TODO = hasAction(actions.INVENTARIO_VER_TODO);

  const [state, setState] = useState({
    filters: {
      visible: false,
      nombre: "",
      producto: "",
      direccion: "",
    },
    columns: [
      {
        id: 1,
        title: "Depósito",
        style: {},
        render: (item) => item.nombre,
      },
      {
        id: 2,
        title: "Código",
        style: {},
        render: (item) => item.codigo,
      },
      {
        id: 3,
        title: "Dirección",
        style: {},
        render: (item) => item.direccion,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const [direcciones, setDirecciones] = useState([]);
  const [productos, setProductos] = useState([]);

  const breadcrumb = [{ id: 1, title: "Inventario", url: "/inventario" }];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Nombre" name="nombre">
          <Input />
        </Form.Item>
        <Form.Item label="Producto" name="producto">
          <Select allowClear showSearch optionFilterProp="children">
            {productos.map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {VER_TODO && (
          <Form.Item label="Dirección" name="direccion">
            <Select allowClear showSearch optionFilterProp="children">
              {direcciones.map((x) => (
                <Select.Option key={x.value} value={x.value}>
                  {x.text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {VER && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiSearch />
            Ver
          </Menu.Item>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("inventario/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setDirecciones(data.direcciones);
        setProductos(data.productos);
      }
    }

    getData();
  }, []);

  useEffect(() => {
    if (
      state.total === 1 &&
      state.filters.nombre === "" &&
      state.filters.producto === "" &&
      state.filters.direccion === ""
    ) {
      const item = state.list[0];
      history.push(`/inventario/depositos/${item.id}`);
    }
  }, [state.total]);

  const onEdit = (item) => {
    history.push(`/inventario/depositos/${item.id}`);
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="inventario page">
      <ContextProvider value={[state, setState]}>
        <Header title="Inventario" breadcrumb={breadcrumb} buttons={buttons} />
        <Filters filters={filters} />
        <Table url="inventario/list" menu={menu} />
      </ContextProvider>
    </div>
  );
};

export default Inventario;
