/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Form, Input, Menu, Modal, Select } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FiEdit, FiPlus, FiTrash2 } from "react-icons/fi";

import { ContextProvider } from "./../../../services/context";
import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notification";
import { hasAction, actions } from "./../../../services/security";

import Header from "./../../../components/Header";
import Filters from "./../../../components/Filters";
import FiltersButton from "./../../../components/FiltersButton";
import Table from "./../../../components/Table";

const { Option } = Select;

const Direcciones = () => {
  const history = useHistory();

  const VER = hasAction(actions.DIRECCIONES_VER);
  const CREAR = hasAction(actions.DIRECCIONES_CREAR);
  const EDITAR = hasAction(actions.DIRECCIONES_EDITAR);
  const ELIMINAR = hasAction(actions.DIRECCIONES_ELIMINAR);

  const [state, setState] = useState({
    filters: {
      visible: false,
      nombre: "",
      responsable: "",
    },
    columns: [
      {
        id: 1,
        title: "Nombre",
        style: {},
        render: (item) => item.nombre,
      },
      {
        id: 2,
        title: "Responsable",
        style: {},
        render: (item) => item.responsable,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });
  const [usuarios, setUsuarios] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Configuración" },
    { id: 2, title: "Direcciones", url: "/configuracion/direcciones" },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        {CREAR && (
          <Button type="primary" icon={<FiPlus />} onClick={onAdd}>
            Nuevo
          </Button>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Nombre" name="nombre">
          <Input />
        </Form.Item>
        <Form.Item label="Responsable" name="responsable">
          <Select allowClear>
            {usuarios.map((g) => (
              <Option key={g.value} value={g.value}>
                {g.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="1" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("direcciones/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setUsuarios(data.usuarios);
      }
    }

    getData();
  }, []);

  const onAdd = () => {
    history.push("/configuracion/direcciones/nuevo");
  };

  const onEdit = (item) => {
    history.push(`/configuracion/direcciones/${item.id}`);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar esta dirección?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`direcciones/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("La dirección fue eliminada exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="direcciones page">
      <ContextProvider value={[state, setState]}>
        <Header title="Direcciones" breadcrumb={breadcrumb} buttons={buttons} />
        <Filters filters={filters} />
        <Table url="direcciones/list" menu={menu} />
      </ContextProvider>
    </div>
  );
};

export default Direcciones;
