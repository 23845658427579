import React, { useContext, useState } from "react";
import { Dropdown, Menu, Button, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  FiChevronDown,
  FiChevronUp,
  FiEdit,
  FiMoreVertical,
  FiPercent,
  FiTrash2,
} from "react-icons/fi";
import { SlideDown } from "react-slidedown";

import { Context } from "./../../../services/context";
import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notification";
import { hasAction, actions } from "./../../../services/security";

import EquivalenciasForm from "./EquivalenciasForm";

const Equivalencias = () => {
  const CREAR = hasAction(actions.UNIDADES_EQUIVALENCIAS_CREAR);
  const EDITAR = hasAction(actions.UNIDADES_EQUIVALENCIAS_EDITAR);
  const ELIMINAR = hasAction(actions.UNIDADES_EQUIVALENCIAS_ELIMINAR);

  const [state, setState] = useContext(Context);
  const [visible, setVisible] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [current, setCurrent] = useState({});

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="1" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="2" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onAdd = () => {
    setCurrent({ importe: 0 });
    setFormVisible(true);
  };

  const onEdit = (item) => {
    setCurrent(item);
    setFormVisible(true);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar esta equivalencia?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`unidades/equivalencias/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("La equivalencia fue eliminada exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  return (
    <div className="items form-subsection">
      <div
        className="form-subsection-header"
        onClick={() => setVisible(!visible)}
      >
        <div className="icon">
          <FiPercent />
        </div>
        <div className="content">
          <div className="title">Equivalencias</div>
          <div className="subtitle">{state.equivalencias.length} elementos</div>
        </div>
        <div className="collapse">
          {visible ? <FiChevronUp /> : <FiChevronDown />}
        </div>
      </div>
      <SlideDown closed={!visible}>
        <div className="form-subsection-content">
          <table className="table table-small">
            <thead>
              <tr>
                <th>Unidad de Medida</th>
                <th>Abreviatura</th>
                <th>Multiplicador</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {state.equivalencias.length === 0 && (
                <tr>
                  <td className="table-empty" colSpan="6">
                    Sin resultados
                  </td>
                </tr>
              )}

              {state.equivalencias.map((x) => (
                <tr key={x.id}>
                  <td>{x.nombre}</td>
                  <td>{x.abreviatura}</td>
                  <td>{x.multiplicador}</td>
                  <td className="table-actions">
                    <Dropdown
                      key={x.id}
                      overlay={() => menu(x)}
                      trigger={["click"]}
                      placement="bottomRight"
                      arrow
                    >
                      <div>
                        <FiMoreVertical />
                      </div>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {CREAR && (
            <div className="actions">
              <Button type="primary" onClick={onAdd}>
                Agregar
              </Button>
            </div>
          )}
        </div>
      </SlideDown>

      <Modal
        visible={formVisible}
        onCancel={() => setFormVisible(false)}
        footer={null}
        maskClosable={false}
        keyboard={false}
        destroyOnClose={true}
      >
        <EquivalenciasForm
          item={current}
          onClose={() => setFormVisible(false)}
        />
      </Modal>
    </div>
  );
};

export default Equivalencias;
