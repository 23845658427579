/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { DatePicker, Form, Input, Button, Select, Modal, Row, Col } from "antd";
import { FiMap } from "react-icons/fi";
import * as moment from "moment";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";

import { ContextProvider } from "./../../../services/context";
import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notification";
import { hasAction, actions } from "./../../../services/security";
import { required } from "./../../../services/validation";

import Archivos from "./Archivos";
import Comentarios from "./Comentarios";
import Header from "./../../../components/Header";
import Historial from "./Historial";

import "./CatastroForm.scss";

const { Option } = Select;

const CatastroForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.CATASTRO_VER);
  const EDITAR = hasAction(actions.CATASTRO_EDITAR);

  const [state, setState] = useState({
    title: EDITAR ? "Editar" : "Ver",
    id: parseInt(match.params.id),
    loading: false,
    reload: false,
    historial: [],
    archivos: [],
    comentarios: [],
  });

  const [estados, setEstados] = useState([]);
  const [historial, setHistorial] = useState(false);

  const breadcrumb = [
    { id: 1, title: "Notificaciones" },
    { id: 2, title: "Catastro", url: "/notificaciones/catastro" },
    { id: 3, title: state.title },
  ];

  const onHistoryClick = () => {
    setHistorial(true);
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    values.notificacion =
      values.notificacion !== undefined
        ? values.notificacion.format("DD/MM/YYYY")
        : undefined;

    const rSave =
      state.id === 0
        ? await http.post("notificaciones/catastro", values)
        : await http.put(`notificaciones/catastro/${state.id}`, values);

    if (rSave && rSave.data.code === 0) {
      showSuccess("El registro de catastro fue guardado exitosamente.");
      history.push("/notificaciones/catastro");
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getData() {
      const rData = await http.get("notificaciones/catastro/data");
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;

        setEstados(data.estados);
      }
    }

    if (VER) {
      getData();
    }
  }, []);

  useEffect(() => {
    async function getForm() {
      const rForm = await http.get(`notificaciones/catastro/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;

        setState({
          ...state,
          historial: data.historial,
          archivos: data.archivos,
          comentarios: data.comentarios,
        });

        data.notificacion =
          data.notificacion === undefined
            ? undefined
            : moment(data.notificacion, "DD/MM/YYYY");

        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, [state.id, state.reload]);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <ContextProvider value={[state, setState]}>
      <div className="catastro">
        <Header
          title={`${state.title} Notificación de Catastro`}
          breadcrumb={breadcrumb}
        />
        <Form form={form} layout="vertical" onFinish={onSave}>
          <div className="form card">
            <div className="form-icon">
              <FiMap />
            </div>
            <Row gutter={[16, 5]}>
              <Col span={12}>
                <Form.Item label="Titular" name="titular">
                  <Input disabled={!EDITAR} />
                </Form.Item>
              </Col>
              <Col span={12} className="section-estado">
                <Form.Item label="Estado" name="estado" rules={[required]}>
                  <Select disabled={!EDITAR}>
                    {estados.map((x) => (
                      <Option key={x.value} value={x.value}>
                        {x.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <small onClick={onHistoryClick}>Ver historial</small>
                <Modal
                  className="catastro-historial"
                  title="Historial"
                  visible={historial}
                  onCancel={() => setHistorial(false)}
                  footer={null}
                  maskClosable={false}
                  keyboard={false}
                  destroyOnClose={true}
                  width={700}
                >
                  <Historial onClose={() => setHistorial(false)} />
                </Modal>
              </Col>

              <Col span={12}>
                <Form.Item label="N° Padrón" name="padron">
                  <Input disabled={!EDITAR} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Expediente" name="expediente">
                  <Input disabled={!EDITAR} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Domicilio" name="domicilio">
                  <Input disabled={!EDITAR} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Domicilio Postal" name="domicilioPostal">
                  <Input disabled={!EDITAR} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Distrito" name="distrito">
                  <Input disabled={!EDITAR} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Superficie" name="superficie">
                  <Input disabled={!EDITAR} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Declarado" name="declarado">
                  <Input disabled={!EDITAR} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="No declarado" name="diferencia">
                  <Input disabled={!EDITAR} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Fecha de Notificación" name="notificacion">
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder=""
                    locale={locale}
                    disabled={!EDITAR}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div className="form-actions">
              <Link to="/notificaciones/catastro">
                <Button type="secondary">Volver</Button>
              </Link>
              <Button type="primary" htmlType="submit" loading={state.Loading}>
                Guardar
              </Button>
            </div>
          </div>
        </Form>

        {state.id > 0 && (
          <React.Fragment>
            <Archivos />
            <Comentarios />
          </React.Fragment>
        )}
      </div>
    </ContextProvider>
  );
};

export default CatastroForm;
