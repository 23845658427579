/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import { Form, Input, Modal, Button } from "antd";
import { FiMessageSquare } from "react-icons/fi";

import { Context } from "./../../../services/context";
import http from "./../../../services/http";
import { required } from "./../../../services/validation";

const { TextArea } = Input;

const ComentariosForm = ({ item, onClose }) => {
  const [form] = Form.useForm();

  const [state, setState] = useContext(Context);
  const [loading, setLoading] = useState(false);

  const onSave = async (values) => {
    setLoading(true);

    values.catastroId = state.id;

    const rSave =
      item.id === undefined
        ? await http.post("notificaciones/catastro/comentarios", values)
        : await http.put(
            `notificaciones/catastro/comentarios/${item.id}`,
            values
          );

    if (rSave && rSave.data.code === 0) {
      setState({ ...state, reload: !state.reload });
      setLoading(false);
      onClose();
    } else {
      setLoading(false);
    }
  };

  form.setFieldsValue(item);

  return (
    <Modal
      visible={item != null}
      onCancel={onClose}
      footer={null}
      maskClosable={false}
      keyboard={false}
      destroyOnClose={true}
    >
      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form">
          <div className="form-icon">
            <FiMessageSquare />
          </div>

          <Form.Item name="contenido" className="textarea" rules={[required]}>
            <TextArea showCount maxLength={2000} rows={6} />
          </Form.Item>

          <div className="form-actions">
            <Button type="secondary" onClick={onClose}>
              Volver
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Guardar
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ComentariosForm;
