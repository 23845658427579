/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Button, Col, Form, Input, Row, Select, Tooltip } from "antd";
import { FiMapPin } from "react-icons/fi";

import http from "../../../services/http";
import { search } from "../../../services/maps";
import { showSuccess } from "../../../services/notification";
import { hasAction, actions } from "../../../services/security";
import { required } from "../../../services/validation";

import Header from "../../../components/Header";
import Map from "../../../components/Map";

const PuntosReclamoForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.PUNTOS_RECLAMO_VER);
  const CREAR = hasAction(actions.PUNTOS_RECLAMO_CREAR);
  const EDITAR = hasAction(actions.PUNTOS_RECLAMO_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });

  const [distritos, setDistritos] = useState([]);
  const [tipos, setTipos] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Configuración" },
    { id: 2, title: "Puntos de Reclamo", url: "/configuracion/puntosreclamo" },
    { id: 3, title: state.title },
  ];

  const [center, setCenter] = useState({
    lat: -32.8983746,
    lng: -68.7958638,
  });
  const [zoom, setZoom] = useState(13);
  const [marcador, setMarcador] = useState();

  useEffect(() => {
    async function getForm() {
      const rData = await http.get("reclamospuntos/data");
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setDistritos(data.distritos);
        setTipos(data.tipos);
      }

      const rForm = await http.get(`reclamospuntos/${state.id}`);
      if (rForm.data.code === 0) {
        let data = rForm.data.data;
        form.setFieldsValue(data);

        if (data.latitud && data.longitud) {
          const coordenadas = {
            lat: parseFloat(data.latitud),
            lng: parseFloat(data.longitud),
          };

          setMarcador(coordenadas);
          setCenter(coordenadas);
          setZoom(15);
        }
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  const onSearch = async () => {
    let value = form.getFieldValue("direccion");

    if (!value || !GUARDAR) {
      return;
    }

    value += ", Guaymallen, Mendoza, Argentina";

    const coordenadas = await search(value);
    setMarcador(coordenadas);
    setCenter(coordenadas);
    setZoom(15);
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    values.latitud = marcador.lat;
    values.longitud = marcador.lng;

    const result =
      state.id === 0
        ? await http.post("reclamospuntos", values)
        : await http.put(`reclamospuntos/${state.id}`, values);

    if (result && result.data.code === 0) {
      history.push("/configuracion/puntosreclamo");
      showSuccess("El punto de reclamo fue guardado exitosamente.");
    } else {
      setState({ ...state, loading: false });
    }
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="puntoReclamo">
      <Header
        title={`${state.title} Punto de Reclamo`}
        breadcrumb={breadcrumb}
      />

      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form card">
          <div className="form-icon">
            <FiMapPin />
          </div>

          <Row gutter={20}>
            <Col lg={12}>
              <Form.Item label="Número" name="numero">
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col lg={12}>
              <Form.Item label="Tipo" name="tipo" rules={[required]}>
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  disabled={!GUARDAR}
                >
                  {tipos.map((x) => (
                    <Select.Option key={x.value} value={x.value}>
                      {x.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col lg={24}>
              <Form.Item
                label="Descripción"
                name="descripcion"
                className="textarea"
              >
                <Input.TextArea showCount maxLength={500} disabled={!GUARDAR} />
              </Form.Item>
            </Col>

            <Col lg={24}>
              <Form.Item label="Distrito" name="distrito" rules={[required]}>
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  disabled={!GUARDAR}
                >
                  {distritos.map((x) => (
                    <Select.Option key={x.value} value={x.value}>
                      {x.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col lg={22}>
              <Form.Item label="Dirección" name="direccion" rules={[required]}>
                <Input
                  maxLength={250}
                  placeholder="Calle y número"
                  disabled={!GUARDAR}
                />
              </Form.Item>
            </Col>
            <Col lg={2}>
              <Tooltip placement="top" title="Ubicar en el mapa">
                <Button
                  type="primary"
                  shape="circle"
                  className="circle-inline"
                  icon={<FiMapPin />}
                  onClick={onSearch}
                />
              </Tooltip>
            </Col>

            <Col lg={24}>
              <Map
                center={center}
                zoom={zoom}
                marcador={marcador}
                onUpdate={setMarcador}
                editable={GUARDAR}
              />
            </Col>
          </Row>

          <div className="form-actions">
            <Link to="/configuracion/puntosreclamo">
              <Button type="secondary">Volver</Button>
            </Link>
            {GUARDAR && (
              <Button type="primary" htmlType="submit" loading={state.Loading}>
                Guardar
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default PuntosReclamoForm;
