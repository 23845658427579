/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tooltip,
} from "antd";
import { FiMapPin, FiMessageSquare } from "react-icons/fi";
import * as moment from "moment";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { ContextProvider } from "../../services/context";
import http from "../../services/http";
import { search } from "../../services/maps";
import { modalSuccess, showSuccess } from "../../services/notification";
import { hasAction, actions } from "../../services/security";
import { regex, required } from "./../../services/validation";

import Archivos from "./Archivos";
import Header from "../../components/Header";
import Historial from "./Historial";
import Map from "../../components/Map";

import "./ReclamosForm.scss";

const { TextArea } = Input;
const { Option } = Select;

const ReclamosForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.RECLAMOS_VER);
  const CREAR = hasAction(actions.RECLAMOS_CREAR);
  const EDITAR = hasAction(actions.RECLAMOS_EDITAR);
  const [GUARDAR, SETGUARDAR] = useState(
    match.params.id === "nuevo" ? CREAR : EDITAR
  );

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
    reload: false,
    archivos: [],
    historial: [],
  });
  const [estado, setEstado] = useState();
  const [labelUsuario, setLabelUsuario] = useState("Responsable");

  const [areas, setAreas] = useState([]);
  const [distritos, setDistritos] = useState([]);
  const [estados, setEstados] = useState([]);
  const [prioridades, setPrioridades] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [usuarios, setUsuarios] = useState([]);

  const [center, setCenter] = useState({
    lat: -32.8983746,
    lng: -68.7958638,
  });
  const [zoom, setZoom] = useState(14);
  const [marcador, setMarcador] = useState();

  const breadcrumb = [
    { id: 1, title: "Reclamos", url: "/reclamos" },
    { id: 2, title: state.title },
  ];

  useEffect(() => {
    async function getForm() {
      let response = await http.get("reclamos/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setAreas(data.areas);
        setDistritos(data.distritos);
        setEstados(data.estados);
        setPrioridades(data.prioridades);
        setTipos(data.tipos);
      }

      response = await http.get(`reclamos/${state.id}`);
      if (response && response.data.code === 0) {
        const data = response.data.data;

        data.fecha =
          data.fecha === undefined
            ? undefined
            : moment(data.fecha, "DD/MM/YYYY");

        if (data.tipo) {
          await onChangeTipo(data.tipo);
        }

        if (data.area) {
          await onChangeArea(data.area);
        }

        form.setFieldsValue(data);

        if (data.latitud && data.longitud) {
          const coordenadas = {
            lat: parseFloat(data.latitud),
            lng: parseFloat(data.longitud),
          };

          setMarcador(coordenadas);
          setCenter(coordenadas);
          setZoom(15);
        }

        SETGUARDAR(data.editable);

        setState({
          ...state,
          archivos: data.archivos,
          historial: data.historial,
        });

        setEstado(data.estado);
      }
    }

    if (VER) {
      getForm();
    }
  }, [state.id, state.reload]);

  const regexDocumento = {
    pattern: /^\d{7,8}$/g,
    message: "El documento debe contener entre 7 y 8 dígitos",
  };

  const regexTelefono = {
    pattern: /^\d{10}$/g,
    message: "El teléfono debe contener 10 dígitos",
  };

  const regexEmail = {
    pattern:
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/g,
    message: "El email ingresado no es una dirección de correo válida",
  };

  const onSearch = async () => {
    let value = form.getFieldValue("direccion");

    if (!value || !GUARDAR) {
      return;
    }

    value += ", Guaymallen, Mendoza, Argentina";

    const coordenadas = await search(value);
    setMarcador(coordenadas);
    setCenter(coordenadas);
    setZoom(15);
  };

  const onChangeTipo = async (value) => {
    form.setFieldsValue({ area: null, usuario: null });

    const response = await http.get(`reclamos/data/tipo?tipo=${value}`);
    if (response && response.data.code === 0) {
      const data = response.data.data;
      setAreas(data.areas);
    }
  };

  const onChangeArea = async (value) => {
    form.setFieldsValue({ usuario: null });

    if (!value) {
      return;
    }

    await updateUsuarios(value, estado);

    if (estado === "1") {
      form.setFieldsValue({ estado: "2" });
    }
  };

  const updateUsuarios = async (area, estado, usuario = undefined) => {
    let rol = "";

    if (estado === "6") {
      rol = "Auditor";
    } else if (estado === "4" || estado === "5") {
      rol = "Supervisor";
    } else {
      rol = "Responsable";
    }

    const response = await http.get(`reclamos/data/area?area=${area}`);
    if (response && response.data.code === 0) {
      const data = response.data.data;

      if (!form.getFieldValue("tipo")) {
        setTipos(data.tipos);
      }

      let nUsuarios = data.usuarios.filter(
        (x) => x.extra === rol.toLocaleLowerCase()
      );

      if (usuario) {
        nUsuarios.unshift(usuario);
      }

      setLabelUsuario(rol);
      setUsuarios(nUsuarios);

      if (nUsuarios.length === 1) {
        form.setFieldsValue({ usuario: nUsuarios[0].value });
      }

      setUsuarios(data.usuarios);
    }
  };

  const onChangeEstado = async (value) => {
    if (estado === "2" && value === "1") {
      form.setFieldsValue({ tipo: null, area: null });
      await onChangeTipo(null);
      await updateUsuarios(null, value);
    }

    if (parseInt(estado) < 4 && parseInt(value) < 4) {
      return;
    }

    setEstado(value);
    form.setFieldsValue({ usuario: null });
    await updateUsuarios(form.getFieldValue("area"), value);
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    if (marcador) {
      values.latitud = marcador.lat;
      values.longitud = marcador.lng;
    }

    const response =
      state.id === 0
        ? await http.post("reclamos", values)
        : await http.put(`reclamos/${state.id}`, values);

    if (response && response.data.code === 0) {
      const id = response.data.id;

      if (state.id === 0) {
        Modal.confirm({
          title: "Continuar editando",
          icon: <ExclamationCircleOutlined />,
          content: "¿Desea adjuntar archivos al reclamo?",
          okText: "Confirmar",
          cancelText: "Cancelar",
          onOk: () => {
            setState({ ...state, id: id, title: "Editar" });
            history.push(`/reclamos/${id}`);
          },
          onCancel: () => history.push("/reclamos"),
        });
      } else {
        showSuccess("El reclamo fue actualizado exitosamente.");
        history.push("/reclamos");
      }
    } else {
      setState({ ...state, loading: false });
    }
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <ContextProvider value={[state, setState]}>
      <div className="reclamo">
        <Header title={`${state.title} Reclamo`} breadcrumb={breadcrumb} />
        <Form form={form} layout="vertical" onFinish={onSave}>
          <div className="form card">
            <div className="form-icon">
              <FiMessageSquare />
            </div>
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item label="Número" name="numero">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Fecha" name="fecha" rules={[required]}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder=""
                    locale={locale}
                    disabled={true}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Quien reclama"
                  name="nombre"
                  rules={[required]}
                >
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Documento"
                  name="documento"
                  rules={[required, regex(regexDocumento)]}
                >
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Telefono"
                  name="telefono"
                  rules={[required, regex(regexTelefono)]}
                >
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Correo Electrónico"
                  name="email"
                  rules={[regex(regexEmail)]}
                >
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Detalle" name="detalle" rules={[required]}>
                  <TextArea
                    maxLength={2000}
                    rows={7}
                    showCount
                    disabled={!GUARDAR}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Direccion"
                  name="direccion"
                  rules={[required]}
                >
                  <Input disabled={!GUARDAR} />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item label="Distrito" name="distrito" rules={[required]}>
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    disabled={!GUARDAR}
                  >
                    {distritos.map((x) => (
                      <Option key={x.value} value={x.value}>
                        {x.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={2}>
                <Tooltip placement="top" title="Ubicar en el mapa">
                  <Button
                    type="primary"
                    shape="circle"
                    className="circle-inline"
                    icon={<FiMapPin />}
                    onClick={onSearch}
                  />
                </Tooltip>
              </Col>

              <Col span={24}>
                <Map
                  center={center}
                  zoom={zoom}
                  marcador={marcador}
                  onUpdate={setMarcador}
                  editable={GUARDAR}
                />
                <br />
              </Col>

              <Col span={12}>
                <Form.Item label="Tipo" name="tipo" rules={[required]}>
                  <Select
                    disabled={!GUARDAR}
                    onChange={onChangeTipo}
                    showSearch
                    optionFilterProp="children"
                  >
                    {tipos.map((x) => (
                      <Option key={x.value} value={x.value}>
                        {x.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Area" name="area" rules={[required]}>
                  <Select
                    disabled={!GUARDAR}
                    onChange={onChangeArea}
                    showSearch
                    optionFilterProp="children"
                  >
                    {areas.map((x) => (
                      <Option key={x.value} value={x.value}>
                        {x.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Creado por" name="creador">
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={labelUsuario} name="usuario">
                  <Select
                    disabled={!GUARDAR}
                    showSearch
                    optionFilterProp="children"
                  >
                    {usuarios.map((x) => (
                      <Option key={x.value} value={x.value}>
                        {x.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Estado" name="estado" rules={[required]}>
                  <Select disabled={!GUARDAR} onChange={onChangeEstado}>
                    {estados.map((x) => (
                      <Option key={x.value} value={x.value}>
                        {x.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Prioridad"
                  name="prioridad"
                  rules={[required]}
                >
                  <Select disabled={!GUARDAR}>
                    {prioridades.map((x) => (
                      <Option key={x.value} value={x.value}>
                        {x.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Informe" name="informe">
                  <TextArea
                    maxLength={2000}
                    rows={7}
                    showCount
                    disabled={!GUARDAR}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div className="form-actions">
              <Link to="/reclamos">
                <Button type="secondary">Volver</Button>
              </Link>
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            </div>
          </div>
        </Form>

        {state.id > 0 && (
          <React.Fragment>
            <Historial list={state.historial} />
            <Archivos />
          </React.Fragment>
        )}
      </div>
    </ContextProvider>
  );
};

export default ReclamosForm;
