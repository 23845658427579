/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Dropdown, Menu, Button, Modal, Tag } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  FiChevronDown,
  FiChevronUp,
  FiEdit,
  FiMoreVertical,
  FiPackage,
  FiTrash2,
} from "react-icons/fi";
import { SlideDown } from "react-slidedown";

import { Context } from "../../services/context";
import http from "../../services/http";
import { showSuccess } from "../../services/notification";
import { hasAction, actions } from "../../services/security";

import ProductosForm from "./ProductosForm";

const Productos = () => {
  const AGREGAR = hasAction(actions.EXPEDIENTES_PRODUCTOS_AGREGAR);
  const EDITAR = hasAction(actions.EXPEDIENTES_PRODUCTOS_EDITAR);
  const ELIMINAR = hasAction(actions.EXPEDIENTES_PRODUCTOS_ELIMINAR);

  const [state] = useContext(Context);
  const [productos, setProductos] = useState([]);
  const [reload, setReload] = useState(false);

  const [visible, setVisible] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [current, setCurrent] = useState({});

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="1" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="2" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    async function getList() {
      const response = await http.get(`expedientes/${state.id}/productos`);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setProductos(data);
      }
    }

    getList();
  }, [reload]);

  const onAdd = () => {
    setCurrent({ importe: 0 });
    setFormVisible(true);
  };

  const onEdit = (item) => {
    setCurrent(item);
    setFormVisible(true);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este producto?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const response = await http.delete(`expedientes/productos/${item.id}`);
        if (response && response.data.code === 0) {
          showSuccess("El producto fue eliminado exitosamente.");
          setReload(!reload);
        }
      },
    });
  };

  const onCloseForm = () => {
    setReload(!reload);
    setFormVisible(false);
  };

  return (
    <div className="items form-subsection">
      <div
        className="form-subsection-header"
        onClick={() => setVisible(!visible)}
      >
        <div className="icon">
          <FiPackage />
        </div>
        <div className="content">
          <div className="title">Productos Solicitados</div>
          <div className="subtitle">{productos.length} elementos</div>
        </div>
        <div className="collapse">
          {visible ? <FiChevronUp /> : <FiChevronDown />}
        </div>
      </div>
      <SlideDown closed={!visible}>
        <div className="form-subsection-content">
          <table className="table table-small">
            <thead>
              <tr>
                <th></th>
                <th>Producto</th>
                <th>Solicitados</th>
                <th>Entregados</th>
                <th>Pendientes</th>
              </tr>
            </thead>
            <tbody>
              {productos.length === 0 && (
                <tr>
                  <td className="table-empty" colSpan="7">
                    Sin resultados
                  </td>
                </tr>
              )}

              {productos.map((x) => (
                <tr key={x.id}>
                  <td className="table-actions">
                    <Dropdown
                      key={x.id}
                      overlay={() => menu(x)}
                      trigger={["click"]}
                      placement="bottomLeft"
                      arrow
                    >
                      <div>
                        <FiMoreVertical />
                      </div>
                    </Dropdown>
                  </td>
                  <td>{x.productoTexto}</td>
                  <td>{x.cantidadSolicitada}</td>
                  <td>
                    <Tag
                      color={
                        x.cantidadRecibida < x.cantidadSolicitada
                          ? "red"
                          : "green"
                      }
                    >
                      {x.cantidadRecibida}
                    </Tag>
                  </td>
                  <td>
                    <Tag color={x.cantidadPendiente > 0 ? "red" : "green"}>
                      {x.cantidadPendiente}
                    </Tag>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {AGREGAR && (
            <div className="actions">
              <Button type="primary" onClick={onAdd}>
                Agregar
              </Button>
            </div>
          )}
        </div>
      </SlideDown>

      <Modal
        visible={formVisible}
        onCancel={() => setFormVisible(false)}
        footer={null}
        maskClosable={false}
        keyboard={false}
        destroyOnClose={true}
      >
        <ProductosForm
          item={current}
          productos={productos}
          onClose={onCloseForm}
        />
      </Modal>
    </div>
  );
};

export default Productos;
