/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Select, Button, Row, Col } from "antd";
import { FiLogIn } from "react-icons/fi";

import { Context } from "./../../services/context";
import http from "./../../services/http";
import { required } from "./../../services/validation";

import "./EntradasForm.scss";

const { Option } = Select;
const { TextArea } = Input;

const EntradasForm = ({ item, onClose }) => {
  const [form] = Form.useForm();

  const [state, setState] = useContext(Context);
  const [loading, setLoading] = useState(false);

  const [depositos, setDepositos] = useState([]);
  const [productos, setProductos] = useState([]);
  const [proveedores, setProveedores] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await http.get(
        `expedientes/entradas/data?expediente=${state.id}&direccion=${state.direccion}`
      );

      if (response && response.data.code === 0) {
        const data = response.data.data;
        setDepositos(data.depositos);
        setProductos(data.productos);
        setProveedores(data.proveedores);
      }

      form.setFieldsValue(item);
    }

    getData();
  }, []);

  const onSave = async (values) => {
    setLoading(true);

    values.id = item.id;
    values.expediente = state.id;

    const response =
      item.id === undefined
        ? await http.post("expedientes/entradas", values)
        : await http.put(`expedientes/entradas/${item.id}`, values);

    if (response && response.data.code === 0) {
      form.resetFields();
      setState({ ...state, reload: !state.reload });
      setLoading(false);
      onClose();
    } else {
      setLoading(false);
    }
  };

  return (
    <Form form={form} name="form-entradas" layout="vertical" onFinish={onSave}>
      <div className="form">
        <div className="form-icon">
          <FiLogIn />
        </div>

        <Row gutter={[16, 5]}>
          <Col span={12}>
            <Form.Item label="Fecha" name="fecha">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Hora" name="hora">
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Proveedor" name="proveedor" rules={[required]}>
          <Select showSearch optionFilterProp="children">
            {proveedores
              .filter((x) => state.proveedores.indexOf(x.value) > -1)
              .map((x) => (
                <Option key={x.value} value={x.value}>
                  {x.text}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item label="Depósito" name="deposito" rules={[required]}>
          <Select allowClear showSearch optionFilterProp="children">
            {depositos.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Producto" name="producto" rules={[required]}>
          <Select allowClear showSearch optionFilterProp="children">
            {productos.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Cantidad" name="cantidad" rules={[required]}>
          <Input type="number" step="any" onWheel={(e) => e.target.blur()} />
        </Form.Item>

        <Form.Item label="Marca" name="marca">
          <Input />
        </Form.Item>

        <Form.Item label="Modelo" name="modelo">
          <Input />
        </Form.Item>

        <Form.Item label="Nº Remito" name="remito">
          <Input />
        </Form.Item>

        <Form.Item
          label="Observaciones"
          name="observaciones"
          className="textarea"
        >
          <TextArea showCount maxLength={500} />
        </Form.Item>

        <div className="form-actions">
          <Button type="secondary" onClick={onClose}>
            Volver
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Guardar
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default EntradasForm;
