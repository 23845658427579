/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";

import { Context } from "../../../services/context";
import http from "../../../services/http";

import Map from "../../../components/Map";

import "./Ubicacion.scss";

const Ubicacion = () => {
  const [state, setState] = useContext(Context);

  const [center, setCenter] = useState({
    lat: -32.8983746,
    lng: -68.7958638,
  });
  const [zoom, setZoom] = useState(13);

  const [marcadores, setMarcadores] = useState([]);

  useEffect(() => {
    async function getData() {
      const filters = state.getFilters();
      const response = await http.get("tableros/reclamos/ubicacion" + filters);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setMarcadores(data.reclamos);
      }
    }

    getData();
  }, [state.reload]);

  const renderInfoWindow = (item) => {
    return (
      <div className="infowindow">
        <Link
          to={`/reclamos/${item.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="title">Reclamo #{item.id}</div>
        </Link>
        <div className="row">
          <div className="name">Fecha:</div>
          <div className="value">{item.fecha}</div>
        </div>
        <div className="row">
          <div className="name">Tipo:</div>
          <div className="value">{item.tipo}</div>
        </div>
        <div className="row">
          <div className="name">Estado:</div>
          <div className="value">{item.estado}</div>
        </div>
        <div className="row">
          <div className="name">Area:</div>
          <div className="value">{item.area}</div>
        </div>
        <div className="row">
          <div className="name">Responsable:</div>
          <div className="value">{item.responsable}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="chart card">
      <div className="header">
        <div className="title">Ubicación</div>
      </div>

      <Map
        center={center}
        zoom={zoom}
        marcadores={marcadores}
        editable={false}
        renderInfoWindow={renderInfoWindow}
        onUpdateMarcadores={(marcadores) => setMarcadores(marcadores)}
      />

      <div className="references">
        <Row justify="center" gutter={[0, 10]}>
          {(state.filters.estado === "" || state.filters.estado === "1") && (
            <Col xs={6} md={4}>
              <div className="estado">
                <div className="color precarga" />
                Precarga
              </div>
            </Col>
          )}

          {(state.filters.estado === "" || state.filters.estado === "2") && (
            <Col xs={6} md={4}>
              <div className="estado">
                <div className="color pendiente" />
                Pendiente
              </div>
            </Col>
          )}

          {(state.filters.estado === "" || state.filters.estado === "3") && (
            <Col xs={6} md={4}>
              <div className="estado">
                <div className="color encurso" />
                En Curso
              </div>
            </Col>
          )}

          {(state.filters.estado === "" || state.filters.estado === "4") && (
            <Col xs={6} md={4}>
              <div className="estado">
                <div className="color terminado" />
                Terminado
              </div>
            </Col>
          )}

          {(state.filters.estado === "" || state.filters.estado === "5") && (
            <Col xs={6} md={4}>
              <div className="estado">
                <div className="color rechazado" />
                Rechazado
              </div>
            </Col>
          )}

          {(state.filters.estado === "" || state.filters.estado === "6") && (
            <Col xs={6} md={4}>
              <div className="estado">
                <div className="color supervisado" />
                Supervisado
              </div>
            </Col>
          )}

          {(state.filters.estado === "" || state.filters.estado === "7") && (
            <Col xs={6} md={4}>
              <div className="estado">
                <div className="color solucionado" />
                Solucionado
              </div>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default Ubicacion;
