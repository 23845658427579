/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Select, Button } from "antd";
import { FiPercent } from "react-icons/fi";

import { Context } from "./../../../services/context";
import http from "./../../../services/http";
import { required } from "./../../../services/validation";

const { Option } = Select;

const EquivalenciasForm = ({ item, onClose }) => {
  const [form] = Form.useForm();

  const [state, setState] = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [unidades, setUnidades] = useState([]);

  const onSave = async (values) => {
    setLoading(true);

    values.origenId = state.id;

    const rSave =
      item.id === undefined
        ? await http.post("unidades/equivalencias", values)
        : await http.put(`unidades/equivalencias/${item.id}`, values);

    if (rSave && rSave.data.code === 0) {
      form.resetFields();
      setState({ ...state, reload: !state.reload });
      setLoading(false);
      onClose();
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function getData() {
      const rData = await http.get(
        `unidades/equivalencias/data?id=${state.id}`
      );

      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setUnidades(data.unidades);
      }

      form.setFieldsValue(item);
    }

    getData();
  }, []);

  return (
    <Form form={form} layout="vertical" onFinish={onSave}>
      <div className="form">
        <div className="form-icon">
          <FiPercent />
        </div>

        <Form.Item label="Unidad de Medida" name="destinoId" rules={[required]}>
          <Select allowClear showSearch optionFilterProp="children">
            {unidades.map((x) => (
              <Option key={x.id} value={x.id}>
                {x.nombre}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Multiplicador"
          name="multiplicador"
          rules={[required]}
        >
          <Input type="number" step="any" onWheel={(e) => e.target.blur()} />
        </Form.Item>

        <div className="form-actions">
          <Button type="secondary" onClick={onClose}>
            Volver
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Guardar
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default EquivalenciasForm;
