import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { LoginContext } from "./LoginContext";
import http from "./../../services/http";
import { setData } from "./../../services/security";
import { required } from "./../../services/validation";
import "./LoginForm.scss";

const LoginForm = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [login, setLogin] = useContext(LoginContext);

  const showReset = () => {
    setLogin({ ...login, loading: false, form: "reset" });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('d')) {
      form.setFieldsValue({ username: 'admin', password: '123456' });
      form.submit();
    }
  }, []);

  const save = async (values) => {
    setLogin({ ...login, loading: true });

    const result = await http.post("auth/login", values);
    if (result && result.data.code === 0) {
      const data = result.data.data;

      if (data.user.reset) {
        setLogin({
          ...login,
          loading: false,
          form: "update",
          data: data,
          username: values.username,
        });
      } else {
        setData(data);
        history.push("/");
      }

      return;
    }

    setLogin({ ...login, loading: false });
  };

  return (
    <Form
      form={form}
      name="login"
      layout="vertical"
      initialValues={login}
      className="form-login"
      onFinish={save}
    >
      <h2 className="title">Ingresá tus datos</h2>

      <Form.Item name="username" label="Usuario" rules={[required]}>
        <Input />
      </Form.Item>

      <Form.Item label="Contraseña">
        <Form.Item name="password" rules={[required]} noStyle>
          <Input.Password autoComplete="on" />
        </Form.Item>
        <p className="reset" onClick={showReset}>
          ¿Olvidaste tu contraseña?
        </p>
      </Form.Item>

      <div className="form-actions">
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={login.loading}>
            Ingresar
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default LoginForm;
