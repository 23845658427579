/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import { Scrollbars } from "react-custom-scrollbars";

import { validateToken } from "./../services/security";

import NavBar from "./../components/NavBar";
import SideBar from "./../components/SideBar";

import Inicio from "./Inicio/Inicio";
import ExpedientesForm from "./Expedientes/ExpedientesForm";
import Expedientes from "./Expedientes/Expedientes";
import Inventario from "./Inventario/Inventario";
import InventarioDeposito from "./Inventario/Deposito";
import EntradasForm from "./Entradas/EntradasForm";
import Salidas from "./Salidas/Salidas";
import SalidasForm from "./Salidas/SalidasForm";
import Devoluciones from "./Devoluciones/Devoluciones";
import DevolucionesForm from "./Devoluciones/DevolucionesForm";
import PuntoPedido from "./PuntoPedido/PuntoPedido";
import TableroHacienda from "./Tableros/Hacienda/Tablero";
import TableroReclamos from "./Tableros/Reclamos/Tablero";
import Catastro from "./Notificaciones/Catastro/Catastro";
import CatastroForm from "./Notificaciones/Catastro/CatastroForm";
import AuditoriaForm from "./Seguridad/Auditoria/AuditoriaForm";
import Areas from "./Configuracion/Areas/Areas";
import AreasForm from "./Configuracion/Areas/AreasForm";
import TiposReclamosForm from "./Configuracion/TiposReclamos/TiposReclamosForm";
import TiposReclamos from "./Configuracion/TiposReclamos/TiposReclamos";
import ProductosForm from "./Configuracion/Productos/ProductosForm";
import Productos from "./Configuracion/Productos/Productos";
import DepositosForm from "./Configuracion/Depositos/DepositosForm";
import Depositos from "./Configuracion/Depositos/Depositos";
import DireccionesForm from "./Configuracion/Direcciones/DireccionesForm";
import Direcciones from "./Configuracion/Direcciones/Direcciones";
import PuntosReclamoForm from "./Configuracion/PuntosReclamo/PuntosReclamoForm";
import PuntosReclamo from "./Configuracion/PuntosReclamo/PuntosReclamo";
import ProveedoresForm from "./Configuracion/Proveedores/ProveedoresForm";
import Proveedores from "./Configuracion/Proveedores/Proveedores";
import Parametros from "./Configuracion/Parametros/Parametros";
import ParametrosForm from "./Configuracion/Parametros/ParametrosForm";
import ReclamosForm from "./Reclamos/ReclamosForm";
import Reclamos from "./Reclamos/Reclamos";
import CategoriasForm from "./Configuracion/Categorias/CategoriasForm";
import Categorias from "./Configuracion/Categorias/Categorias";
import Unidades from "./Configuracion/Unidades/Unidades";
import UnidadesForm from "./Configuracion/Unidades/UnidadesForm";
import Perfiles from "./Seguridad/Perfiles/Perfiles";
import PerfilesForm from "./Seguridad/Perfiles/PerfilesForm";
import Usuarios from "./Seguridad/Usuarios/Usuarios";
import UsuariosForm from "./Seguridad/Usuarios/UsuariosForm";
import Auditoria from "./Seguridad/Auditoria/Auditoria";
import NotFound from "./NotFound";

import "react-slidedown/lib/slidedown.css";

import "./Master.scss";

const Master = () => {
  const history = useHistory();
  const minWidth = 992;
  const [visible, setVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(window.innerWidth < minWidth);
  const refScroll = useRef(null);

  useEffect(() => {
    if (refScroll.current) {
      refScroll.current.scrollToTop();
    }
  }, [history.location.pathname]);

  useEffect(() => {
    function windowResize() {
      setCollapsed(window.innerWidth < minWidth);
    }

    async function validate() {
      const validToken = await validateToken();

      if (!validToken) {
        localStorage.clear();
        history.push("/login");
      } else {
        setVisible(true);
      }
    }

    window.addEventListener("resize", windowResize);
    validate();
  }, []);

  if (!visible) {
    return <div></div>;
  }

  return (
    <div className="master">
      <SideBar collapsed={collapsed} onCollapse={setCollapsed} />
      <Scrollbars
        className="master-scroll"
        style={{ width: "100%", height: "100vh" }}
        autoHide
        ref={refScroll}
      >
        <main className="content">
          <NavBar collapsed={collapsed} onCollapse={setCollapsed} />
          <AnimatedSwitch
            atEnter={{ opacity: 0 }}
            atLeave={{ opacity: 0 }}
            atActive={{ opacity: 1 }}
            className="switch-wrapper"
          >
            <Route path="/expedientes/:id" component={ExpedientesForm} />
            <Route path="/expedientes" component={Expedientes} />

            <Route
              path="/inventario/depositos/:id"
              component={InventarioDeposito}
            />
            <Route path="/inventario" component={Inventario} />
            <Route path="/entradas/:id" component={EntradasForm} />
            <Route path="/salidas/:id" component={SalidasForm} />
            <Route path="/salidas" component={Salidas} />
            <Route path="/devoluciones/:id" component={DevolucionesForm} />
            <Route path="/devoluciones" component={Devoluciones} />

            <Route path="/reclamos/:id" component={ReclamosForm} />
            <Route path="/reclamos" component={Reclamos} />

            <Route path="/punto-pedido" component={PuntoPedido} />

            {/* Tableros */}
            <Route path="/tableros/hacienda" component={TableroHacienda} />
            <Route path="/tableros/reclamos" component={TableroReclamos} />

            {/* Notificaciones */}
            <Route
              path="/notificaciones/catastro/:id"
              component={CatastroForm}
            />
            <Route path="/notificaciones/catastro" component={Catastro} />

            {/* Configuracion */}
            <Route path="/configuracion/areas/:id" component={AreasForm} />
            <Route path="/configuracion/areas" component={Areas} />
            <Route
              path="/configuracion/direcciones/:id"
              component={DireccionesForm}
            />
            <Route path="/configuracion/direcciones" component={Direcciones} />
            <Route
              path="/configuracion/categorias/:id"
              component={CategoriasForm}
            />
            <Route path="/configuracion/categorias" component={Categorias} />
            <Route
              path="/configuracion/productos/:id"
              component={ProductosForm}
            />
            <Route path="/configuracion/productos" component={Productos} />
            <Route
              path="/configuracion/parametros/:id"
              component={ParametrosForm}
            />
            <Route path="/configuracion/parametros" component={Parametros} />
            <Route
              path="/configuracion/proveedores/:id"
              component={ProveedoresForm}
            />
            <Route path="/configuracion/proveedores" component={Proveedores} />
            <Route
              path="/configuracion/depositos/:id"
              component={DepositosForm}
            />
            <Route path="/configuracion/depositos" component={Depositos} />
            <Route
              path="/configuracion/unidades/:id"
              component={UnidadesForm}
            />
            <Route path="/configuracion/unidades" component={Unidades} />
            <Route
              path="/configuracion/puntosreclamo/:id"
              component={PuntosReclamoForm}
            />
            <Route
              path="/configuracion/puntosreclamo"
              component={PuntosReclamo}
            />
            <Route
              path="/configuracion/tiposreclamo/:id"
              component={TiposReclamosForm}
            />
            <Route
              path="/configuracion/tiposreclamo"
              component={TiposReclamos}
            />

            {/* Seguridad */}
            <Route path="/seguridad/auditoria/:id" component={AuditoriaForm} />
            <Route path="/seguridad/auditoria" component={Auditoria} />
            <Route path="/seguridad/perfiles/:id" component={PerfilesForm} />
            <Route path="/seguridad/perfiles" component={Perfiles} />
            <Route path="/seguridad/usuarios/:id" component={UsuariosForm} />
            <Route path="/seguridad/usuarios" component={Usuarios} />

            <Route path="/not-found" component={NotFound} />
            <Route path="/" exact component={Inicio} />
            <Redirect to="/not-found" />
          </AnimatedSwitch>
        </main>
      </Scrollbars>
    </div>
  );
};

export default Master;
