import React from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";

import marker1 from "./../assets/images/marker1.png";
import marker2 from "./../assets/images/marker2.png";
import marker3 from "./../assets/images/marker3.png";
import marker4 from "./../assets/images/marker4.png";
import marker5 from "./../assets/images/marker5.png";
import marker6 from "./../assets/images/marker6.png";
import marker7 from "./../assets/images/marker7.png";

const Map = (props) => {
  const {
    center,
    zoom,
    editable,
    marcador,
    marcadores,
    renderInfoWindow,
    onUpdate,
    onUpdateMarcadores,
  } = props;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  });

  const getMarkerIcon = (item) => {
    switch (item.icon) {
      case 1:
        return marker1;
      case 2:
        return marker2;
      case 3:
        return marker3;
      case 4:
        return marker4;
      case 5:
        return marker5;
      case 6:
        return marker6;
      case 7:
        return marker7;
      default:
        return marker1;
    }
  };

  const onMapClick = (e) => {
    if (!editable) {
      return;
    }

    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    onUpdate({ lat, lng });
  };

  const onMarkerClick = (index) => {
    let nMarcadores = [...marcadores];
    nMarcadores = nMarcadores.map((marcador) => {
      marcador = { ...marcador, infowindow: false };
      return marcador;
    });
    nMarcadores[index].infowindow = true;
    onUpdateMarcadores(nMarcadores);
  };

  const onCloseInfoWindow = (index) => {
    let nMarcadores = [...marcadores];
    nMarcadores[index].infowindow = false;
    onUpdateMarcadores(nMarcadores);
  };

  const onMarkerDrag = (e) => {
    if (!editable) {
      return;
    }

    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    onUpdate({ lat, lng });
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        width: "100%",
        height: "600px",
      }}
      center={center}
      zoom={zoom}
      options={{
        gestureHandling: "cooperative",
        styles: [
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "off" }],
          },
        ],
      }}
      onClick={onMapClick}
    >
      {marcador && (
        <Marker
          draggable={editable}
          icon={{
            url: marker1,
            scaledSize: new window.google.maps.Size(30, 30),
          }}
          position={marcador}
          onDragEnd={onMarkerDrag}
        />
      )}

      {marcadores &&
        marcadores.map((item, index) => (
          <React.Fragment key={index}>
            <Marker
              icon={{
                url: getMarkerIcon(item),
                scaledSize: new window.google.maps.Size(30, 30),
              }}
              position={item}
              onClick={() => onMarkerClick(index)}
            />

            {item.infowindow && (
              <InfoWindow
                position={item}
                onCloseClick={() => onCloseInfoWindow(index)}
              >
                {renderInfoWindow(item)}
              </InfoWindow>
            )}
          </React.Fragment>
        ))}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default React.memo(Map);
