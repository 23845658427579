import React, { useContext, useState } from "react";
import { Dropdown, Menu, Button, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  FiMessageSquare,
  FiEdit,
  FiDelete,
  FiChevronDown,
  FiChevronUp,
  FiMoreVertical,
} from "react-icons/fi";
import { SlideDown } from "react-slidedown";

import { Context } from "./../../../services/context";
import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notification";
import { hasAction, actions } from "./../../../services/security";

import ComentariosForm from "./ComentariosForm";

const Comentarios = () => {
  const EDITAR = hasAction(actions.CATASTRO_EDITAR);

  const [state, setState] = useContext(Context);
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState(null);

  const onAdd = () => {
    setCurrent({});
  };

  const onEdit = (item) => {
    setCurrent(item);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este comentario?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(
          `notificaciones/catastro/comentarios/${item.id}`
        );
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("El comentario fue eliminado exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  const onClose = () => {
    setCurrent(null);
  };

  const menu = (file) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="1" onClick={() => onEdit(file)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {EDITAR && (
          <Menu.Item key="2" onClick={() => onDelete(file)}>
            <FiDelete />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  return (
    <div className="comentarios form-subsection">
      <div
        className="form-subsection-header"
        onClick={() => setVisible(!visible)}
      >
        <div className="icon">
          <FiMessageSquare />
        </div>
        <div className="content">
          <div className="title">Comentarios</div>
          <div className="subtitle">{state.comentarios.length} elementos</div>
        </div>
        <div className="collapse">
          {visible ? <FiChevronUp /> : <FiChevronDown />}
        </div>
      </div>
      <SlideDown closed={!visible}>
        <div className="form-subsection-content">
          <div className="comentarios">
            {state.comentarios.map((x) => (
              <div key={x.id} className="comentario-item">
                <div className="comentario-actions">
                  {x.editable && (
                    <Dropdown
                      key={x.id}
                      overlay={() => menu(x)}
                      trigger={["click"]}
                      placement="bottomLeft"
                      arrow
                    >
                      <div>
                        <FiMoreVertical />
                      </div>
                    </Dropdown>
                  )}
                </div>
                <div className="comentario-body">
                  <div className="comentario-title">
                    <div className="comentario-user">{x.usuario}</div>
                    <div className="comentario-date">
                      {x.fecha} a las {x.hora}
                    </div>
                  </div>
                  <div className="comentario-content">{x.contenido}</div>
                </div>
              </div>
            ))}
          </div>

          {EDITAR && (
            <React.Fragment>
              <ComentariosForm item={current} onClose={onClose} />
              <div className="actions">
                <Button type="primary" onClick={onAdd}>
                  Agregar
                </Button>
              </div>
            </React.Fragment>
          )}
        </div>
      </SlideDown>
    </div>
  );
};

export default Comentarios;
