/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';
import { FiLogIn } from 'react-icons/fi';

import { Context } from './../../../services/context';
import http from './../../../services/http';
import { showSuccess } from './../../../services/notification';
import { required } from './../../../services/validation';

const UnificarModal = (props) => {
  const [form] = Form.useForm();

  const [state, setState] = useContext(Context);
  const { visible, onClose } = props;
  const [loading, setLoading] = useState(false);

  const [productos, setProductos] = useState([]);

  useEffect(() => {
    async function getData() {
      if (!visible) {
        return;
      }

      const response = await http.get('productos/merge/data');
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setProductos(
          data.productos.filter((x) => x.value !== visible.id.toString())
        );

        form.setFieldsValue({
          origen: `${visible.codigo} - ${visible.nombre}`,
          destino: undefined,
        });
      }
    }

    getData();
  }, [visible]);

  const onSave = async (values) => {
    setLoading(true);

    const url = `productos/${visible.id}/merge`;
    const data = {
      destino: values.destino,
    };

    const response = await http.put(url, data);
    setLoading(false);

    if (response && response.data.code === 0) {
      showSuccess('El producto fue unificado exitosamente.');
      form.resetFields();
      setState({ ...state, reload: !state.reload });
      onClose();
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      maskClosable={false}
      keyboard={false}
      destroyOnClose={true}
    >
      <Form
        form={form}
        name="form-unificar"
        layout="vertical"
        onFinish={onSave}
      >
        <div className="form">
          <div className="form-icon">
            <FiLogIn />
          </div>

          <Form.Item label="Producto Original" name="origen">
            <Input disabled={true} />
          </Form.Item>

          <Form.Item label="Producto Destino" name="destino" rules={[required]}>
            <Select allowClear showSearch optionFilterProp="children">
              {productos.map((x) => (
                <Select.Option key={x.value} value={x.value}>
                  {x.text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div className="form-actions">
            <Button type="secondary" onClick={onClose}>
              Volver
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              {!loading && 'Guardar'}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default UnificarModal;
