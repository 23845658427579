/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import * as moment from 'moment';
import 'moment/locale/es';

import http from '../../../services/http';
import { hasAction, actions } from '../../../services/security';

import { ContextProvider } from '../../../services/context';
import Header from '../../../components/Header';
import Filters from './../../../components/Filters';
import FiltersButton from './../../../components/FiltersButton';

import Area from './Area';
import Estado from './Estado';
import Prioridad from './Prioridad';
import Tiempo from './Tiempo';
import Tipo from './Tipo';
import Ubicacion from './Ubicacion';
import Cantidad from './Cantidad';

const Tablero = () => {
  const VER = hasAction(actions.TABLEROS_RECLAMOS_VER);

  const [state, setState] = useState({
    reload: false,
    filters: {
      numero: '',
      desde: moment().startOf('month').format('DD/MM/YYYY'),
      hasta: moment().endOf('month').format('DD/MM/YYYY'),
      documento: '',
      tipo: '',
      area: '',
      prioridad: '',
      estado: '',
      responsable: '',
      direccion: '',
      distrito: '',
      creadoPor: '',
    },
    getFilters: () => {
      return (
        '?numero=' +
        (state.filters.numero ?? '') +
        '&desde=' +
        (state.filters.desde ?? '') +
        '&hasta=' +
        (state.filters.hasta ?? '') +
        '&documento=' +
        (state.filters.documento ?? '') +
        '&tipo=' +
        (state.filters.tipo ?? '') +
        '&area=' +
        (state.filters.area ?? '') +
        '&prioridad=' +
        (state.filters.prioridad ?? '') +
        '&estado=' +
        (state.filters.estado ?? '') +
        '&responsable=' +
        (state.filters.responsable ?? '') +
        '&direccion=' +
        (state.filters.direccion ?? '') +
        '&distrito=' +
        (state.filters.distrito ?? '') +
        '&creadoPor=' +
        (state.filters.creadoPor ?? '')
      );
    },
  });

  const [areas, setAreas] = useState([]);
  const [distritos, setDistritos] = useState([]);
  const [estados, setEstados] = useState([]);
  const [prioridades, setPrioridades] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [usuarios, setUsuarios] = useState([]);

  const breadcrumb = [
    { id: 1, title: 'Tablero' },
    { id: 2, title: 'Reclamos', url: '/tableros/reclamos' },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Número" name="numero">
          <Input />
        </Form.Item>
        <Form.Item label="Desde" name="desde">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>
        <Form.Item label="Hasta" name="hasta">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>
        <Form.Item label="Documento" name="documento">
          <Input />
        </Form.Item>
        <Form.Item label="Tipo" name="tipo">
          <Select allowClear showSearch optionFilterProp="children">
            {tipos.map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Area" name="area">
          <Select allowClear showSearch optionFilterProp="children">
            {areas.map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Direccion" name="direccion">
          <Input />
        </Form.Item>
        <Form.Item label="Distrito" name="distrito">
          <Select allowClear showSearch optionFilterProp="children">
            {distritos.map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Responsable" name="responsable">
          <Select allowClear showSearch optionFilterProp="children">
            {usuarios.map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Prioridad" name="prioridad">
          <Select allowClear showSearch optionFilterProp="children">
            {prioridades.map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Estado" name="estado">
          <Select allowClear showSearch optionFilterProp="children">
            {estados.map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Creado por" name="creadoPor">
          <Select allowClear showSearch optionFilterProp="children">
            {usuarios.map((x, index) => (
              <Select.Option key={index} value={x.text}>
                {x.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get('reclamos/data');
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setAreas(data.areas);
        setDistritos(data.distritos);
        setUsuarios(data.usuarios);
        setEstados(data.estados);
        setPrioridades(data.prioridades);
        setTipos(data.tipos);
      }
    }

    getData();
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="tablero page">
      <ContextProvider value={[state, setState]}>
        <Header title="Reclamos" breadcrumb={breadcrumb} buttons={buttons} />
        <Filters
          filters={filters}
          initial={{
            desde: moment().startOf('month'),
            hasta: moment().endOf('month'),
          }}
        />

        <Row gutter={[32, 36]}>
          <Col xs={24} lg={12}>
            <Cantidad />
          </Col>
          <Col xs={24} lg={12}>
            <Tiempo />
          </Col>

          <Col xs={24} lg={24}>
            <Ubicacion />
          </Col>

          <Col xs={24} lg={12}>
            <Estado />
          </Col>
          <Col xs={24} lg={12}>
            <Prioridad />
          </Col>

          <Col xs={24} lg={24}>
            <Tipo />
          </Col>

          <Col xs={24} lg={24}>
            <Area />
          </Col>
        </Row>
      </ContextProvider>
    </div>
  );
};

export default Tablero;
