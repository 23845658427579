/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Button, Form, Input, Menu, Modal, Popover, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FiEdit, FiPlus, FiSearch, FiShare2, FiTrash2 } from 'react-icons/fi';

import { ContextProvider } from '../../../services/context';
import { downloadBase64 } from '../../../services/files';
import http from '../../../services/http';
import { showSuccess } from '../../../services/notification';
import { hasAction, actions } from '../../../services/security';

import Header from '../../../components/Header';
import Filters from '../../../components/Filters';
import FiltersButton from '../../../components/FiltersButton';
import Table from '../../../components/Table';
import UnificarModal from './UnificarModal';

const Productos = () => {
  const history = useHistory();

  const VER = hasAction(actions.PRODUCTOS_VER);
  const CREAR = hasAction(actions.PRODUCTOS_CREAR);
  const EDITAR = hasAction(actions.PRODUCTOS_EDITAR);
  const ELIMINAR = hasAction(actions.PRODUCTOS_ELIMINAR);
  const UNIFICAR = hasAction(actions.PRODUCTOS_UNIFICAR);

  const [state, setState] = useState({
    filters: {
      visible: false,
      nombre: '',
      codigo: '',
      categoria: '',
      descripcion: '',
    },
    columns: [
      {
        title: 'Nombre',
        style: {},
        render: (item) => item.nombre,
      },
      {
        title: 'Código',
        style: {},
        render: (item) => item.codigo,
      },
      {
        title: 'Categoría',
        style: {},
        render: (item) => item.categoria,
      },
      {
        title: 'Unidad de Medida',
        style: {},
        render: (item) => item.unidad,
      },
      {
        title: 'Descripcion',
        style: {},
        render: (item) => {
          if (item.descripcion.length > 30) {
            return (
              <Popover
                placement="top"
                content={
                  <div style={{ maxWidth: '400px' }}>{item.descripcion}</div>
                }
              >
                {item.descripcion.substring(0, 30)}...
              </Popover>
            );
          }

          return item.descripcion;
        },
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });
  const [categorias, setCategorias] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [currentUnificar, setCurrentUnificar] = useState();

  const breadcrumb = [
    { id: 1, title: 'Configuración' },
    { id: 2, title: 'Productos', url: '/configuracion/productos' },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <Button loading={exportLoading} onClick={onClickExport}>
          {!exportLoading && <React.Fragment>Exportar</React.Fragment>}
        </Button>
        <FiltersButton />
        {CREAR && (
          <Button type="primary" icon={<FiPlus />} onClick={onAdd}>
            Nuevo
          </Button>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Nombre" name="nombre">
          <Input />
        </Form.Item>
        <Form.Item label="Código" name="codigo">
          <Input />
        </Form.Item>
        <Form.Item label="Categoría" name="categoria">
          <Select allowClear>
            {categorias.map((g) => (
              <Select.Option key={g.value} value={g.value}>
                {g.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Descripción" name="descripcion">
          <Input />
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {!EDITAR && (
          <Menu.Item key="1" onClick={() => onEdit(item)}>
            <FiSearch />
            Ver
          </Menu.Item>
        )}
        {EDITAR && (
          <Menu.Item key="2" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {UNIFICAR && (
          <Menu.Item key="3" onClick={() => onMerge(item)}>
            <FiShare2 />
            Unificar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="4" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onClickExport = async () => {
    setExportLoading(true);

    const response = await http.post(`productos/export`, state.filters);
    if (response && response.data.code === 0) {
      const data = response.data.data;
      downloadBase64(data.filename, data.content);
    }

    setExportLoading(false);
  };

  const onAdd = () => {
    history.push('/configuracion/productos/nuevo');
  };

  const onEdit = (item) => {
    history.push(`/configuracion/productos/${item.id}`);
  };

  const onMerge = (item) => {
    setCurrentUnificar(item);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: 'Confirmar eliminación',
      icon: <ExclamationCircleOutlined />,
      content: '¿Esta seguro que desea eliminar este producto?',
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: async () => {
        const response = await http.delete(`productos/${item.id}`);
        if (response && response.data.code === 0) {
          showSuccess('El producto fue eliminado exitosamente.');
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  useEffect(() => {
    async function getData() {
      const rData = await http.get('productos/data');
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setCategorias(data.categorias);
      }
    }

    getData();
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="productos page">
      <ContextProvider value={[state, setState]}>
        <Header title="Productos" breadcrumb={breadcrumb} buttons={buttons} />
        <Filters filters={filters} />
        <Table url="productos/list" menu={menu} />
        <UnificarModal
          visible={currentUnificar}
          onClose={() => setCurrentUnificar()}
        />
      </ContextProvider>
    </div>
  );
};

export default Productos;
