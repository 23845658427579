/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { FiPackage } from "react-icons/fi";

import http from "../../../services/http";
import { showSuccess } from "../../../services/notification";
import { hasAction, actions } from "../../../services/security";
import { required } from "../../../services/validation";

import Header from "../../../components/Header";

const { TextArea } = Input;
const { Option } = Select;

const ProductosForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.PRODUCTOS_VER);
  const CREAR = hasAction(actions.PRODUCTOS_CREAR);
  const EDITAR = hasAction(actions.PRODUCTOS_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });
  const [categorias, setCategorias] = useState([]);
  const [subcategorias, setSubcategorias] = useState([]);
  const [unidades, setUnidades] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Configuración" },
    { id: 2, title: "Productos", url: "/configuracion/productos" },
    { id: 3, title: state.title },
  ];

  const onCategoriaChange = async (value) => {
    if (!value) {
      setSubcategorias([]);
      return;
    }

    const rSubcategorias = await http.get(`productos/subcategorias/${value}`);
    if (rSubcategorias && rSubcategorias.data.code === 0) {
      form.resetFields(["subcategoria"]);
      const data = rSubcategorias.data.data;
      setSubcategorias(data.subcategorias);
    }
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const rSave =
      state.id === 0
        ? await http.post("productos", values)
        : await http.put(`productos/${state.id}`, values);

    if (rSave && rSave.data.code === 0) {
      history.push("/configuracion/productos");
      showSuccess("El producto fue guardado exitosamente.");
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      const rData = await http.get("productos/data");
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setCategorias(data.categorias);
        setUnidades(data.unidades);
      }

      const rForm = await http.get(`productos/${state.id}`);
      if (rForm.data.code === 0) {
        const data = rForm.data.data;
        setSubcategorias(data.subcategorias);
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="producto">
      <Header title={`${state.title} Producto`} breadcrumb={breadcrumb} />

      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form card">
          <div className="form-icon">
            <FiPackage />
          </div>

          <Form.Item label="Nombre" name="nombre" rules={[required]}>
            <Input maxLength={250} disabled={!GUARDAR} />
          </Form.Item>

          <Form.Item label="Código" name="codigo">
            <Input maxLength={20} disabled={!GUARDAR} />
          </Form.Item>

          <Row gutter={20}>
            <Col span={12}>
              <Form.Item label="Categoría" name="categoria" rules={[required]}>
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  disabled={!GUARDAR}
                  onChange={onCategoriaChange}
                >
                  {categorias.map((g) => (
                    <Option key={g.value} value={g.value}>
                      {g.text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Subcategoría" name="subcategoria">
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  disabled={!GUARDAR}
                >
                  {subcategorias.map((subcategoria, index) => (
                    <Option key={index} value={subcategoria}>
                      {subcategoria}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Descripción"
            name="descripcion"
            className="textarea"
          >
            <TextArea showCount maxLength={1000} disabled={!GUARDAR} />
          </Form.Item>

          <Form.Item label="Unidad de Medida" name="unidad" rules={[required]}>
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              disabled={!GUARDAR}
            >
              {unidades.map((x) => (
                <Option key={x.value} value={x.value}>
                  {x.text}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Punto de Pedido" name="puntoPedido">
            <Input
              type="number"
              step="any"
              disabled={!GUARDAR}
              onWheel={(e) => e.target.blur()}
            />
          </Form.Item>

          <div className="form-actions">
            <Link to="/configuracion/productos">
              <Button type="secondary">Volver</Button>
            </Link>
            {GUARDAR && (
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ProductosForm;
