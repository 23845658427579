/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Chart from "react-apexcharts";

import { Context } from "../../../services/context";
import http from "../../../services/http";

import empty from "../../../assets/images/empty.svg";

const Estado = () => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);

  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (val) {
          return parseInt(val);
        },
      },
    },
    xaxis: {
      categories: [],
    },
  });
  const [series, setSeries] = useState([]);

  useEffect(() => {
    async function getData() {
      const filters = state.getFilters();
      const response = await http.get("tableros/reclamos/estado" + filters);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setOptions({ ...options, xaxis: { categories: data.categories } });
        setSeries(data.series);
      }
    }

    getData();
  }, [state.reload]);

  return (
    <div className="chart card">
      <div className="header">
        <div className="title">Estado</div>
      </div>

      {options.xaxis.categories.length === 0 && (
        <div className="empty">
          <img src={empty} alt="empty" />
          Sin resultados
        </div>
      )}

      {options.xaxis.categories.length > 0 && (
        <Chart
          className="canvas"
          options={options}
          series={series}
          type="bar"
          height={300}
          width="100%"
        />
      )}
    </div>
  );
};

export default Estado;
