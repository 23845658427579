/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, Menu, Button, Modal } from 'antd';
import { SlideDown } from 'react-slidedown';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  FiChevronDown,
  FiChevronUp,
  FiEdit,
  FiLogIn,
  FiMoreVertical,
  FiTrash2,
} from 'react-icons/fi';
import * as moment from 'moment';

import { Context } from './../../services/context';
import http from './../../services/http';
import { showSuccess } from './../../services/notification';
import { hasAction, actions } from './../../services/security';

import EntradasForm from './EntradasForm';

const Entradas = () => {
  const AGREGAR = hasAction(actions.EXPEDIENTES_ENTRADAS_AGREGAR);
  const EDITAR = hasAction(actions.EXPEDIENTES_ENTRADAS_EDITAR);
  const ELIMINAR = hasAction(actions.EXPEDIENTES_ENTRADAS_ELIMINAR);

  const [state] = useContext(Context);
  const [entradas, setEntradas] = useState([]);
  const [reload, setReload] = useState(false);

  const [visible, setVisible] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [current, setCurrent] = useState({});

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="1" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="2" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    async function getList() {
      const response = await http.get(`expedientes/${state.id}/entradas`);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEntradas(data);
      }
    }

    getList();
  }, [reload]);

  const onAdd = () => {
    setCurrent({
      fecha: moment(new Date()).format('DD/MM/YYYY'),
      hora: moment(new Date()).format('HH:mm'),
      proveedor:
        state.proveedores.length === 1 ? state.proveedores[0] : undefined,
    });
    setFormVisible(true);
  };

  const onEdit = (item) => {
    setCurrent(item);
    setFormVisible(true);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: 'Confirmar eliminación',
      icon: <ExclamationCircleOutlined />,
      content: '¿Esta seguro que desea eliminar esta entrada?',
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: async () => {
        const rDelete = await http.delete(`expedientes/entradas/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess('La entrada fue eliminada exitosamente.');
          setReload(!reload);
        }
      },
    });
  };

  const onCloseForm = () => {
    setReload(!reload);
    setFormVisible(false);
  };

  return (
    <div className="entradas form-subsection">
      <div
        className="form-subsection-header"
        onClick={() => setVisible(!visible)}
      >
        <div className="icon">
          <FiLogIn />
        </div>
        <div className="content">
          <div className="title">Productos Entregados</div>
          <div className="subtitle">{entradas.length} elementos</div>
        </div>
        <div className="collapse">
          {visible ? <FiChevronUp /> : <FiChevronDown />}
        </div>
      </div>

      <SlideDown closed={!visible}>
        <div className="form-subsection-content">
          <table className="table table-small">
            <thead>
              <tr>
                <th></th>
                <th>#</th>
                <th>Producto</th>
                <th>Marca</th>
                <th>Modelo</th>
                <th>Cantidad</th>
                <th>Depósito</th>
              </tr>
            </thead>
            <tbody>
              {entradas.length === 0 && (
                <tr>
                  <td className="table-empty" colSpan="7">
                    Sin resultados
                  </td>
                </tr>
              )}

              {entradas.map((x) => (
                <tr key={x.id}>
                  <td className="table-actions">
                    <Dropdown
                      key={x.id}
                      overlay={() => menu(x)}
                      trigger={['click']}
                      placement="bottomLeft"
                      arrow
                    >
                      <div>
                        <FiMoreVertical />
                      </div>
                    </Dropdown>
                  </td>
                  <td>E{x.id.toString().padStart(8, '0')}</td>
                  <td>{x.productoTexto}</td>
                  <td>{x.marca}</td>
                  <td>{x.modelo}</td>
                  <td>{x.cantidad}</td>
                  <td>{x.depositoTexto}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {AGREGAR && (
            <div className="actions">
              <Button type="primary" onClick={onAdd}>
                Agregar
              </Button>
            </div>
          )}
        </div>
      </SlideDown>

      <Modal
        visible={formVisible}
        onCancel={() => setFormVisible(false)}
        footer={null}
        maskClosable={false}
        keyboard={false}
        destroyOnClose={true}
      >
        <EntradasForm item={current} onClose={onCloseForm} />
      </Modal>
    </div>
  );
};

export default Entradas;
