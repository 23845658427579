/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

import http from "../../../../services/http";

import empty from "../../../../assets/images/empty.svg";

const CobranzasApremios = (props) => {
  const [update, setUpdate] = useState();

  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (val) {
          return val.toLocaleString("es-AR", {
            style: "currency",
            currency: "ARS",
          });
        },
      },
    },
    xaxis: {
      categories: [],
    },
  });
  const [series, setSeries] = useState([]);

  useEffect(() => {
    async function getData() {
      const rData = await http.get(`tableros/hacienda/cobranzasapremios`);
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setUpdate(data.fecha);
        setOptions({
          ...options,
          xaxis: { categories: data.chart.categories },
        });
        setSeries(data.chart.series);
      }
    }

    getData();
  }, []);

  return (
    <div className="card chart">
      <div className="header">
        <div>
          <div className="title">Cobranzas en Apremios</div>
          <div className="update">Última actualización: {update}</div>
        </div>
      </div>

      {options.xaxis.categories.length === 0 && (
        <div className="empty">
          <img src={empty} alt="empty" />
          Sin resultados
        </div>
      )}

      {options.xaxis.categories.length > 0 && (
        <Chart
          className="canvas"
          options={options}
          series={series}
          type="bar"
          height={300}
          width="100%"
        />
      )}
    </div>
  );
};

export default CobranzasApremios;
