/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import { Context } from "../../../services/context";
import http from "../../../services/http";

import "./Tiempo.scss";

const Tiempo = () => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);

  const [promedio, setPromedio] = useState();

  useEffect(() => {
    async function getData() {
      const filters = state.getFilters();
      const response = await http.get("tableros/reclamos/tiempo" + filters);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setPromedio(data.promedio);
      }
    }

    getData();
  }, [state.reload]);

  return (
    <div className="tablero-card">
      <div className="title">{promedio}</div>
      <div className="subtitle">Tiempo promedio de resolución</div>
    </div>
  );
};

export default Tiempo;
