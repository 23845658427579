export const ProductoSalidaTipo = {
  Deposito: 1,
  ConsumidorFinal: 2,
};

export const ProductoSalidaEstado = {
  Pendiente: 1,
  Aceptada: 2,
  Rechazada: 3,
  Entregada: 4,
};
