/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import { Context } from "../../../services/context";
import http from "../../../services/http";

import "./Cantidad.scss";

const Cantidad = () => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(Context);

  const [cantidad, setCantidad] = useState();

  useEffect(() => {
    async function getData() {
      const filters = state.getFilters();
      const response = await http.get("tableros/reclamos/cantidad" + filters);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setCantidad(data.cantidad);
      }
    }

    getData();
  }, [state.reload]);

  return (
    <div className="tablero-card">
      <div className="title">{cantidad}</div>
      <div className="subtitle">Reclamos</div>
    </div>
  );
};

export default Cantidad;
