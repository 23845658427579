/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Button, Form, Input, Select } from 'antd';
import { FiArchive } from 'react-icons/fi';

import http from './../../../services/http';
import { showSuccess } from './../../../services/notification';
import { hasAction, actions } from './../../../services/security';
import { minimumOptions, required } from './../../../services/validation';

import Header from './../../../components/Header';

const { TextArea } = Input;
const { Option } = Select;

const DepositosForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.DEPOSITOS_VER);
  const CREAR = hasAction(actions.DEPOSITOS_CREAR);
  const EDITAR = hasAction(actions.DEPOSITOS_EDITAR);
  const GUARDAR = match.params.id === 'nuevo' ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === 'nuevo' ? 'Crear' : EDITAR ? 'Editar' : 'Ver',
    id: match.params.id === 'nuevo' ? 0 : parseInt(match.params.id),
    loading: false,
  });
  const [direcciones, setDirecciones] = useState([]);
  const [usuarios, setUsuarios] = useState([]);

  const breadcrumb = [
    { id: 1, title: 'Configuración' },
    { id: 2, title: 'Depósitos', url: '/configuracion/depositos' },
    { id: 3, title: state.title },
  ];

  const onDireccionChange = async (value) => {
    form.setFieldsValue({ usuario: null });

    const rData = await http.get(`depositos/data/usuarios/${value}`);

    if (rData && rData.data.code === 0) {
      const data = rData.data.data;
      setUsuarios(data.usuarios);
    }
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const rSave =
      state.id === 0
        ? await http.post('depositos', values)
        : await http.put(`depositos/${state.id}`, values);

    if (rSave && rSave.data.code === 0) {
      history.push('/configuracion/depositos');
      showSuccess('El depósito fue guardado exitosamente.');
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      const rData = await http.get('depositos/data');
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setDirecciones(data.direcciones);
      }

      const rForm = await http.get(`depositos/${state.id}`);
      if (rForm.data.code === 0) {
        const data = rForm.data.data;

        if (data.direccion) {
          await onDireccionChange(data.direccion);
        }

        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="deposito">
      <Header title={`${state.title} Depósito`} breadcrumb={breadcrumb} />

      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form card">
          <div className="form-icon">
            <FiArchive />
          </div>

          <Form.Item label="Nombre" name="nombre" rules={[required]}>
            <Input maxLength={250} disabled={!GUARDAR} />
          </Form.Item>

          <Form.Item label="Código" name="codigo">
            <Input maxLength={10} disabled={!GUARDAR} />
          </Form.Item>

          <Form.Item label="Descripción" name="descripcion">
            <TextArea showCount maxLength={250} disabled={!GUARDAR} />
          </Form.Item>

          <Form.Item label="Dirección" name="direccion" rules={[required]}>
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              disabled={!GUARDAR}
              onChange={onDireccionChange}
            >
              {direcciones.map((x) => (
                <Option key={x.value} value={x.value}>
                  {x.text}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Responsables"
            name="usuarios"
            rules={[minimumOptions(0)]}
          >
            <Select
              mode="multiple"
              allowClear
              showSearch
              optionFilterProp="children"
              maxTagCount="responsive"
              disabled={!GUARDAR}
            >
              {usuarios.map((x) => (
                <Option key={x.value} value={x.value}>
                  {x.text}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <div className="form-actions">
            <Link to="/configuracion/depositos">
              <Button type="secondary">Volver</Button>
            </Link>
            {GUARDAR && (
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default DepositosForm;
