/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Menu,
  Modal,
  Popover,
  Select,
  Tag,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  FiCheckCircle,
  FiEdit,
  FiPackage,
  FiPlus,
  FiPrinter,
  FiRepeat,
  FiSearch,
  FiTrash2,
  FiXCircle,
} from "react-icons/fi";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import { ContextProvider } from "../../services/context";
import { ProductoSalidaEstado } from "./../../services/enums";
import { downloadBase64 } from "../../services/files";
import http from "../../services/http";
import { showSuccess } from "../../services/notification";
import { hasAction, actions } from "../../services/security";

import Header from "../../components/Header";
import Filters from "../../components/Filters";
import FiltersButton from "../../components/FiltersButton";
import Table from "../../components/Table";

const { Option } = Select;

const Salidas = () => {
  const history = useHistory();

  const VER = hasAction(actions.SALIDAS_VER);
  const CREAR = hasAction(actions.SALIDAS_CREAR);
  const ELIMINAR = hasAction(actions.SALIDAS_ELIMINAR);
  const ACEPTAR = hasAction(actions.SALIDAS_ACEPTAR);
  const RECHAZAR = hasAction(actions.SALIDAS_RECHAZAR);
  const ENTREGAR = hasAction(actions.SALIDAS_ENTREGAR);
  const DEVOLVER = hasAction(actions.DEVOLUCIONES_CREAR);

  const [aceptarMultiple, setAceptarMultiple] = useState(false);
  const [rechazarMultiple, setRechazarMultiple] = useState(false);
  const [entregarMultiple, setEntregarMultipl] = useState(false);

  const [state, setState] = useState({
    filters: {
      visible: false,
      numero: undefined,
      desde: "",
      hasta: "",
      estado: undefined,
      categoria: "",
      destino: "",
      usuario: undefined,
      producto: "",
      marca: "",
      modelo: "",
      observaciones: "",
    },
    columns: [
      {
        title: "#",
        style: { minWidth: "80px" },
        render: (item) => "S" + item.id.toString().padStart(8, "0"),
      },
      {
        title: "Fecha",
        style: { minWidth: "100px", textAlign: "center" },
        render: (item) => item.fecha,
      },
      {
        title: "Estado",
        style: { minWidth: "150px", textAlign: "center" },
        render: (item) => {
          if (item.estado === ProductoSalidaEstado.Pendiente) {
            return <Tag color="purple">Pendiente</Tag>;
          } else if (item.estado === ProductoSalidaEstado.Aceptada) {
            return <Tag color="green">Aceptada</Tag>;
          } else if (item.estado === ProductoSalidaEstado.Rechazada) {
            return <Tag color="red">Rechazada</Tag>;
          } else if (item.estado === ProductoSalidaEstado.Entregada) {
            return <Tag color="gold">Entregada</Tag>;
          }
        },
      },
      {
        title: "Productos",
        style: { minWidth: "150px", textAlign: "center" },
        render: (item) => {
          const content = (
            <div className="popover-list">
              {item.productos.map((x, i) => (
                <div key={i} className="popover-item">
                  {x.cantidad} x {x.productoTexto}
                </div>
              ))}
            </div>
          );

          return (
            <Popover placement="top" title="Productos" content={content}>
              <Tag color="purple">{item.productos.length} productos</Tag>
            </Popover>
          );
        },
      },
      {
        title: "Origen",
        style: { minWidth: "300px" },
        render: (item) => item.depositoOrigen,
      },
      {
        title: "Destino",
        style: { minWidth: "300px" },
        render: (item) => item.depositoDestino,
      },
      {
        title: "Usuario",
        style: { minWidth: "200px" },
        render: (item) => item.usuario,
      },
      {
        title: "Observaciones",
        style: {},
        render: (item) => item.observaciones,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    selected: [],
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const [loadingAccept, setLoadingAccept] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [loadingDeliver, setLoadingDeliver] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);

  const [estados, setEstados] = useState([]);
  const [usuarios, setUsuarios] = useState([]);

  const [estadoSeleccionado, setEstadoSeleccionado] = useState();

  const breadcrumb = [
    { id: 1, title: "Solicitudes de Salida", url: "/salidas" },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        {ACEPTAR && aceptarMultiple && (
          <Button
            icon={<FiCheckCircle />}
            loading={loadingAccept}
            onClick={() => onAccept(state.selected)}
          >
            {!loadingAccept && "Aceptar"}
          </Button>
        )}
        {RECHAZAR && rechazarMultiple && (
          <Button
            icon={<FiXCircle />}
            loading={loadingReject}
            onClick={() => onReject(state.selected)}
          >
            {!loadingReject && "Rechazar"}
          </Button>
        )}
        {ENTREGAR && entregarMultiple && (
          <Button
            icon={<FiPackage />}
            loading={loadingDeliver}
            onClick={() => onDeliver(state.selected)}
          >
            {!loadingDeliver && "Entregar"}
          </Button>
        )}
        <Button
          type="primary"
          icon={<FiPrinter />}
          loading={loadingExport}
          onClick={onExport}
        >
          {!loadingExport && "Exportar"}
        </Button>
        <FiltersButton />
        {CREAR && (
          <Button type="primary" icon={<FiPlus />} onClick={onAdd}>
            Nuevo
          </Button>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Número" name="numero">
          <Input />
        </Form.Item>
        <Form.Item label="Desde" name="desde">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>
        <Form.Item label="Hasta" name="hasta">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>
        <Form.Item label="Estado" name="estado">
          <Select allowClear>
            {estados.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Categoría" name="categoria">
          <Input />
        </Form.Item>
        <Form.Item label="Tipo" name="tipo">
          <Select allowClear>
            <Option value="1">Deposito</Option>
            <Option value="2">Consumidor Final</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Destino" name="destino">
          <Input />
        </Form.Item>
        <Form.Item label="Usuario" name="usuario">
          <Select allowClear>
            {usuarios.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Producto" name="producto">
          <Input />
        </Form.Item>
        <Form.Item label="Marca" name="marca">
          <Input />
        </Form.Item>
        <Form.Item label="Modelo" name="modelo">
          <Input />
        </Form.Item>
        <Form.Item label="Observaciones" name="observaciones">
          <Input />
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {!item.editable && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiSearch />
            Ver
          </Menu.Item>
        )}
        {item.imprimible && (
          <Menu.Item key="4" onClick={() => onPrint([item.id])}>
            <FiPrinter />
            Remito
          </Menu.Item>
        )}
        {item.editable && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        <Menu.Divider />
        {ACEPTAR && item.aceptable && (
          <Menu.Item key="1" onClick={() => onAccept([item.id])}>
            <FiCheckCircle />
            Aceptar
          </Menu.Item>
        )}
        {RECHAZAR && item.rechazable && (
          <Menu.Item key="2" onClick={() => onReject([item.id])}>
            <FiXCircle />
            Rechazar
          </Menu.Item>
        )}
        {ENTREGAR && item.entregable && (
          <Menu.Item key="3" onClick={() => onDeliver([item.id])}>
            <FiPackage />
            Entregar
          </Menu.Item>
        )}
        {DEVOLVER && item.devolvible && (
          <Menu.Item key="5" onClick={() => onReturn(item)}>
            <FiRepeat />
            Devolver
          </Menu.Item>
        )}
        <Menu.Divider />
        {ELIMINAR && item.eliminable && (
          <Menu.Item key="6" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("salidas/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEstados(data.estados);
        setUsuarios(data.usuarios);
      }
    }

    getData();
  }, []);

  useEffect(() => {
    if (state.list.length === 0) {
      return;
    }

    let nList = onReload([...state.list]);
    setState({ ...state, list: nList });
  }, [estadoSeleccionado]);

  useEffect(() => {
    async function getPermisos() {
      const url = "salidas/acciones";
      const data = { seleccionados: state.selected };
      const response = await http.post(url, data);

      if (response && response.data.code === 0) {
        const data = response.data.data;
        setAceptarMultiple(data.aceptar);
        setRechazarMultiple(data.rechazar);
        setEntregarMultipl(data.entregar);
      }
    }

    getPermisos();
  }, [state.selected]);

  const onAdd = () => {
    history.push("/salidas/nuevo?tipo=1");
  };

  const onEdit = (item) => {
    history.push(`/salidas/${item.id}`);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar esta solicitud?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`salidas/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("La solicitud fue eliminada exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  const onReturn = (item) => {
    history.push(`/devoluciones/nuevo?salida=${item.id}`);
  };

  const onAccept = (list) => {
    Modal.confirm({
      title: "Aceptar Salidas",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea aceptar estas solicitudes de salida?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        setLoadingAccept(true);
        setEstadoSeleccionado();

        const response = await http.post("salidas/aceptar", {
          seleccionados: list,
        });
        if (response && response.data.code === 0) {
          showSuccess(
            "Las solicitudes de salida fueron aceptadas exitosamente."
          );
          setState({ ...state, reload: !state.reload, selected: [] });
        }

        setLoadingAccept(false);
      },
    });
  };

  const onReject = (list) => {
    Modal.confirm({
      title: "Rechazar Salidas",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea rechazar esta solicitudes de salida?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        setLoadingReject(true);
        setEstadoSeleccionado();

        const response = await http.post("salidas/rechazar", {
          seleccionados: list,
        });
        if (response && response.data.code === 0) {
          showSuccess(
            "La solicitudes de salida fueron rechazadas exitosamente."
          );
          setState({ ...state, reload: !state.reload, selected: [] });
        }

        setLoadingReject(false);
      },
    });
  };

  const onDeliver = (list) => {
    Modal.confirm({
      title: "Entregar productos",
      icon: <ExclamationCircleOutlined />,
      content:
        "¿Esta seguro que desea marcar estas solicitudes como entregadas?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        setLoadingDeliver(true);
        setEstadoSeleccionado();

        const response = await http.post("salidas/entregar", {
          seleccionados: list,
        });
        if (response && response.data.code === 0) {
          showSuccess("La solicitud fue marcada como entregada exitosamente.");
          try {
            await onPrint(list);
          } catch (error) {
            console.error(error);
          }
          setState({ ...state, reload: !state.reload, selected: [] });
        }

        setLoadingDeliver(false);
      },
    });
  };

  const onPrint = async (list) => {
    const response = await http.post("/salidas/remito", {
      seleccionados: list,
    });

    if (response && response.data.data) {
      const data = response.data.data;
      downloadBase64(data.filename, data.content);
    }
  };

  const onExport = async () => {
    setLoadingExport(true);

    const response = await http.post("/salidas/export", state.filters);
    if (response && response.data.data) {
      const data = response.data.data;
      downloadBase64(data.filename, data.content);
    }

    setLoadingExport(false);
  };

  const onReload = (list) => {
    for (let i = 0; i < list.length; i++) {
      list[i].disabled =
        estadoSeleccionado !== undefined &&
        list[i].estado !== estadoSeleccionado;
    }

    return list;
  };

  const onCheck = (list) => {
    if (estadoSeleccionado === undefined && list.length === 1) {
      const item = state.list.find((x) => x.id === list[0]);
      setEstadoSeleccionado(item.estado);
    }

    if (estadoSeleccionado !== undefined && list.length === 0) {
      setEstadoSeleccionado();
    }

    setState({ ...state, selected: list });
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="salidas page">
      <ContextProvider value={[state, setState]}>
        <Header
          title="Solicitudes de Salida"
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Table
          url="salidas/list"
          menu={menu}
          checkbox
          onReload={onReload}
          onCheck={onCheck}
        />
      </ContextProvider>
    </div>
  );
};

export default Salidas;
