/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Button, Form, Input } from "antd";
import { FiCompass } from "react-icons/fi";

import { ContextProvider } from "./../../../services/context";
import http from "../../../services/http";
import { showSuccess } from "../../../services/notification";
import { hasAction, actions } from "../../../services/security";
import { required } from "../../../services/validation";

import Equivalencias from "./Equivalencias";
import Header from "../../../components/Header";

const UnidadesForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.UNIDADES_VER);
  const CREAR = hasAction(actions.UNIDADES_CREAR);
  const EDITAR = hasAction(actions.UNIDADES_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
    equivalencias: [],
  });

  const breadcrumb = [
    { id: 1, title: "Configuración" },
    { id: 2, title: "Unidades de Medida", url: "/configuracion/unidades" },
    { id: 3, title: state.title },
  ];

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const rSave =
      state.id === 0
        ? await http.post("unidades", values)
        : await http.put(`unidades/${state.id}`, values);

    if (rSave && rSave.data.code === 0) {
      history.push("/configuracion/unidades");
      showSuccess("La unidad de medida fue guardada exitosamente.");
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      const rForm = await http.get(`unidades/${state.id}`);
      if (rForm.data.code === 0) {
        const data = rForm.data.data;
        setState({ ...state, equivalencias: data.equivalencias });
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, [state.id, state.reload]);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <ContextProvider value={[state, setState]}>
      <div className="unidad">
        <Header
          title={`${state.title} Unidad de Medida`}
          breadcrumb={breadcrumb}
        />

        <Form form={form} layout="vertical" onFinish={onSave}>
          <div className="form card">
            <div className="form-icon">
              <FiCompass />
            </div>

            <Form.Item label="Nombre" name="nombre" rules={[required]}>
              <Input maxLength={250} disabled={!GUARDAR} />
            </Form.Item>

            <Form.Item
              label="Abreviatura"
              name="abreviatura"
              rules={[required]}
            >
              <Input maxLength={20} disabled={!GUARDAR} />
            </Form.Item>

            <div className="form-actions">
              <Link to="/configuracion/unidades">
                <Button type="secondary">Volver</Button>
              </Link>
              {GUARDAR && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={state.loading}
                >
                  Guardar
                </Button>
              )}
            </div>
          </div>
        </Form>

        {state.id > 0 && (
          <React.Fragment>
            <Equivalencias />
          </React.Fragment>
        )}
      </div>
    </ContextProvider>
  );
};

export default UnidadesForm;
