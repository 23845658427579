/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Dropdown, Menu, Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  FiDownload,
  FiChevronUp,
  FiChevronDown,
  FiMoreVertical,
  FiPaperclip,
  FiSearch,
  FiTrash2,
} from 'react-icons/fi';
import { SlideDown } from 'react-slidedown';
import Viewer from 'react-viewer';
import Compressor from 'compressorjs';
import { FileIcon, defaultStyles } from 'react-file-icon';

import { Context } from './../../services/context';
import http from './../../services/http';
import { showSuccess } from './../../services/notification';
import { hasAction, actions } from './../../services/security';

const Archivos = () => {
  const AGREGAR = hasAction(actions.DEVOLUCIONES_ARCHIVOS_AGREGAR);
  const ELIMINAR = hasAction(actions.DEVOLUCIONES_ARCHIVOS_ELIMINAR);

  const [state] = useContext(Context);
  const [archivos, setArchivos] = useState([]);
  const [reload, setReload] = useState(false);

  const [visible, setVisible] = useState(false);
  const fileRef = useRef(null);

  const [previewUrl, setPreviewUrl] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);

  const images = ['jpg', 'jpeg', 'bmp', 'gif', 'png'];

  useEffect(() => {
    async function getList() {
      const response = await http.get(`devoluciones/${state.id}/archivos`);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setArchivos(data);
      }
    }

    getList();
  }, [reload]);

  const onAdd = () => {
    fileRef.current.click();
  };

  const onUpload = async (e) => {
    const files = e.target.files;
    if (files.length === 0) {
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].type.includes('image')) {
        compress(files[i]);
      } else {
        upload(files[i]);
      }
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const compress = async (file) => {
    new Compressor(file, {
      quality: 0.6,
      async success(result) {
        await upload(result);
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  const upload = async (file) => {
    const base64 = await toBase64(file);

    const data = {
      nombre: file.name,
      contenido: base64.split('base64,')[1],
      tamanio: file.size.toString(),
      devolucionId: state.id,
    };

    const response = await http.post('devoluciones/archivos', data);
    if (response && response.data.code === 0) {
      setReload(!reload);
    }
  };

  const onPreview = (file) => {
    setPreviewUrl(`${process.env.REACT_APP_API_URL}/archivos/${file.id}`);
    setPreviewVisible(true);
  };

  const onDownload = (file) => {
    window.open(
      `${process.env.REACT_APP_API_URL}/archivos/${file.id}`,
      '_blank'
    );
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: 'Confirmar eliminación',
      icon: <ExclamationCircleOutlined />,
      content: '¿Esta seguro que desea eliminar este archivo?',
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: async () => {
        const response = await http.delete(`devoluciones/archivos/${item.id}`);
        if (response && response.data.code === 0) {
          showSuccess('El archivo fue eliminado exitosamente.');
          setReload(!reload);
        }
      },
    });
  };

  const menu = (file) => {
    return (
      <Menu className="table-actions-menu">
        {images.indexOf(file.extension) > -1 && (
          <Menu.Item key="0" onClick={() => onPreview(file)}>
            <FiSearch />
            Ver
          </Menu.Item>
        )}
        <Menu.Item key="1" onClick={() => onDownload(file)}>
          <FiDownload />
          Descargar
        </Menu.Item>
        {ELIMINAR && (
          <Menu.Item key="2" onClick={() => onDelete(file)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  return (
    <div className="archivos form-subsection">
      <Viewer
        visible={previewVisible}
        onClose={() => {
          setPreviewVisible(false);
        }}
        images={[{ src: previewUrl, alt: '' }]}
      />

      <div
        className="form-subsection-header"
        onClick={() => setVisible(!visible)}
      >
        <div className="icon">
          <FiPaperclip />
        </div>
        <div className="content">
          <div className="title">Archivos</div>
          <div className="subtitle">{archivos.length} elementos</div>
        </div>
        <div className="collapse">
          {visible ? <FiChevronUp /> : <FiChevronDown />}
        </div>
      </div>
      <SlideDown closed={!visible}>
        <div className="form-subsection-content">
          <table className="table table-small">
            <thead>
              <tr>
                <th></th>
                <th>Nombre</th>
                <th>Fecha</th>
                <th>Tamaño</th>
              </tr>
            </thead>
            <tbody>
              {archivos.length === 0 && (
                <tr>
                  <td className="table-empty" colSpan="6">
                    Sin resultados
                  </td>
                </tr>
              )}

              {archivos.map((x) => (
                <tr key={x.id}>
                  <td className="table-actions">
                    <Dropdown
                      key={x.id}
                      overlay={() => menu(x)}
                      trigger={['click']}
                      placement="bottomLeft"
                      arrow
                    >
                      <div>
                        <FiMoreVertical />
                      </div>
                    </Dropdown>
                  </td>
                  <td className="td-icon">
                    <div className="icon">
                      <FileIcon
                        extension={x.extension}
                        {...defaultStyles[x.extension]}
                      />
                    </div>

                    {x.nombre}
                  </td>
                  <td>{x.fecha}</td>
                  <td>{x.tamanio}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {AGREGAR && (
            <div className="actions">
              <input
                type="file"
                multiple={true}
                ref={fileRef}
                onChange={onUpload}
              />
              <Button type="primary" onClick={onAdd}>
                Subir
              </Button>
            </div>
          )}
        </div>
      </SlideDown>
    </div>
  );
};

export default Archivos;
