export const required = {
  required: true,
  message: "Este campo es obligatorio",
};

export const compare = (input, message) => {
  return ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue(input) === value) {
        return Promise.resolve();
      }

      return Promise.reject(message);
    },
  });
};

export const regex = (expression) => {
  return {
    pattern: expression.pattern,
    message: expression.message,
  };
};

export const minimumOptions = (minimum) => {
  return (event) => ({
    validator(rule, value) {
      if (value.length > minimum) {
        return Promise.resolve();
      }

      return Promise.reject(`Debe seleccionar al menos ${minimum + 1}`);
    },
  });
};
