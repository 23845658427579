import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import CountUp from "react-countup";
import {
  FiMaximize,
  FiFileText,
  FiArchive,
  FiLogOut,
  FiRepeat,
  FiCompass,
  FiShield,
  FiFlag,
  FiFolder,
  FiMap,
  FiMapPin,
  FiMessageSquare,
  FiPackage,
  FiUser,
  FiList,
  FiLayers,
  FiTruck,
  FiClock,
  FiSettings,
  FiBarChart2,
  FiPieChart,
  FiAlertTriangle,
} from "react-icons/fi";
import Header from "./../../components/Header";
import http from "./../../services/http";
import { hasAction, actions } from "./../../services/security";
import "./Inicio.scss";

const Inicio = () => {
  const [state, setState] = useState({
    expedientes: 0,
    inventario: 0,
    salidas: 0,
    devoluciones: 0,
    puntoPedido: 0,
    reclamos: 0,
    tableroHacienda: 0,
    tableroReclamos: 0,
    catastro: 0,
    areas: 0,
    direcciones: 0,
    categorias: 0,
    productos: 0,
    parametros: 0,
    proveedores: 0,
    unidades: 0,
    depositos: 0,
    puntos: 0,
    reclamosTipos: 0,
    perfiles: 0,
    usuarios: 0,
    auditoria: 0,
  });

  useEffect(() => {
    async function getData() {
      const result = await http.get("inicio");

      if (result && result.data.code === 0) {
        const data = result.data.data;
        setState(data);
      }
    }

    getData();
  }, []);

  return (
    <div className="inicio">
      <Header title="Inicio" breadcrumb={[]} buttons={() => {}} />
      {(hasAction(actions.EXPEDIENTES_VER) ||
        hasAction(actions.INVENTARIO_VER) ||
        hasAction(actions.SALIDAS_VER) ||
        hasAction(actions.DEVOLUCIONES_VER) ||
        hasAction(actions.PUNTO_PEDIDO_VER) ||
        hasAction(actions.RECLAMOS_VER)) && (
        <React.Fragment>
          <Row gutter={[32, 36]}>
            {hasAction(actions.EXPEDIENTES_VER) && (
              <Col xs={12} md={8} xl={6} xxl={4}>
                <Link to="/expedientes">
                  <div className="card primary">
                    <div className="card-icon">
                      <FiFileText />
                    </div>
                    <div className="card-count">
                      <CountUp end={state.expedientes} />
                    </div>
                    <div className="card-title">Expedientes</div>
                  </div>
                </Link>
              </Col>
            )}
            {hasAction(actions.INVENTARIO_VER) && (
              <Col xs={12} md={8} xl={6} xxl={4}>
                <Link to="/inventario">
                  <div className="card primary">
                    <div className="card-icon">
                      <FiList />
                    </div>
                    <div className="card-count">
                      <CountUp end={state.inventario} />
                    </div>
                    <div className="card-title">Inventario</div>
                  </div>
                </Link>
              </Col>
            )}
            {hasAction(actions.SALIDAS_VER) && (
              <Col xs={12} md={8} xl={6} xxl={4}>
                <Link to="/salidas">
                  <div className="card primary">
                    <div className="card-icon">
                      <FiLogOut />
                    </div>
                    <div className="card-count">
                      <CountUp end={state.salidas} />
                    </div>
                    <div className="card-title">Solicitudes de Salida</div>
                  </div>
                </Link>
              </Col>
            )}
            {hasAction(actions.DEVOLUCIONES_VER) && (
              <Col xs={12} md={8} xl={6} xxl={4}>
                <Link to="/devoluciones">
                  <div className="card primary">
                    <div className="card-icon">
                      <FiRepeat />
                    </div>
                    <div className="card-count">
                      <CountUp end={state.devoluciones} />
                    </div>
                    <div className="card-title">Devoluciones</div>
                  </div>
                </Link>
              </Col>
            )}
            {hasAction(actions.PUNTO_PEDIDO_VER) && (
              <Col xs={12} md={8} xl={6} xxl={4}>
                <Link to="/punto-pedido">
                  <div className="card primary">
                    <div className="card-icon">
                      <FiAlertTriangle />
                    </div>
                    <div className="card-count">
                      <CountUp end={state.puntoPedido} />
                    </div>
                    <div className="card-title">Punto de Pedido</div>
                  </div>
                </Link>
              </Col>
            )}
            {hasAction(actions.RECLAMOS_VER) && (
              <Col xs={12} md={8} xl={6} xxl={4}>
                <Link to="/reclamos">
                  <div className="card primary">
                    <div className="card-icon">
                      <FiMessageSquare />
                    </div>
                    <div className="card-count">
                      <CountUp end={state.reclamos} />
                    </div>
                    <div className="card-title">Reclamos</div>
                  </div>
                </Link>
              </Col>
            )}
          </Row>
        </React.Fragment>
      )}
      {(hasAction(actions.TABLEROS_HACIENDA_VER) ||
        hasAction(actions.TABLEROS_RECLAMOS_VER)) && (
        <React.Fragment>
          <h3 className="section-header">Tableros</h3>
          <Row gutter={[32, 36]}>
            {hasAction(actions.TABLEROS_HACIENDA_VER) && (
              <Col xs={12} md={8} xl={6} xxl={4}>
                <Link to="/tableros/hacienda">
                  <div className="card primary">
                    <div className="card-icon">
                      <FiBarChart2 />
                    </div>
                    <div className="card-count">
                      <CountUp end={state.tableroHacienda} />
                    </div>
                    <div className="card-title">Tablero de Hacienda</div>
                  </div>
                </Link>
              </Col>
            )}
            {hasAction(actions.TABLEROS_RECLAMOS_VER) && (
              <Col xs={12} md={8} xl={6} xxl={4}>
                <Link to="/tableros/reclamos">
                  <div className="card primary">
                    <div className="card-icon">
                      <FiPieChart />
                    </div>
                    <div className="card-count">
                      <CountUp end={state.tableroReclamos} />
                    </div>
                    <div className="card-title">Tablero de Reclamos</div>
                  </div>
                </Link>
              </Col>
            )}
          </Row>
        </React.Fragment>
      )}
      {hasAction(actions.CATASTRO_VER) && (
        <React.Fragment>
          <h3 className="section-header">Notificaciones</h3>
          <Row gutter={[32, 36]}>
            {hasAction(actions.CATASTRO_VER) && (
              <Col xs={12} md={8} xl={6} xxl={4}>
                <Link to="/notificaciones/catastro">
                  <div className="card success">
                    <div className="card-icon">
                      <FiMap />
                    </div>
                    <div className="card-count">
                      <CountUp end={state.catastro} />
                    </div>
                    <div className="card-title">Catastro</div>
                  </div>
                </Link>
              </Col>
            )}
          </Row>
        </React.Fragment>
      )}

      {(hasAction(actions.AREAS_VER) ||
        hasAction(actions.CATEGORIAS_VER) ||
        hasAction(actions.DEPOSITOS_VER) ||
        hasAction(actions.DIRECCIONES_VER) ||
        hasAction(actions.PARAMETROS_VER) ||
        hasAction(actions.PROVEEDORES_VER) ||
        hasAction(actions.PUNTOS_RECLAMO_VER) ||
        hasAction(actions.UNIDADES_VER)) && (
        <React.Fragment>
          <h3 className="section-header">Configuración</h3>
          <Row gutter={[32, 36]}>
            {hasAction(actions.AREAS_VER) && (
              <Col xs={12} md={8} xl={6} xxl={4}>
                <Link to="/configuracion/areas">
                  <div className="card info">
                    <div className="card-icon">
                      <FiMaximize />
                    </div>
                    <div className="card-count">
                      <CountUp end={state.areas} />
                    </div>
                    <div className="card-title">Areas</div>
                  </div>
                </Link>
              </Col>
            )}
            {hasAction(actions.CATEGORIAS_VER) && (
              <Col xs={12} md={8} xl={6} xxl={4}>
                <Link to="/configuracion/categorias">
                  <div className="card info">
                    <div className="card-icon">
                      <FiLayers />
                    </div>
                    <div className="card-count">
                      <CountUp end={state.categorias} />
                    </div>
                    <div className="card-title">Categorías</div>
                  </div>
                </Link>
              </Col>
            )}
            {hasAction(actions.DEPOSITOS_VER) && (
              <Col xs={12} md={8} xl={6} xxl={4}>
                <Link to="/configuracion/depositos">
                  <div className="card info">
                    <div className="card-icon">
                      <FiArchive />
                    </div>
                    <div className="card-count">
                      <CountUp end={state.depositos} />
                    </div>
                    <div className="card-title">Depósitos</div>
                  </div>
                </Link>
              </Col>
            )}
            {hasAction(actions.DIRECCIONES_VER) && (
              <Col xs={12} md={8} xl={6} xxl={4}>
                <Link to="/configuracion/direcciones">
                  <div className="card info">
                    <div className="card-icon">
                      <FiFolder />
                    </div>
                    <div className="card-count">
                      <CountUp end={state.direcciones} />
                    </div>
                    <div className="card-title">Direcciones</div>
                  </div>
                </Link>
              </Col>
            )}
            {hasAction(actions.PARAMETROS_VER) && (
              <Col xs={12} md={8} xl={6} xxl={4}>
                <Link to="/configuracion/parametros">
                  <div className="card info">
                    <div className="card-icon">
                      <FiSettings />
                    </div>
                    <div className="card-count">
                      <CountUp end={state.parametros} />
                    </div>
                    <div className="card-title">Parámetros</div>
                  </div>
                </Link>
              </Col>
            )}
            {hasAction(actions.PRODUCTOS_VER) && (
              <Col xs={12} md={8} xl={6} xxl={4}>
                <Link to="/configuracion/productos">
                  <div className="card info">
                    <div className="card-icon">
                      <FiPackage />
                    </div>
                    <div className="card-count">
                      <CountUp end={state.productos} />
                    </div>
                    <div className="card-title">Productos</div>
                  </div>
                </Link>
              </Col>
            )}
            {hasAction(actions.PROVEEDORES_VER) && (
              <Col xs={12} md={8} xl={6} xxl={4}>
                <Link to="/configuracion/proveedores">
                  <div className="card info">
                    <div className="card-icon">
                      <FiTruck />
                    </div>
                    <div className="card-count">
                      <CountUp end={state.proveedores} />
                    </div>
                    <div className="card-title">Proveedores</div>
                  </div>
                </Link>
              </Col>
            )}
            {hasAction(actions.PUNTOS_RECLAMO_VER) && (
              <Col xs={12} md={8} xl={6} xxl={4}>
                <Link to="/configuracion/puntosreclamo">
                  <div className="card info">
                    <div className="card-icon">
                      <FiMapPin />
                    </div>
                    <div className="card-count">
                      <CountUp end={state.puntos} />
                    </div>
                    <div className="card-title">Puntos de Reclamo</div>
                  </div>
                </Link>
              </Col>
            )}
            {hasAction(actions.TIPOS_RECLAMOS_VER) && (
              <Col xs={12} md={8} xl={6} xxl={4}>
                <Link to="/configuracion/tiposreclamo">
                  <div className="card info">
                    <div className="card-icon">
                      <FiFlag />
                    </div>
                    <div className="card-count">
                      <CountUp end={state.reclamosTipos} />
                    </div>
                    <div className="card-title">Tipos de Reclamo</div>
                  </div>
                </Link>
              </Col>
            )}
            {hasAction(actions.UNIDADES_VER) && (
              <Col xs={12} md={8} xl={6} xxl={4}>
                <Link to="/configuracion/unidades">
                  <div className="card info">
                    <div className="card-icon">
                      <FiCompass />
                    </div>
                    <div className="card-count">
                      <CountUp end={state.unidades} />
                    </div>
                    <div className="card-title">Unidades de Medida</div>
                  </div>
                </Link>
              </Col>
            )}
          </Row>
        </React.Fragment>
      )}

      {(hasAction(actions.PERFILES_VER) ||
        hasAction(actions.USUARIOS_VER) ||
        hasAction(actions.AUDITORIA_VER)) && (
        <React.Fragment>
          <h3 className="section-header">Seguridad</h3>
          <Row gutter={[32, 36]}>
            {hasAction(actions.PERFILES_VER) && (
              <Col xs={12} md={8} xl={6} xxl={4}>
                <Link to="/seguridad/perfiles">
                  <div className="card primary">
                    <div className="card-icon">
                      <FiShield />
                    </div>
                    <div className="card-count">
                      <CountUp end={state.perfiles} />
                    </div>
                    <div className="card-title">Perfiles</div>
                  </div>
                </Link>
              </Col>
            )}
            {hasAction(actions.USUARIOS_VER) && (
              <Col xs={12} md={8} xl={6} xxl={4}>
                <Link to="/seguridad/usuarios">
                  <div className="card primary">
                    <div className="card-icon">
                      <FiUser />
                    </div>
                    <div className="card-count">
                      <CountUp end={state.usuarios} />
                    </div>
                    <div className="card-title">Usuarios</div>
                  </div>
                </Link>
              </Col>
            )}
            {hasAction(actions.AUDITORIA_VER) && (
              <Col xs={12} md={8} xl={6} xxl={4}>
                <Link to="/seguridad/auditoria">
                  <div className="card primary">
                    <div className="card-icon">
                      <FiClock />
                    </div>
                    <div className="card-count">
                      <CountUp end={state.auditoria} />
                    </div>
                    <div className="card-title">Auditoría</div>
                  </div>
                </Link>
              </Col>
            )}
          </Row>
        </React.Fragment>
      )}
    </div>
  );
};

export default Inicio;
