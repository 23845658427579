/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Input } from 'antd';

import { ContextProvider } from './../../services/context';
import { hasAction, actions } from './../../services/security';

import Header from './../../components/Header';
import Filters from './../../components/Filters';
import FiltersButton from './../../components/FiltersButton';
import Table from './../../components/Table';

const PuntoPedido = () => {
  const VER = hasAction(actions.PUNTO_PEDIDO_VER);

  const [state, setState] = useState({
    filters: {
      visible: false,
      nombre: '',
      deposito: '',
    },
    columns: [
      {
        title: 'Deposito',
        style: {},
        render: (item) => item.deposito,
      },
      {
        title: 'Producto',
        style: {},
        render: (item) => item.producto,
      },
      {
        title: 'Stock',
        style: { minWidth: '100px', textAlign: 'center' },
        render: (item) => item.stock,
      },
      {
        title: 'Punto de Pedido',
        style: { minWidth: '100px', textAlign: 'center' },
        render: (item) => item.puntoPedido,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const breadcrumb = [
    { id: 1, title: 'Punto de Pedido', url: '/configuracion/punto-pedido' },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Deposito" name="deposito">
          <Input />
        </Form.Item>
        <Form.Item label="Producto" name="producto">
          <Input />
        </Form.Item>
      </React.Fragment>
    );
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="punto-pedido page">
      <ContextProvider value={[state, setState]}>
        <Header
          title="Punto de Pedido"
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Table url="puntopedido/list" />
      </ContextProvider>
    </div>
  );
};

export default PuntoPedido;
