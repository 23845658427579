import React from "react";
import { Button, Form, Input, Modal } from "antd";

const ModalExpediente = ({ visible, onSubmit, onClose }) => {
  const [form] = Form.useForm();

  const onConfirm = async () => {
    const values = form.getFieldsValue();
    form.setFieldsValue({ expediente: "" });
    await onSubmit(values);
  };

  const onModalClose = () => {
    form.setFieldsValue({ expediente: "" });
    onClose();
  };

  return (
    <Modal
      visible={visible}
      title="Ingresar Expediente"
      forceRender
      onCancel={onModalClose}
      footer={[
        <Button key="back" type="secondary" onClick={onModalClose}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={onConfirm}>
          Imprimir
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="expediente" style={{ marginBottom: "0px" }}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalExpediente;
