/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Form, Input } from "antd";

import { ContextProvider } from "./../../../services/context";
import { hasAction, actions } from "./../../../services/security";

import Header from "./../../../components/Header";
import Filters from "./../../../components/Filters";
import FiltersButton from "./../../../components/FiltersButton";
import Table from "./../../../components/Table";

const Proveedores = () => {
  const VER = hasAction(actions.PROVEEDORES_VER);

  const [state, setState] = useState({
    filters: {
      visible: false,
      nombre: "",
    },
    columns: [
      {
        id: 1,
        title: "Nombre",
        style: {},
        render: (item) => item.nombre,
      },
      {
        id: 2,
        title: "Cuil",
        style: {},
        render: (item) => item.cuil,
      },
      {
        id: 8,
        title: "Email",
        style: {},
        render: (item) => item.email,
      },
      {
        id: 9,
        title: "Telefono",
        style: {},
        render: (item) => item.telefono,
      },
      {
        id: 3,
        title: "Dirección",
        style: {},
        render: (item) => item.direccion,
      },
      {
        id: 4,
        title: "Número",
        style: {},
        render: (item) => item.numero,
      },
      {
        id: 5,
        title: "Piso",
        style: {},
        render: (item) => item.piso,
      },
      {
        id: 6,
        title: "Departamento",
        style: {},
        render: (item) => item.departamento,
      },
      {
        id: 7,
        title: "Localidad",
        style: {},
        render: (item) => item.Localidad,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const breadcrumb = [
    { id: 1, title: "Configuración" },
    { id: 2, title: "Proveedores", url: "/configuracion/proveedores" },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Nombre" name="nombre">
          <Input />
        </Form.Item>
      </React.Fragment>
    );
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="proveedores page">
      <ContextProvider value={[state, setState]}>
        <Header title="Proveedores" breadcrumb={breadcrumb} buttons={buttons} />
        <Filters filters={filters} />
        <Table url="proveedores/list" />
      </ContextProvider>
    </div>
  );
};

export default Proveedores;
