/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Form, Input, Menu, Modal, Select } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FiEdit, FiPlus, FiTrash2 } from "react-icons/fi";

import { ContextProvider } from "../../../services/context";
import http from "../../../services/http";
import { showSuccess } from "../../../services/notification";
import { hasAction, actions } from "../../../services/security";

import Header from "../../../components/Header";
import Filters from "../../../components/Filters";
import FiltersButton from "../../../components/FiltersButton";
import Table from "../../../components/Table";

const TiposReclamos = () => {
  const history = useHistory();

  const VER = hasAction(actions.TIPOS_RECLAMOS_VER);
  const CREAR = hasAction(actions.TIPOS_RECLAMOS_CREAR);
  const EDITAR = hasAction(actions.TIPOS_RECLAMOS_EDITAR);
  const ELIMINAR = hasAction(actions.TIPOS_RECLAMOS_ELIMINAR);

  const [state, setState] = useState({
    filters: {
      visible: false,
      nombre: "",
      area: "",
    },
    columns: [
      {
        id: 1,
        title: "Nombre",
        style: {},
        render: (item) => item.nombre,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const [areas, setAreas] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Configuración" },
    { id: 2, title: "Tipos de Reclamo", url: "/configuracion/tiposreclamo" },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        {CREAR && (
          <Button type="primary" icon={<FiPlus />} onClick={onAdd}>
            Nuevo
          </Button>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Nombre" name="nombre">
          <Input />
        </Form.Item>
        <Form.Item label="Área" name="area">
          <Select allowClear>
            {areas.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="1" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onAdd = () => {
    history.push("/configuracion/tiposreclamo/nuevo");
  };

  const onEdit = (item) => {
    history.push(`/configuracion/tiposreclamo/${item.id}`);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este tipo de reclamo?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`reclamostipos/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("El tipo de reclamo fue eliminada exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  useEffect(() => {
    async function getData() {
      const rData = await http.get("reclamostipos/data");
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setAreas(data.areas);
      }
    }

    getData();
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="tiposReclamo page">
      <ContextProvider value={[state, setState]}>
        <Header
          title="Tipos de Reclamo"
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Table url="reclamostipos/list" menu={menu} />
      </ContextProvider>
    </div>
  );
};

export default TiposReclamos;
