/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Select, Button } from "antd";
import { FiEdit } from "react-icons/fi";

import { Context } from "../../services/context";
import http from "../../services/http";
import { required } from "../../services/validation";

const EstadoForm = ({ item, onClose }) => {
  const [form] = Form.useForm();

  const [state, setState] = useContext(Context);
  const [loading, setLoading] = useState(false);

  const [estados, setEstados] = useState([]);

  useEffect(() => {
    async function getForm() {
      let response = await http.get("reclamos/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEstados(data.estados);
        form.resetFields();
      }
    }

    getForm();
  }, []);

  const onSave = async (values) => {
    values.seleccionados = state.selected;

    setLoading(true);
    const response = await http.put("reclamos/estado", values);
    setLoading(false);

    if (response && response.data.code === 0) {
      let nState = { ...state };
      nState.reload = !nState.reload;
      nState.selected = [];
      setState(nState);
      onClose();
    }
  };

  return (
    <Form form={form} name="form-estado" layout="vertical" onFinish={onSave}>
      <div className="form">
        <div className="form-icon">
          <FiEdit />
        </div>

        <Form.Item label="Estado" name="estado" rules={[required]}>
          <Select showSearch optionFilterProp="children">
            {estados.map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Informe" name="informe" className="textarea">
          <Input.TextArea showCount maxLength={500} />
        </Form.Item>

        <div className="form-actions">
          <Button type="secondary" onClick={onClose}>
            Volver
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Guardar
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default EstadoForm;
