/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Redirect } from "react-router-dom";
import { Tabs } from "antd";

import { hasAction, actions } from "../../../services/security";

import Header from "../../../components/Header";
import Rentas from "./Rentas";
import Censo from "./Censo";

const Tablero = () => {
  const VER = hasAction(actions.TABLEROS_HACIENDA_VER);

  const breadcrumb = [
    { id: 1, title: "Tableros" },
    { id: 2, title: "Hacienda", url: "/tableros/hacienda" },
  ];

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="hacienda page">
      <Header title="Hacienda" breadcrumb={breadcrumb} buttons={() => []} />

      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Indicadores de Rentas" key="1">
          <Rentas />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Censo" key="2">
          <Censo />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default Tablero;
