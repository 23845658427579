/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  TimePicker,
} from "antd";
import { FiLogOut } from "react-icons/fi";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import useQuery from "./../../hooks/useQuery";

import { ContextProvider } from "../../services/context";
import {
  parseDate,
  parseTime,
  parseToDate,
  parseToTime,
} from "../../services/forms";
import http from "../../services/http";
import { showSuccess } from "./../../services/notification";
import { hasAction, actions } from "../../services/security";
import { required } from "./../../services/validation";

import Header from "../../components/Header";
import Productos from "./Productos";
import Archivos from "./Archivos";

const SalidasForm = ({ match }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const query = useQuery();

  const VER = hasAction(actions.SALIDAS_VER);
  const CREAR = hasAction(actions.SALIDAS_CREAR);

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : "Editar",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loaded: false,
    loading: false,
    reload: false,
    tipo: query.get("tipo") ?? "1",
    depositoOrigen: query.get("origen"),
    depositoOrigenDisabled: query.get("origen") ? true : false,
    readonly: false,
    productos: [],
  });

  const [depositosOrigen, setDepositosOrigen] = useState([]);
  const [depositosDestino, setDepositosDestino] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Solicitudes de Salida", url: "/salidas" },
    { id: 2, title: state.title },
  ];

  useEffect(() => {
    async function getData() {
      const response = await http.get(`salidas/data`);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setDepositosOrigen(data.depositos);
        setDepositosDestino(data.depositos);
        setState({ ...state, loaded: true });
      }
    }

    getData();
  }, []);

  useEffect(() => {
    async function getForm() {
      if (!state.loaded) {
        return;
      }

      const response = await http.get(`salidas/${state.id}`);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        data.fecha = parseToDate(data.fecha);
        data.hora = parseToTime(data.hora);
        data.depositoOrigen = state.depositoOrigen ?? data.depositoOrigen;

        form.setFieldsValue(data);

        setState({
          ...state,
          title: !data.editable ? "Ver" : "Editar",
          readonly: !data.editable,
          tipo: data.tipo?.toString() ?? state.tipo,
          depositoOrigen: data.depositoOrigen,
          depositoOrigenDisabled:
            state.depositoOrigenDisabled === true || state.id > 0,
          productos: data.productos,
        });
      }
    }

    getForm();
  }, [state.id, state.loaded, state.reload]);

  useEffect(() => {
    async function getDepositos() {
      if (state.depositoOrigenDisabled || state.readonly) {
        return;
      }

      const response = await http.post(`salidas/data/depositos`, {
        productos: state.productos,
      });

      if (response && response.data.code === 0) {
        const data = response.data.data;
        setDepositosOrigen(data.depositos);
        form.setFieldsValue({ depositosOrigen: "" });
      }
    }

    getDepositos();
  }, [state.productos]);

  const onChangeDepositoOrigen = (value) => {
    if (form.getFieldValue("depositoDestino") === value) {
      form.setFieldsValue({ depositoDestino: "" });
    }

    if (!state.depositoOrigen) {
      setState({ ...state, depositoOrigen: value });
      return;
    }

    Modal.confirm({
      title: "Cambiar depósito de origen",
      icon: <ExclamationCircleOutlined />,
      content:
        "Al cambiar el depósito de origen, se eliminaran todos los items guardados ¿Esta seguro que desea realizar esta acción?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        setState({ ...state, depositoOrigen: value, productos: [] });
      },
    });
  };

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    values.fecha = parseDate(values.fecha);
    values.hora = parseTime(values.hora);
    values.tipo = state.tipo;
    values.productos = state.productos;

    const response =
      state.id === 0
        ? await http.post("salidas", values)
        : await http.put(`salidas/${state.id}`, values);

    if (response && response.data.code === 0) {
      showSuccess("La solicitud de salida fue guardada exitosamente.");

      if (state.id === 0) {
        Modal.confirm({
          title: "Continuar registrando salidas",
          icon: <ExclamationCircleOutlined />,
          content: "¿Desea registrar otra salida?",
          okText: "Confirmar",
          cancelText: "Cancelar",
          onOk: () => {
            history.go(0);
          },
          onCancel: () => history.push("/salidas"),
        });
      } else {
        history.push("/salidas");
      }
    } else {
      setState({ ...state, loading: false });
    }
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <ContextProvider value={[state, setState]}>
      <div className="salida">
        <Header
          title={`${state.title} Solicitud de Salida`}
          breadcrumb={breadcrumb}
        />
        <Form form={form} layout="vertical" onFinish={onSave}>
          <div className="form card">
            <div className="form-icon">
              <FiLogOut />
            </div>

            <Row gutter={[16, 5]}>
              <Col span={12}>
                <Form.Item label="Fecha" name="fecha">
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder=""
                    locale={locale}
                    disabled={state.readonly}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Hora" name="hora">
                  <TimePicker format="HH:mm" disabled={state.readonly} />
                </Form.Item>
              </Col>

              <Productos />

              <Col span={12}>
                <Form.Item
                  label="Depósito Origen"
                  name="depositoOrigen"
                  rules={[required]}
                >
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    onChange={onChangeDepositoOrigen}
                    disabled={state.depositoOrigenDisabled || state.readonly}
                  >
                    {depositosOrigen.map((x) => (
                      <Select.Option key={x.value} value={x.value}>
                        {x.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {state.tipo === "1" && (
                <Col span={12}>
                  <Form.Item
                    label="Depósito Destino"
                    name="depositoDestino"
                    rules={[required]}
                  >
                    <Select
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      disabled={state.readonly}
                    >
                      {depositosDestino
                        .filter((x) => x.value !== state.depositoOrigen)
                        .map((x) => (
                          <Select.Option key={x.value} value={x.value}>
                            {x.text}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              {state.tipo === "2" && (
                <Col span={12}>
                  <Form.Item
                    label="Consumidor Final"
                    name="consumidorFinal"
                    rules={[required]}
                  >
                    <Input disabled={state.readonly} />
                  </Form.Item>
                </Col>
              )}

              <Col span={24}>
                <Form.Item
                  label="Observaciones"
                  name="observaciones"
                  className="textarea"
                >
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    disabled={state.readonly}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>

          {state.id > 0 && <Archivos />}

          <div className="form-actions">
            <Link to="/salidas">
              <Button type="secondary">Volver</Button>
            </Link>
            {CREAR && !state.readonly && (
              <Button type="primary" htmlType="submit" loading={state.loading}>
                {!state.loading && "Guardar"}
              </Button>
            )}
          </div>
        </Form>
      </div>
    </ContextProvider>
  );
};

export default SalidasForm;
