/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Button, Form, Input } from "antd";
import { FiTruck } from "react-icons/fi";

import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notification";
import { hasAction, actions } from "./../../../services/security";
import { required } from "./../../../services/validation";

import Header from "./../../../components/Header";

const ProveedoresForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.PROVEEDORES_VER);
  const CREAR = hasAction(actions.PROVEEDORES_CREAR);
  const EDITAR = hasAction(actions.PROVEEDORES_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });

  const breadcrumb = [
    { id: 1, title: "Configuración" },
    { id: 2, title: "Proveedores", url: "/configuracion/proveedores" },
    { id: 3, title: state.title },
  ];

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const result =
      state.id === 0
        ? await http.post("proveedores", values)
        : await http.put(`proveedores/${state.id}`, values);

    if (result && result.data.code === 0) {
      history.push("/configuracion/proveedores");
      showSuccess("El proveedor fue guardado exitosamente.");
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      const rForm = await http.get(`proveedores/${state.id}`);
      if (rForm.data.code === 0) {
        const data = rForm.data.data;
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="proveedor">
      <Header title={`${state.title} Proveedor`} breadcrumb={breadcrumb} />

      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form card">
          <div className="form-icon">
            <FiTruck />
          </div>

          <Form.Item label="Nombre" name="nombre" rules={[required]}>
            <Input maxLength={250} disabled={!GUARDAR} />
          </Form.Item>

          <div className="form-actions">
            <Link to="/configuracion/proveedores">
              <Button type="secondary">Volver</Button>
            </Link>
            {GUARDAR && (
              <Button type="primary" htmlType="submit" loading={state.Loading}>
                Guardar
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ProveedoresForm;
