import React, { useState } from "react";
import { SlideDown } from "react-slidedown";
import { FiChevronDown, FiChevronUp, FiClock } from "react-icons/fi";

const Historial = ({ list }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className="items form-subsection">
      <div
        className="form-subsection-header"
        onClick={() => setVisible(!visible)}
      >
        <div className="icon">
          <FiClock />
        </div>
        <div className="content">
          <div className="title">Historial de Cambios</div>
          <div className="subtitle">{list.length} cambios</div>
        </div>
        <div className="collapse">
          {visible ? <FiChevronUp /> : <FiChevronDown />}
        </div>
      </div>

      <SlideDown closed={!visible}>
        <div className="form-subsection-content">
          <table className="table table-small">
            <thead>
              <tr>
                <th style={{ width: "120px", textAlign: "center" }}>Fecha</th>
                <th style={{ width: "120px", textAlign: "center" }}>Hora</th>
                <th style={{ width: "120px", textAlign: "center" }}>
                  Anterior
                </th>
                <th style={{ width: "120px", textAlign: "center" }}>Estado</th>
                <th>Informe</th>
                <th style={{ width: "250px" }}>Usuario</th>
              </tr>
            </thead>
            <tbody>
              {list.length === 0 && (
                <tr>
                  <td className="table-empty" colSpan="3">
                    Sin resultados
                  </td>
                </tr>
              )}
              {list.map((x) => (
                <tr key={x.id}>
                  <td style={{ textAlign: "center" }}>{x.fecha}</td>
                  <td style={{ textAlign: "center" }}>{x.hora}</td>
                  <td style={{ textAlign: "center" }}>{x.estadoAnterior}</td>
                  <td style={{ textAlign: "center" }}>{x.estado}</td>
                  <td>{x.informe}</td>
                  <td>{x.usuario}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </SlideDown>
    </div>
  );
};

export default Historial;
