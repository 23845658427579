import Geocode from "react-geocode";

export const search = async (location) => {
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);
  Geocode.setLanguage("es");
  Geocode.setRegion("ar");
  Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();

  const response = await Geocode.fromAddress(location);

  return response.results[0].geometry.location;
};
