import http from './http';

export const actions = {
  PERFILES_VER: 100,
  PERFILES_CREAR: 101,
  PERFILES_EDITAR: 102,
  PERFILES_ELIMINAR: 103,

  USUARIOS_VER: 200,
  USUARIOS_CREAR: 201,
  USUARIOS_EDITAR: 202,
  USUARIOS_ELIMINAR: 203,

  AUDITORIA_VER: 304,

  PARAMETROS_VER: 400,
  PARAMETROS_CREAR: 401,
  PARAMETROS_EDITAR: 402,
  PARAMETROS_ELIMINAR: 403,

  DEPOSITOS_VER: 500,
  DEPOSITOS_CREAR: 501,
  DEPOSITOS_EDITAR: 502,
  DEPOSITOS_ELIMINAR: 503,

  CATEGORIAS_VER: 600,
  CATEGORIAS_CREAR: 601,
  CATEGORIAS_EDITAR: 602,
  CATEGORIAS_ELIMINAR: 603,

  PRODUCTOS_VER: 700,
  PRODUCTOS_CREAR: 701,
  PRODUCTOS_EDITAR: 702,
  PRODUCTOS_ELIMINAR: 703,
  PRODUCTOS_UNIFICAR: 704,

  DIRECCIONES_VER: 800,
  DIRECCIONES_CREAR: 801,
  DIRECCIONES_EDITAR: 802,
  DIRECCIONES_ELIMINAR: 803,

  EXPEDIENTES_VER: 900,
  EXPEDIENTES_CREAR: 901,
  EXPEDIENTES_EDITAR: 902,
  EXPEDIENTES_ELIMINAR: 903,
  EXPEDIENTES_PRODUCTOS_AGREGAR: 904,
  EXPEDIENTES_PRODUCTOS_EDITAR: 905,
  EXPEDIENTES_PRODUCTOS_ELIMINAR: 906,
  EXPEDIENTES_ARCHIVOS_AGREGAR: 907,
  EXPEDIENTES_ARCHIVOS_ELIMINAR: 908,
  EXPEDIENTES_ENTRADAS_AGREGAR: 909,
  EXPEDIENTES_ENTRADAS_EDITAR: 910,
  EXPEDIENTES_ENTRADAS_ELIMINAR: 911,
  EXPEDIENTES_VER_TODOS: 912,

  PROVEEDORES_VER: 1000,
  PROVEEDORES_CREAR: 1001,
  PROVEEDORES_EDITAR: 1002,
  PROVEEDORES_ELIMINAR: 1003,

  INVENTARIO_VER: 1100,
  INVENTARIO_VER_TODO: 1101,
  INVENTARIO_ENTRADA_CREAR: 1102,
  INVENTARIO_SALIDA_CREAR: 1103,
  INVENTARIO_EXPORTAR: 1104,

  UNIDADES_VER: 1200,
  UNIDADES_CREAR: 1201,
  UNIDADES_EDITAR: 1202,
  UNIDADES_ELIMINAR: 1203,
  UNIDADES_EQUIVALENCIAS_CREAR: 1204,
  UNIDADES_EQUIVALENCIAS_EDITAR: 1205,
  UNIDADES_EQUIVALENCIAS_ELIMINAR: 1206,

  SALIDAS_VER: 1300,
  SALIDAS_VER_TODAS: 1301,
  SALIDAS_CREAR: 1302,
  SALIDAS_EDITAR: 1303,
  SALIDAS_ELIMINAR: 1304,
  SALIDAS_ACEPTAR: 1305,
  SALIDAS_RECHAZAR: 1306,
  SALIDAS_ENTREGAR: 1307,
  SALIDAS_ARCHIVOS_AGREGAR: 1308,
  SALIDAS_ARCHIVOS_ELIMINAR: 1309,

  DEVOLUCIONES_VER: 1400,
  DEVOLUCIONES_VER_TODAS: 1401,
  DEVOLUCIONES_CREAR: 1402,
  DEVOLUCIONES_EDITAR: 1403,
  DEVOLUCIONES_ELIMINAR: 1404,
  DEVOLUCIONES_ACEPTAR: 1405,
  DEVOLUCIONES_RECHAZAR: 1406,
  DEVOLUCIONES_ARCHIVOS_AGREGAR: 1407,
  DEVOLUCIONES_ARCHIVOS_ELIMINAR: 1408,

  EXTINTOR_USUARIOS_VER: 1500,
  EXTINTOR_USUARIOS_CREAR: 1501,
  EXTINTOR_USUARIOS_EDITAR: 1502,
  EXTINTOR_USUARIOS_ELIMINAR: 1503,

  EXTINTORES_VER: 1600,
  EXTINTORES_CREAR: 1601,
  EXTINTORES_EDITAR: 1602,
  EXTINTORES_ELIMINAR: 1603,

  CATASTRO_VER: 1700,
  CATASTRO_IMPORTAR: 1701,
  CATASTRO_IMPRIMIR: 1702,
  CATASTRO_EDITAR: 1703,
  CATASTRO_ELIMINAR: 1704,
  CATASTRO_ARCHIVOS_AGREGAR: 1705,
  CATASTRO_ARCHIVOS_ELIMINAR: 1706,

  PUNTOS_RECLAMO_VER: 1800,
  PUNTOS_RECLAMO_CREAR: 1801,
  PUNTOS_RECLAMO_EDITAR: 1802,
  PUNTOS_RECLAMO_ELIMINAR: 1803,

  RECLAMOS_VER: 1900,
  RECLAMOS_CREAR: 1901,
  RECLAMOS_EDITAR: 1902,
  RECLAMOS_ELIMINAR: 1903,
  RECLAMOS_VER_TODOS: 1904,
  RECLAMOS_EDITAR_TODOS: 1905,
  RECLAMOS_AGREGAR_ARCHIVOS: 1906,
  RECLAMOS_ELIMINAR_ARCHIVOS: 1907,

  TABLEROS_HACIENDA_VER: 2000,
  TABLEROS_RECLAMOS_VER: 2001,

  AREAS_VER: 2100,
  AREAS_CREAR: 2101,
  AREAS_EDITAR: 2102,
  AREAS_ELIMINAR: 2103,

  TIPOS_RECLAMOS_VER: 2200,
  TIPOS_RECLAMOS_CREAR: 2201,
  TIPOS_RECLAMOS_EDITAR: 2202,
  TIPOS_RECLAMOS_ELIMINAR: 2203,

  PUNTO_PEDIDO_VER: 2300,
};

export const setData = (data) => {
  localStorage.setItem('token', data.token);
  localStorage.setItem('user', JSON.stringify(data.user));
};

export const getUser = () => {
  const user = localStorage.getItem('user');
  return user === null ? { nombre: '', sexo: '' } : JSON.parse(user);
};

export const getJwt = () => {
  return localStorage.getItem('token');
};

export const validateToken = async () => {
  const result = await http.post('auth/validate', {});
  return result && result.data.code === 0;
};

export const hasAction = (action) => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    const permisos = JSON.parse(atob(user.permisos));
    return permisos.indexOf(action) > -1;
  } catch (error) {
    return false;
  }
};
