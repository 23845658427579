import * as moment from 'moment';

export const parseYear = (value) => {
  return value === undefined || value === null
    ? undefined
    : value.format('YYYY');
};

export const parseToYear = (value) => {
  return value === undefined || value === null
    ? undefined
    : moment(value, 'YYYY');
};

export const parseDate = (value) => {
  return value === undefined || value === null
    ? undefined
    : value.format('DD/MM/YYYY');
};

export const parseToDate = (value) => {
  return value === undefined || value === null
    ? undefined
    : moment(value, 'DD/MM/YYYY');
};

export const parseTime = (value) => {
  return value === undefined || value === null
    ? undefined
    : value.format('HH:mm');
};

export const parseToTime = (value) => {
  return value === undefined || value === null
    ? undefined
    : moment(value, 'HH:mm');
};

export const maxLength = (max, str) => {
  return str.substring(0, max);
};

export const formatCurrency = (value) => {
  var isNegative = value && value < 0;

  if (isNegative) {
    value = value * -1;
    return '- $' + value.toLocaleString('es-AR', { minimumFractionDigits: 2 });
  }

  return value
    ? '$' + value.toLocaleString('es-AR', { minimumFractionDigits: 2 })
    : '-';
};
