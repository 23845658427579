import { notification } from "antd";
import swal from "sweetalert";

notification.config({
  placement: "bottomRight",
  duration: 5,
});

export const showInfo = (message) => {
  notification.info({ message: "Información", description: message });
};

export const showWarning = (message) => {
  notification.warning({ message: "Advertencia", description: message });
};

export const showSuccess = (message) => {
  notification.success({ message: "Éxito", description: message });
};

export const showError = (message) => {
  notification.error({ message: "Error", description: message });
};

export const modalSuccess = (title, text) => {
  return swal({
    title: title,
    text: text,
    icon: "success",
    buttons: [false, "Aceptar"],
  });
};
