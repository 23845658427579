/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { FiPackage } from "react-icons/fi";

import { Context } from "../../services/context";
import http from "../../services/http";
import { showWarning } from "../../services/notification";
import { required } from "../../services/validation";

const ProductosForm = ({ item, onClose }) => {
  const [form] = Form.useForm();

  const [state, setState] = useContext(Context);

  const [categorias, setCategorias] = useState([]);
  const [productos, setProductos] = useState([]);
  const [modelos, setModelos] = useState([]);

  const [loaded, setLoaded] = useState(false);
  const [categoria, setCategoria] = useState();
  const [disponible, setDisponible] = useState();

  useEffect(() => {
    async function getData() {
      const response = await http.get(
        `salidas/data/productos?deposito=${state.depositoOrigen}`
      );
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setCategorias(data.categorias);
        setProductos(data.productos);
        setLoaded(true);
      }
    }

    getData();
  }, [state.depositoOrigen]);

  useEffect(() => {
    async function getForm() {
      if (!loaded) {
        return;
      }

      form.setFieldsValue(item);
      await onChangeProducto(item.producto, true);
    }

    getForm();
  }, [loaded, item]);

  const onChangeCategoria = (value) => {
    setCategoria(value);
    onChangeProducto("");
  };

  const onChangeProducto = async (value, setup = false) => {
    if (!value) {
      form.setFieldsValue({
        producto: "",
        modelo: "",
        descripcion: "",
      });

      return;
    }

    const producto = productos.find((x) => x.id === parseInt(value));
    setCategoria(producto.categoria);

    form.setFieldsValue({
      categoria: producto.categoria,
      producto: producto.id,
      modelo: "",
      descripcion: producto.descripcion,
    });

    const response = await http.get(
      `salidas/data/productos/modelos?producto=${value}&deposito=${state.depositoOrigen}`
    );
    if (response && response.data.code === 0) {
      const data = response.data.data;
      setModelos(data.modelos);

      if (setup) {
        const modelo = data.modelos.find(
          (x) => x.marca === item.marca && x.modelo === item.modelo
        );
        const index = data.modelos.indexOf(modelo);

        form.setFieldsValue({
          modelo: index,
        });
      }
    }
  };

  const onChangeModelo = (value) => {
    if (value === undefined) {
      setDisponible();
      return;
    }

    const modelo = modelos[value];
    setDisponible(modelo.stock);
  };

  const renderCantidadPlaceholder = () => {
    if (!disponible || parseFloat(disponible) <= 0) {
      return "";
    }

    return `Disponibles: ${disponible}`;
  };

  const onSave = async (values) => {
    values.producto = parseInt(values.producto);
    const producto = productos.find((x) => x.id === values.producto);
    const modelo = modelos[values.modelo];

    let temp = { ...item };
    temp.categoria = values.categoria;
    temp.producto = producto.id;
    temp.productoTexto = producto.nombre;
    temp.marca = modelo.marca;
    temp.modelo = modelo.modelo;
    temp.cantidad = parseFloat(values.cantidad);
    temp.unidad = values.unidad;

    if (temp.cantidad > parseFloat(disponible)) {
      showWarning("La cantidad solicitada excede la cantidad disponible.");
      return;
    }

    let nProductos = [...state.productos];

    if (temp.index > -1) {
      nProductos[temp.index] = temp;
    } else {
      nProductos.push(temp);
    }

    setState({ ...state, productos: nProductos });

    form.resetFields();
    setLoaded(false);
    onClose();
  };

  return (
    <Form form={form} layout="vertical" onFinish={onSave}>
      <div className="form">
        <div className="form-icon">
          <FiPackage />
        </div>

        <Form.Item label="Categoría" name="categoria" rules={[required]}>
          <Select
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={onChangeCategoria}
          >
            {categorias.map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Producto" name="producto" rules={[required]}>
          <Select
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={(e) => onChangeProducto(e, false)}
          >
            {productos
              .filter((x) => !categoria || x.categoria === categoria)
              .map((x) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.codigo} - {x.nombre}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item label="Marca - Modelo" name="modelo" rules={[required]}>
          <Select
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={onChangeModelo}
          >
            {modelos.map((x, i) => (
              <Select.Option key={i} value={i}>
                {x.marca} - {x.modelo}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Descripción" name="descripcion">
          <Input.TextArea rows={5} disabled={true} />
        </Form.Item>

        <Form.Item label="Cantidad" name="cantidad" rules={[required]}>
          <Input
            type="number"
            step="any"
            placeholder={renderCantidadPlaceholder()}
            onWheel={(e) => e.target.blur()}
            min={0}
          />
        </Form.Item>
        <div className="form-actions">
          <Button type="secondary" onClick={onClose}>
            Volver
          </Button>
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ProductosForm;
