/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Dropdown, Form, Input, Menu, Modal, Select, Tag } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FiEdit, FiPrinter, FiTrash2, FiUploadCloud } from "react-icons/fi";
import { ImCompass2, ImFileExcel, ImFilePdf } from "react-icons/im";
import { SiMicrosoftexcel } from "react-icons/si";
import fileDownload from "js-file-download";

import { ContextProvider } from "./../../../services/context";
import http from "./../../../services/http";
import { showSuccess, showWarning } from "./../../../services/notification";
import { hasAction, actions } from "./../../../services/security";

import Header from "./../../../components/Header";
import Filters from "./../../../components/Filters";
import FiltersButton from "./../../../components/FiltersButton";
import Table from "./../../../components/Table";
import ModalExpediente from "./ModalExpediente";
import ModalImport from "./ModalImport";

const { Option } = Select;

const Catastro = () => {
  const history = useHistory();

  const VER = hasAction(actions.CATASTRO_VER);
  const IMPORTAR = hasAction(actions.CATASTRO_IMPORTAR);
  const IMPRIMIR = hasAction(actions.CATASTRO_IMPRIMIR);
  const EDITAR = hasAction(actions.CATASTRO_EDITAR);
  const ELIMINAR = hasAction(actions.CATASTRO_ELIMINAR);

  const [state, setState] = useState({
    filters: {
      visible: false,
      titular: "",
      padron: "",
      distrito: "",
      estado: null,
      expediente: "",
      diferenciaDesde: "",
      diferenciaHasta: "",
    },
    columns: [
      {
        id: 1,
        title: "Titular",
        style: { minWidth: "350px" },
        render: (item) => item.titular,
      },
      {
        id: 2,
        title: "N° Padrón",
        style: { minWidth: "150px", textAlign: "center" },
        render: (item) => item.padron,
      },
      {
        id: 3,
        title: "Distrito",
        style: { minWidth: "150px" },
        render: (item) => item.distrito,
      },
      {
        id: 4,
        title: "Domicilio",
        style: { minWidth: "250px" },
        render: (item) => item.domicilio,
      },
      {
        id: 5,
        title: "Declarado",
        style: { minWidth: "150px", textAlign: "center" },
        render: (item) => `${item.declarado} m2`,
      },
      {
        id: 6,
        title: "No Declarado",
        style: { minWidth: "150px", textAlign: "center" },
        render: (item) => `${item.diferencia} m2`,
      },
      {
        id: 7,
        title: "Estado",
        style: { minWidth: "150px", textAlign: "center" },
        render: (item) => (
          <Tag color={getEstadoColor(item.estado)}>{item.estado}</Tag>
        ),
      },
      {
        id: 8,
        title: "Expediente",
        style: { minWidth: "150px", textAlign: "center" },
        render: (item) => item.expediente,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
    selected: [],
    modalExpediente: false,
    loadingImport: false,
    loadingPrint: false,
  });

  const [importVisible, setImportVisible] = useState(false);

  const [estados, setEstados] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Notificaciones" },
    { id: 2, title: "Catastro", url: "/notificaciones/catastro" },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        {IMPRIMIR && (
          <Dropdown
            overlay={menuPrint}
            trigger={["click"]}
            placement="bottomCenter"
          >
            <Button
              type="primary"
              icon={<FiPrinter />}
              loading={state.loadingPrint}
            >
              Imprimir
            </Button>
          </Dropdown>
        )}
        <FiltersButton />
        {IMPORTAR && (
          <Dropdown
            overlay={menuImport}
            trigger={["click"]}
            placement="bottomCenter"
          >
            <Button
              type="primary"
              icon={<FiUploadCloud />}
              loading={state.loadingImport}
            >
              Importar
            </Button>
          </Dropdown>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Titular" name="titular">
          <Input />
        </Form.Item>
        <Form.Item label="N° Padrón" name="padron">
          <Input type="number" step="any" onWheel={(e) => e.target.blur()} />
        </Form.Item>
        <Form.Item label="Distrito" name="distrito">
          <Input />
        </Form.Item>
        <Form.Item label="Estado" name="estado">
          <Select allowClear>
            {estados.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Mínino No Declarado" name="diferenciaDesde">
          <Input type="number" step="any" onWheel={(e) => e.target.blur()} />
        </Form.Item>
        <Form.Item label="Máximo No Declarado" name="diferenciaHasta">
          <Input type="number" step="any" onWheel={(e) => e.target.blur()} />
        </Form.Item>
        <Form.Item label="Expediente" name="expediente">
          <Input />
        </Form.Item>
        <Form.Item label="N° Padrón Desde" name="padronDesde">
          <Input type="number" step="any" onWheel={(e) => e.target.blur()} />
        </Form.Item>
        <Form.Item label="N° Padrón Hasta" name="padronHasta">
          <Input type="number" step="any" onWheel={(e) => e.target.blur()} />
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
        {ELIMINAR && item.estado === "Emitida" && (
          <Menu.Item key="2" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const menuPrint = () => {
    return (
      <Menu className="table-actions-menu">
        <Menu.Item key="0" onClick={() => onPrint()}>
          <ImFilePdf />
          Notificaciones (.pdf)
        </Menu.Item>
        <Menu.Item key="1" onClick={() => onPrintSobretasas()} disabled>
          <ImFilePdf />
          Sobretasas (.pdf)
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="2" onClick={() => onPrintExcel()}>
          <ImFileExcel />
          Notificaciones (.xlsx)
        </Menu.Item>
      </Menu>
    );
  };

  const menuImport = () => {
    return (
      <Menu className="table-actions-menu">
        <Menu.Item key="0" onClick={() => onImportExcel()}>
          <SiMicrosoftexcel />
          Excel
        </Menu.Item>
        <Menu.Item key="1" disabled>
          <ImCompass2 />
          GIS
        </Menu.Item>
      </Menu>
    );
  };

  useEffect(() => {
    async function getData() {
      const response = await http.get("notificaciones/catastro/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setEstados(data.estados);
      }
    }

    getData();
  }, []);

  const onImportExcel = () => {
    setImportVisible(true);
  };

  const onCloseExcel = () => {
    setImportVisible(false);
    setState({ ...state, reload: !state.reload });
  };

  // const onImportGIS = async () => {
  //   setState({ ...state, loadingImport: true });

  //   const rImport = await http.post('notificaciones/catastro/import/gis');
  //   if (rImport && rImport.data.code === 0) {
  //     const data = rImport.data.data;

  //     if (data.repeated > 0) {
  //       showWarning(
  //         `Se encontraron ${data.repeated} registros ya cargados en el sistema.`
  //       );
  //     }
  //   }

  //   setState({ ...state, total: -1, loadingImport: false });
  // };

  const onPrint = async () => {
    if (state.selected.length === 0) {
      showWarning(
        "Debe seleccionar al menos un registro para poder imprimirlo."
      );
      return;
    }

    setState({ ...state, modalExpediente: true });
  };

  const onPrintConfirm = async (values) => {
    let nState = { ...state, modalExpediente: false, loadingPrint: true };
    setState(nState);

    const rPrint = await http.post(
      "notificaciones/catastro/print",
      { expediente: values.expediente, list: state.selected },
      true
    );

    if (rPrint) {
      fileDownload(
        rPrint.data,
        `NotificacionesCatastro_${values.expediente}.pdf`
      );

      nState = { ...nState, total: 0, selected: [], loadingPrint: false };
      setState(nState);
    } else {
      nState = { ...nState, loadingPrint: false };
      setState(nState);
    }
  };

  const onPrintSobretasas = async () => {
    if (state.selected.length === 0) {
      showWarning(
        "Debe seleccionar al menos un registro para poder imprimirlo."
      );
      return;
    }

    setState({ ...state, loadingPrint: true });

    const rPrint = await http.post(
      "notificaciones/catastro/print/sobretasas",
      { list: state.selected },
      true
    );

    if (rPrint) {
      if (rPrint.data.size === 0) {
        showWarning(
          "Alguno de los registros seleccionados no se encuentran en estado Sobretasas."
        );
      } else {
        fileDownload(rPrint.data, `NotificacionesCatastro_Sobretasas.pdf`);
        setState({
          ...state,
          total: 0,
          selected: [],
          loadingPrint: false,
        });
      }
    }
  };

  const onPrintExcel = async () => {
    const rPrint = await http.post(
      "notificaciones/catastro/print/excel",
      state.filters,
      true
    );

    if (rPrint) {
      fileDownload(rPrint.data, `NotificacionesCatastro.xlsx`);
    }
  };

  const onEdit = (item) => {
    history.push(`/notificaciones/catastro/${item.id}`);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar esta notificación de catastro?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`notificaciones/catastro/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess(
            "La notificación de catastro fue eliminada exitosamente."
          );
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  const getEstadoColor = (item) => {
    switch (item) {
      case "Pendiente":
        return "purple";
      case "Emitida":
        return "gold";
      case "Notificado":
        return "green";
      case "Sobretasas":
        return "red";
      case "Sobretasa Emitida":
        return "red";
      case "Pendiente de Trámites":
        return "blue";
      case "Regularizado":
        return "lime";
      default:
        return "";
    }
  };

  const onCheck = (list) => {
    setState({ ...state, selected: list });
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="catastro page">
      <ContextProvider value={[state, setState]}>
        <Header
          title="Notificaciones de Catastro"
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters filters={filters} />
        <Table
          url="notificaciones/catastro/list"
          menu={menu}
          checkbox
          onCheck={onCheck}
        />

        <ModalImport visible={importVisible} onClose={onCloseExcel} />

        <ModalExpediente
          visible={state.modalExpediente}
          onSubmit={onPrintConfirm}
          onClose={() => setState({ ...state, modalExpediente: false })}
        />
      </ContextProvider>
    </div>
  );
};

export default Catastro;
