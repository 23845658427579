/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { Tag } from "antd";

import { Context } from "./../../../services/context";

import "./Historial.scss";

const Historial = () => {
  const [state] = useContext(Context);

  const getEstadoColor = (item) => {
    switch (item) {
      case "Pendiente":
        return "purple";
      case "Emitida":
        return "gold";
      case "Notificado":
        return "green";
      case "Sobretasas":
        return "red";
      case "Sobretasa Emitida":
        return "red";
      case "Pendiente de Trámites":
        return "blue";
      case "Regularizado":
        return "lime";
      default:
        return "";
    }
  };

  return (
    <div className="catastro-historial">
      <table className="table">
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Hora</th>
            <th>Estado</th>
            <th>Usuario</th>
          </tr>
        </thead>
        <tbody>
          {state.historial.map((x) => (
            <tr key={x.id}>
              <td>{x.fecha}</td>
              <td>{x.hora}</td>
              <td>
                <Tag color={getEstadoColor(x.estado)}>{x.estado}</Tag>
              </td>
              <td>{x.usuario}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Historial;
