import React, { useEffect, useState } from 'react';
import { Button, Modal, Tag } from 'antd';
import fileDownload from 'js-file-download';

import http from './../../services/http';

import './Movimientos.scss';

const Movimientos = (props) => {
  const { deposito, onClose } = props;

  const [movimientos, setMovimientos] = useState([]);

  useEffect(() => {
    if (!deposito) {
      return;
    }

    async function getMovimientos() {
      const response = await http.get(
        `inventario/deposito/movimientos?id=${deposito.id}`
      );
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setMovimientos(data.movimientos);
      }
    }

    getMovimientos();
  }, [deposito]);

  const onClickExport = async () => {
    const rExport = await http.get(
      `inventario/deposito/movimientos/export?id=${deposito.id}`,
      true
    );

    fileDownload(rExport.data, `Movimientos_${Date.now()}.xlsx`);
  };

  const renderStock = (item) => {
    if (item.id.startsWith('S')) {
      return <Tag color="red">- {item.cantidad}</Tag>;
    }

    return <Tag color="green">+ {item.cantidad}</Tag>;
  };

  return (
    <Modal
      className="inventario-movimientos"
      title="Movimientos"
      visible={deposito}
      onCancel={onClose}
      footer={null}
      width={1200}
    >
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Tipo</th>
            <th>Expediente</th>
            <th>Destino</th>
            <th>Fecha</th>
            <th>Hora</th>
            <th>Cantidad</th>
            <th>Receptor</th>
            <th>Observaciones</th>
          </tr>
        </thead>
        <tbody>
          {movimientos.map((x) => (
            <tr key={x.id}>
              <td>{x.id}</td>
              <td>{x.tipo}</td>
              <td>{x.expediente}</td>
              <td>{x.destino}</td>
              <td>{x.fechaTexto}</td>
              <td>{x.horaTexto}</td>
              <td>{renderStock(x)}</td>
              <td>{x.receptor}</td>
              <td>{x.observaciones}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="actions">
        <Button type="primary" onClick={onClickExport}>
          Exportar
        </Button>
      </div>
    </Modal>
  );
};

export default Movimientos;
